import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { SharingModuleModule } from '../Sharing/sharing-module.module';
import { HotDatesRoutingModule } from './hot-dates-routing.module';
import { HotdateWrapperComponent } from './hotdate-wrapper/hotdate-wrapper.component';
import { HotdatesComponent } from './hotdates/hotdates.component';
import { HotdateListComponent } from './hotdate-list/hotdate-list.component';
import { HotDateNavigationComponent } from './hot-date-navigation/hot-date-navigation.component';
import { SwingerHotdatesComponent } from './swinger-hotdates/swinger-hotdates.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);
@NgModule({
  declarations: [HotdateWrapperComponent, HotdatesComponent, HotdateListComponent, HotDateNavigationComponent, SwingerHotdatesComponent],
  imports: [
    CommonModule,
    SharingModuleModule,
    HotDatesRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    FlatpickrModule.forRoot(),
  ]
})
export class HotDatesModule { }
