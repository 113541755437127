<div *ngIf="!uiHide">
    <div class="row g-0">
        <div class="col-3 col-md-2 pr-1">
            <div class="user_profile_ls"><img [src]="(swinger.is_pic == null)?defaultProfile:swinger.is_pic " alt="user-profile" class="img-fluid"></div>
        </div>
        <div class="col-9 col-md-10 pl-1">
          <div class="row">
            <div class="col-md-7 col-12">
              <div class="card-body">
                <h5 class="card-title"><a (click)="reloadWindow(swinger?.id);">{{swinger?.name}}</a></h5>
                <span *ngIf="swinger?.gender=='f'" class="badge badge-pill badge-success mb-2">Female</span>
                <span *ngIf="swinger?.gender=='m'" class="badge badge-pill badge-info mb-2">Male</span>
                <span *ngIf="swinger?.gender=='c'" class="badge badge-pill badge-warning mb-2">Couple</span>
                <!-- <p class="card-text text-muted">{{swinger?.short_tag_line}}</p> -->
              </div>
            </div>
            <div class="col-md-5 col-12">
              <p *ngIf="successMsg" class="text-small text-success">{{swingerStatus}}</p>
              <div class="btn-group mt-4">
              <button *ngIf="swinger?.friend_status == 'not friends'" type="button" class="btn btn-warning" (click)='sendFriendRequest(swinger?.id)'><div *ngIf='requestLoding' class="spinner-border spinner-border-sm text-light" role="status">
                <span class="visually-hidden"></span>
              </div> <i class="fas fa-user-plus"></i></button>
    
              <span *ngIf="swinger?.friend_status == 'Requested'" class="badge bg-info text-light"> Requested</span>
    
              <span *ngIf="swinger?.friend_status == 'friends'" class="badge bg-success text-light"> Friends</span>
              
              <!-- <a  class="btn btn-info" routerLink="/{{swinger?.id}}/swinger/profile" (click)="setProfileViews(swinger?.id)"><i class="fas fa-eye"></i></a> -->
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
