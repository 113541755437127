import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GlobalLocationsService {

  constructor(private http: HttpClient ) { }
  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

getCountries() {
  return this.http.get<any>(`${environment.apiUrl}/get-country`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getStates(countryName: string) {
  return this.http.get<any>(`${environment.apiUrl}/get-state/${countryName}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}

getCities(stateName: string) {
  return this.http.get<any>(`${environment.apiUrl}/get-cities/${stateName}`, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}



}
