import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-hotdate-wrapper',
  templateUrl: './hotdate-wrapper.component.html',
  styleUrls: ['./hotdate-wrapper.component.css']
})
export class HotdateWrapperComponent implements OnInit {
  emailStatus = true;
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(check => {
      if(check.email_status!=1){
      this.emailStatus = false;
      }
    })
   }

  ngOnInit(): void {
  }

}
