
          <app-group-navigation></app-group-navigation> 
          <div class="card bg-dark text-light mb-3 mt-2">
            <div class="card-header"><h6>Invitations</h6></div>
            <div class="card-body over_flow_wrapper_big">
              <div class="card mb-2 bg-dark m-50-wrapper" *ngFor="let friend of groupInvitations; let i=index">
                <div class="row g-0" *ngIf="i<11">
                  <div class="col-12 col-md-2 pr-1">
                      <div class="user_profile_ls"><img src="{{friend.profile_pic?friend.profile_pic:defaultProfile}}" alt="user-profile" class="img-fluid"></div>
                  </div>
                  <div class="col-12 col-md-6 pl-1">
                    <div class="card-body">
                      <h5 class="card-title"><a routerLink="/group-panel/{{friend.id}}/post" >{{friend.name | slice:0:12}}</a></h5>
                      <p class="card-text text-muted">{{friend.sort_discription | slice:0:35}}</p>
                    </div>
                  </div>
 
                  <div class="col-12 col-md-4 pl-1">
                    <div class="btn-group mt-4">
                      <button type="button" (click)="acceptGroupInvitation(friend.id,i)" class="btn btn-success">Confirm</button>
                      <button type="button" (click)="rejectGroupInvitation(friend.id,i)" class="btn btn-danger">Deny</button>
                    </div>
                  </div>
 
                </div>
              </div>
              <div class="card-text text-center" *ngIf="loading">
                <div class="spinner-border text-light" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            </div>
          </div>
      
 
 
      

