import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from '@app/_helpers';
import { FirendsComponent } from './friends/firends.component';
import { FriendRequestSentComponent } from './friends/friend-request-sent/friend-request-sent.component';
import { FriendRequestPanddingComponent } from './friends/friend-request-pandding/friend-request-pandding.component';
import { BlockFriendsComponent } from './friends/block-friends/block-friends.component';
import { SwingersComponent } from './swingers/swingers.component';
import { WhoViewedComponent } from './who-viewed/who-viewed.component';
import { MatchesComponent } from './matches/matches.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';
import { FavouriteFriendsComponent } from './friends/favourite-friends/favourite-friends.component';
import { AboutYouComponent } from './profile/about-you/about-you.component';
import { LocationComponent } from './profile/location/location.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { PreferencesComponent } from './profile/preferences/preferences.component';
import { UserGalleryComponent } from './user-gallery/user-gallery.component';
import { CreateAlbumComponent } from './user-gallery/create-album/create-album.component';
import { UploadPhotosComponent } from './user-gallery/upload-photos/upload-photos.component';
import { UserVideosComponent } from './user-gallery/user-videos/user-videos.component';
import { NotificationComponent } from './notification/notification.component';
import { ProfileCommentListComponent } from './profile-comment-list/profile-comment-list.component';
import { InvoceViewComponent } from './invoce-view/invoce-view.component'
import { UserOnlineComponent } from "./user-online/user-online.component";
const routes: Routes = [{ path: 'dashboard',
                        component: DashboardComponent,
                        canActivate: [AuthGuard],
                        children: [{ path: 'friends', component: FirendsComponent},
                                  { path: 'firend-request-sent', component: FriendRequestSentComponent},
                                  { path: 'firend-request-pandding', component: FriendRequestPanddingComponent},
                                  { path: 'block-friends', component: BlockFriendsComponent},
                                  { path: 'swingers', component: SwingersComponent},
                                  { path: 'who-viewed', component: WhoViewedComponent },
                                  { path: 'matches', component: MatchesComponent},
                                  { path: ':id/profile', component: ProfileComponent},
                                  { path: ':id/friends', component: ProfileComponent},
                                  { path: ':id/videos', component: ProfileComponent},
                                  { path: ':id/gallery', component: ProfileComponent},
                                  { path: ':id/events', component: ProfileComponent},
                                  { path: ':id/groups', component: ProfileComponent},
                                  { path: 'edit-profile', component: ProfileEditComponent},
                                  { path: 'favourite-friends', component: FavouriteFriendsComponent},
                                  { path: 'about-you', component: AboutYouComponent},
                                  { path: 'location', component: LocationComponent},
                                  { path: 'change-password', component: ChangePasswordComponent},
                                  { path: 'preferences', component: PreferencesComponent},
                                  { path: 'gallary', component: UserGalleryComponent},
                                  { path: 'create-album', component: CreateAlbumComponent},
                                  { path: 'upload-photos', component: UploadPhotosComponent},
                                  { path: 'upload-videos', component: UserVideosComponent},
                                  { path: 'notifications', component: NotificationComponent},
                                  { path: 'profile-comments', component: ProfileCommentListComponent},
                                  { path: 'plan-invoce', component: InvoceViewComponent},
                                  { path: 'user-online', component: UserOnlineComponent},
                                  
                                ]
                                }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class UserRoutingModule {}
