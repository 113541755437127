import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserMediaService } from '@app/_services/user-media.service';
import { environment } from '@environments/environment';
import { AuthenticationService, CommentService } from '@app/_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-swinger-info',
  templateUrl: './swinger-info.component.html',
  styleUrls: ['./swinger-info.component.css']
})
export class SwingerInfoComponent implements OnInit {

currentUser: any;
isLogin: any;
userProfileUrl: string;
commentForm: FormGroup;

  constructor(private userMedia: UserMediaService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private formBuilder: FormBuilder,
              private commentService: CommentService
              ) {
                this.authenticationService.currentUser.subscribe(x => {
                  this.currentUser = x;
                  if ( this.currentUser != null ){
                        this.isLogin = true;
                  }else{
                       this.isLogin = false;
                  }
              });
               }

  hidenav: boolean;
  id: number;
  defaultProfile: any = environment.defaultProfile;
  coverImgURL: any = '../assets/images/user_dashboard.jpg';
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  coverImagePath: any;
  dataId: any = '';
  profileId: any = '';
  profileloading = false;
  coverLoader = false;
  defaultNavigation = true;
  getSwingerProfile = false;
  swinger: any;
  c_t_class: string;
  totalComments: any;
  commentFormReply: FormGroup;
  activeSwingerId: number;
  commentLoading = false;
  commentProfile:any;


  ngOnInit(): void {
    this.hidenav = false;
    this.getSwingerProfile = true;
    this.route.parent.params.subscribe( params => {
      this.activeSwingerId = params.swingerId;
      this.getDetails(params.swingerId);
      this.getUserCommentsById(params.swingerId);
    });

    this.commentForm = this.formBuilder.group({
      swinger_id: [''],
      comment_swinger_profile_url:[''],
      comment_content:['', Validators.required],
      comment_parent_id:[''],
      comment_id:['']
    });

    this.commentFormReply = this.formBuilder.group({
      swinger_id: [''],
      comment_swinger_profile_url:[''],
      comment_content:['', Validators.required],
      comment_parent_id:[''],
      comment_id:['']
    });
  }


  addComment(){
    this.commentLoading = true;
    this.commentForm.get('comment_swinger_profile_url').setValue(`${environment.hostUrl}/${this.currentUser.id}/swinger/profile`);
    this.commentService.storeUserProfileComment(this.commentForm.value).subscribe(res => {
      // console.log(res)
      this.commentLoading = false;
      this.commentForm.reset();
      this.getUserCommentsById(this.activeSwingerId);
    },(error)=>{
      console.log(error);
    }
    );

  }


  getUserCommentsById(userId: number){
      this.commentService.getUserProfileCommentsBy(userId)
      .pipe(map(data =>{
        data.data.data.map(comment => {
          comment.profile = comment.profile;
          comment.data.map(commentData => {
          commentData.profile = commentData.profile;
          comment.replyStatus = false;
          });
      })
      return data.data.data;
      })).subscribe(res => {
        // console.log(res, 'user profile comment');
        this.totalComments = res;
      });
  }

  getDetails(id: any) {
    this.userMedia.getSwingerDetails(id).subscribe(res => {
    this.commentForm.get('swinger_id').setValue(res.data.id);
    this.userProfileUrl = res.data.profile;
      for(const item in res.data){
        if(res.data[item] == null){
          res.data[item] = '';
        }
      }
      if(res.data.like_smoke == '0' || res.data.like_drink == '0'){
        res.data.like_smoke = 'No';
        res.data.like_drink = 'No';
        this.c_t_class = 'badge badge-pill badge-danger';
      }else{
        res.data.like_smoke = 'Yes';
        res.data.like_drink = 'Yes';
        this.c_t_class = 'badge badge-pill badge-success';
      }
      this.swinger = res.data;
    }, error => {
      console.log(error);
    });
  }

 showReplyComment(index: number, perReply?: number){

      if(index >= 0 && perReply >= 0){
        if(this.totalComments[index].data[perReply].replyStatus == true) {
          this.totalComments[index].data[perReply].replyStatus = false;
       }else{
          this.totalComments[index].data[perReply].replyStatus = true;
          this.commentFormReply.get('comment_content').setValue(`@${this.totalComments[index].data[perReply].user_name}`);
       }
      }else{
        if(this.totalComments[index].replyStatus == true) {
          this.totalComments[index].replyStatus = false;
       }else{
        this.commentFormReply.get('comment_content').setValue(`@${this.totalComments[index].user_name} `);
        this.totalComments[index].replyStatus = true;
       }
      }
    //  user_name
 }

  replyComment(parntIndex: number,index: number, parentCommentId: number, comment_id: number) {
    this.commentLoading = true;
    this.commentFormReply.get('comment_swinger_profile_url').setValue(`${environment.hostUrl}/${this.currentUser.id}/swinger/profile`);
    this.commentFormReply.get('swinger_id').setValue('');
    this.commentFormReply.get('comment_parent_id').setValue(parentCommentId);
    this.commentFormReply.get('comment_id').setValue(comment_id);
    this.commentService.storeUserProfileComment(this.commentFormReply.value).subscribe(res => {
      // console.log(res);
      //this.commentLoading = true;
      this.commentFormReply.reset();
      this.showReplyComment(parntIndex, index);
      // this.getUserCommentsById(this.activeSwingerId);
    });
  }

  commentLike(index: number, perReply: number, commentId: number){
    const data = {
      comment_id: commentId
    };

    if(index >= 0 && perReply >= 0){
      console.log(index, perReply);
      this.commentService.commentUserProfileLike(data).subscribe(res => {
        this.totalComments[index].data[perReply].totalLikes = res.data;
      },(error)=>{
        console.log(error);
      });

    }else{
      this.commentService.commentUserProfileLike(data).subscribe(res => {
        this.totalComments[index].totalLikes = res.data;
      },(error)=> {
        console.log(error);
      });
    }
  }



}
