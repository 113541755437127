import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent implements OnInit {

  constructor() { }
  heading: string = 'Privacy Policy';
  backgroundImage: any = '/assets/images/login_background.jpg';

  ngOnInit(): void {
  }

}
