import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';
import { HotdatesComponent } from '@app/hot-dates/hotdates/hotdates.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-right-navigation-panel',
  templateUrl: './right-navigation-panel.component.html',
  styleUrls: ['./right-navigation-panel.component.css']
})
export class RightNavigationPanelComponent implements OnInit {


  defaultProfile = environment.defaultProfile;
  loading = true;
  constructor(private friends: FriendsService, private router:Router) { }
  friendsData:any;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  @ViewChild(HotdatesComponent) hotdates: HotdatesComponent;

  ngOnInit(): void {
    this.getOnlineFriends();
  }
  dataNotFound  = false;
  getOnlineFriends(){
    this.friends.getOnlineFriendslist().subscribe(data =>{
      this.loading = false;
      this.friendsData = data.data.data;
      if(this.friendsData.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
    }, error => {
      this.loading = false;
    });
  }
  gotoOnline(){
    this.router.navigate(['/dashboard/user-online'])
  }
}


