import { Component, OnInit, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService, GalleryService, SwingersService } from '@app/_services';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsServices } from '../../_services/notifications.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-swinger-videos',
  templateUrl: './swinger-videos.component.html',
  styleUrls: ['./swinger-videos.component.css']
})

export class SwingerVideosComponent implements OnInit {
  videoUrls:any;
  newVideoUrls:any;
  IDs:any;

    constructor(
      private fb: FormBuilder, 
      private galleryService: GalleryService, 
      private swingerService: SwingersService,
      private route: ActivatedRoute,
      private commonService: CommonService,
      private NotificationsServices:NotificationsServices,
      private modal: NgbModal 
      ) { 
      this.route.queryParams.subscribe(params => {
        this.IDs = params['d'];
        if (this.IDs != null && this.IDs != undefined && this.IDs != "") {
          this.getvideoByID();
        } else {
          this.route.parent.params.subscribe(params => {
            this.getSwingerVideos(params.swingerId);
          });
        }
      })
    }
    
  
    submitted = false;
    userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
    defaultProfile = environment.defaultProfile;
    loading = false;
    ifSuccess = false;
    message: string;
    error = '';
    videosList: FormGroup;
    openModel = false;
    gallaryVideos = [];
    galleryFiles = [];
    videosGallery: any = [];
    NewVideo:any = [];
    privacyMode = true;
    loadingPrivacyStatus = false;
    currentIndex: number;
    api;
    currentItem;
    videoStatus = false;
    pagination:any;
	  checkpages = false;
    videoPlayIcon = '../../../../assets/images/play-button.png';

    singleVideo:any;
  
  
  ngOnInit(): void {
  
  }

  @ViewChild('showVideo', { static: false }) showVideo: TemplateRef<any>;

  getVideoById(id: number,index: number){
    this.modal.open(this.showVideo, { size: 'lg' });
    this.galleryService.getVideoByVideoId(id).subscribe(res=>{
      this.singleVideo = res.data;
      this.singleVideo.index = index;
      this.storeVideoView(id, index);
    },error=>{
      console.log(error);
    })
   
  }
  
  get f() { return this.videosList.controls; }
  
  getvideoByID(){
    this.NotificationsServices.getVideoID(this.IDs).subscribe((res)=>{
      let array :any[] = [];
      array.push(res.data)
      this.videosGallery = array;
      if(this.videosGallery.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
    })
  }
  dataNotFound = false;
  getSwingerVideos(id: any){
    this.galleryService.getallVideos(id).pipe(map(res=>{
      // console.log(res)
    this.pagination = res.data;
    this.checkpages = this.pagination.links.length >= 4?true: false;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
     return res.data.data;
    })).subscribe(videoResponce => {
      this.videosGallery = videoResponce;
      // console.warn(videoResponce)
      if(this.videosGallery.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
    });
  }

  activePage(url:string){
    if(url){
      this.commonService.getPagination(url).pipe(map(res=>{
    this.pagination = res.data;
    this.checkpages = this.pagination.links.length >= 4?true: false;
    this.pagination.links[0].label = 'Previous';
    this.pagination.links[this.pagination.links.length - 1].label = 'Next';
     return res.data.data;
    })).subscribe(videoResponce => {
      this.videosGallery = videoResponce;
      if(this.videosGallery.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
    });
  }
  }
  

  
    videoLike(id: any, index: number){
      const data = {
       'video_id':id
      };
      this.galleryService.videoLike(data).subscribe(res => {
        this.videosGallery[index].totallikes = res.totalLikes;
      });
    }
  
  
    onPlayerReady(api) {
      this.api = api;
      this.api.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
    }
  
    nextVideo() {
      this.currentIndex++;
      if (this.currentIndex === this.videosGallery.length) {
        this.currentIndex = 0;
      }
  
      this.currentItem = this.videosGallery[this.currentIndex];
    }
  
    playVideo() {
      this.api.play();
    }
  
    onClickPlaylistItem(itemId, index: number) {
      this.currentIndex = index;
      this.currentItem = itemId;
      this.storeVideoView(itemId, index);
    }
  
    storeVideoView(id: number,index: number){
      if(!this.videoStatus){
        const data = {
          'video_id':id
          };
      this.galleryService.storeVideoViews(data).subscribe(res => {
        this.videosGallery[index].totalviews = res.views;
      });
        this.videoStatus = true;
      }else{
        this.videoStatus = false;
      }
      
    }
  

}
