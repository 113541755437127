import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HotDateService } from '@app/_services';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-hotdate-list',
  templateUrl: './hotdate-list.component.html',
  styleUrls: ['./hotdate-list.component.css']
})
export class HotdateListComponent implements OnInit {

  userID = JSON.parse(sessionStorage.getItem('currentUser')).id;
  eventData: any;
  @Input() hotdateList: any;
  @ViewChild('updateHotDate', { static: false }) updateHotDate: TemplateRef<any>;
  hotDateUpdateIndex: any;
  hotdateAddress:any;
  addressStatus = false;
  mapShow = false;

  constructor(
    private hotdateServive: HotDateService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private Router:Router
  ) {
  }

  options: FlatpickrDefaultsInterface;


  addHotdateEventForm: FormGroup;

  ngOnInit(): void {
    this.addHotdateEventForm = this.fb.group({
      id: [''],
      address: [''],
      zip_code: [''],
      city: [''],
      start_date: [''],
      end_date: [''],
      latitude:[''],
      longitude:[''],
      sort_description: [''],
      long_description: [''],
      country: [''],
      privacy: [''],
      status: [''],
    });

    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      enable: [
        {
          from: new Date(),
          to: '2025-05-01',
        },
      ],
    };

    this.checkdates();
  }

  updateAddress(){
    this.addressStatus = true;
  }

  checkdates(){
    this.addHotdateEventForm.valueChanges.subscribe(val => {
      if(val.end_date){
      if(val.start_date>val.end_date){
        Swal.fire(
          'Error',
          'Please check start date and select valid end date.','error'
        );
        this.addHotdateEventForm.get('end_date').setValue('');
      }
    }
      
  });
}



  getlatitudeLongitude(mapData: any) {
    this.addHotdateEventForm.get('latitude').setValue(mapData.lat);
    this.addHotdateEventForm.get('longitude').setValue(mapData.lng);
    this.addHotdateEventForm.get('address').setValue(mapData.address);
    this.addHotdateEventForm.get('zip_code').setValue(mapData.postalCode);
}

  deleteHotDate(index: number, id: number){
    Swal.fire({
      title: 'Are you sure want to remove this hot date record?',
      text: 'You will not be able to recover this hot date record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    }).then((result) => {
      if (result.value) {
        this.hotdateServive.deleteHotDate(id).subscribe(res => {
          Swal.fire(
            'Success',
            `${res.message}`,'success'
          );
          this.hotdateList.splice(index, 1);
         },( error ) => {
          Swal.fire(
            'Server Error',
            'Something is wrong please try again.',
            'error'
          );
         });
      }else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your Hot Date record is safe :)', 'error');
      }
    });
  }
addresss:any;
  updateHotDates(index: number, hotdateAddress){
    const data = this.addHotdateEventForm.value;
    
    if(this.addHotdateEventForm.value.address == null || this.addHotdateEventForm.value.address == ''){
      this.addresss = hotdateAddress
    }else{
      this.addresss = this.addHotdateEventForm.value.address
    }
    this.hotdateList[index] = data;
    let body = {
      id: this.addHotdateEventForm.value.id,
      address: this.addresss,
      zip_code: this.addHotdateEventForm.value.zip_code,
      city: this.addHotdateEventForm.value.city,
      start_date: this.addHotdateEventForm.value.start_date,
      end_date: this.addHotdateEventForm.value.end_date,
      latitude:this.addHotdateEventForm.value.latitude,
      longitude:this.addHotdateEventForm.value.longitude,
      sort_description: this.addHotdateEventForm.value.sort_description,
      long_description: this.addHotdateEventForm.value.long_description,
      country: this.addHotdateEventForm.value.country,
      privacy: this.addHotdateEventForm.value.privacy,
      status: this.addHotdateEventForm.value.status,
    }
    this.hotdateList[index] = body;
    this.hotdateServive.updateHotDates(body).subscribe(res => {
      Swal.fire(
        'Success',
        `${res.message}`,'success'
      ).then(ok=>{
        if(ok){
          this.Router.navigate(['/hot-dates/hot-date-list'])
        }
      });
      this.addHotdateEventForm.reset();
      this.closeUpdateHotdateModal();
    }, error => {
      Swal.fire(
        'Error',
        'Something wrong please Try again','error'
      );
    });
  }

  openupdateHotDateModal(index: number, id: any){
    this.addressStatus = false;
    this.hotDateUpdateIndex = index;
    this.addHotdateEventForm.get('id').setValue(id);
    this.hotdateServive.getHotDateById(id).subscribe(res => {
      this.addHotdateEventForm.get('id').setValue(id);
      this.hotdateAddress = res.data.address;
      this.addHotdateEventForm.get('zip_code').setValue(res.data.zip_code);
      this.addHotdateEventForm.get('city').setValue(res.data.city);
      this.addHotdateEventForm.get('country').setValue(res.data.country);
      this.addHotdateEventForm.get('start_date').setValue(res.data.start_date);
      this.addHotdateEventForm.get('end_date').setValue(res.data.end_date);
      this.addHotdateEventForm.get('sort_description').setValue(res.data.sort_description);
      this.addHotdateEventForm.get('long_description').setValue(res.data.long_description);
      this.modal.open(this.updateHotDate, { size: 'lg' });
    },( err ) => {
      Swal.fire(
        'Error',
        'Something wrong please Try again','error'
      );
    });
  }

  closeUpdateHotdateModal(){
    this.modal.dismissAll(this.updateHotDate);
  }

}
