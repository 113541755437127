import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PlanService } from '@app/_services';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    rewind: true,
    autoplay: true,
    autoplayHoverPause: true,
    margin: 10,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
  };

  constructor(private planService: PlanService, private modal: NgbModal,  private router: Router,) { }
  @ViewChild('askForLogin', { static: true }) askForLogin: TemplateRef<any>;
 userID = JSON.parse(sessionStorage.getItem('currentUser'))?.id;
 planId = JSON.parse(sessionStorage.getItem('currentUser'))?.plan_id;

 plans: any;
 selectedPlanId;
 planButton = 'Choose Plan';
 packageLoading = false;

  ngOnInit(): void {
    this.getPlansWithFeatures();
    this.packageLoading = true;
    if(this.userID){
      this.planButton = 'Upgrade Plan';
    }
  }

  getPlansWithFeatures() {
    this.planService.getPlansWithFeatures().subscribe(res => {
      // console.log(res)
      this.packageLoading = false;
      res.data[0].info = 'Due to new laws enacted by The US Government, we are making some changes to our business model and policies which limits access to our free members.';
      res.data[0].subTitle = '(Members with a provide picture get 10 times more exposure.)';
      this.plans = res.data.splice(1);
    }, (error) => {
      this.packageLoading = false;
    });
  }

  askForLogInOrRegister(planId : number) {
    this.selectedPlanId = planId;
    this.modal.open(this.askForLogin,{ size: 'xl', windowClass: 'dark-modal' });
  }

  askForLogInOrRegisterClose() {
    this.modal.dismissAll(this.askForLogin);
  }

  goToRegister(){
    this.router.navigate([`/register`],{ queryParams: { id: this.selectedPlanId}});
    this.askForLogInOrRegisterClose();
  }

  goToCheckout(id: number){
    this.selectedPlanId = id;
    this.router.navigate([`/check-out`],{ queryParams: { id: this.selectedPlanId, uid:this.userID}});
  }

  goToLogin(){
    this.router.navigate(['/login']);
    this.askForLogInOrRegisterClose();
  }


}
