<div class="bg-danger text-light p-2">
  <h3 class="text-center"><i class="fab fa-hotjar"></i> Hot dates</h3>
</div>

<full-calendar [options]="calendarOptions" class="fullCalender"></full-calendar>
<h3 class="text-light">
    Hot Dates
    <div class="clearfix"></div>
</h3>

<ng-template #eventDetails let-close="close">
    <div class="modal-header-event" style="background-image:url({{setEventBackground}});">
        <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
        <div class="d-flex justify-content-center mb-1 z_2 p-2">
            <h4 class="text-light"><i>{{actionEventData?.sort_description}}</i></h4>
        </div>
        <div class="d-flex justify-content-between mt-2 z_2 p-2">
            <span class="text-light"><i>Start Date:</i> <span class="badge bg-success text-light"> {{actionEventData?.start_date}}</span></span>
            <span class="text-light"><i>End Date:</i> <span class="badge bg-danger text-light"> {{actionEventData?.end_date}}</span></span>
        </div>

    </div>
    <div class="modal-body text-light">
        <p class=" text-light mb-2"><i>Address:</i> {{actionEventData?.address}}</p>
        <p class=" text-light mb-2"><i>Descriptioon:</i> {{actionEventData?.long_description}}</p>
        <p class=" text-light">{{actionEventData?.long_description}}</p>
    </div>
</ng-template>



  
  <div class="table-responsive">
    <table class="table table-dark table-striped">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Title</th>
          <th>Address</th>
          <th>Short Description</th>
          <th>Description</th>
          <th>Starts at</th>
          <th>Ends at</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let eventDetail of eventDateList;let i=index">
          <td>{{i+1}}.</td>
          <td class="text-light ">
            {{eventDetail?.sort_description}}
          </td>
          <td class="text-light">
            {{eventDetail?.address}}
          </td>
          <td class="text-light">
            {{eventDetail?.sort_description}}
          </td>
          <td class="text-light">
            {{eventDetail?.long_description}}
          </td>
          <td class="text-light">
            {{eventDetail?.start_date}}
          </td>
          <td class="text-light">
            {{eventDetail?.end_date}}
          </td>
          <td class="text-light" >
            <button class="btn btn-sm btn-warning" (click)="dateEventClick(' ',eventDetail?.id)">view</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>


