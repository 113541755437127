<app-header></app-header>
<section class="defualt_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="card text-light bg-dark set_outline">
                    <!-- <img src="{{sitelogo}}" class="img-fluid logo_cu"> -->
                    <div class="card-body">
                        <form [formGroup]="EmailVerifyForm" (ngSubmit)="onSubmit()">
                            <h1 class="text-center titles" ><i class="fab fa-keycdn" style="color: #ffc107;"></i> &nbsp;FORGOT PASSWORD</h1>
                            <!-- <h1 class="text-center"><i class="fab fa-keycdn"></i></h1>
                            <h3 class="text-center">FORGOT PASSWORD</h3> -->
                            <div class="form-group">
                                <label for="email">Please enter email address. We will send you an email to reset your password.</label>
                                <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email address is required</div>
                                </div>
                            </div>
                    

                            <button [disabled]="loading" class="btn btn-danger">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Submitt
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>