import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { User } from '@app/_models';
import { NotificationsServices } from "../../_services/notifications.service";
import { SharedService} from '../../_services/shared.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { NotificationsService } from "../../../app/notifications.service";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
    clickEventsubscription: Subscription;
    notificationdata: any = [];
    countnotifinum: any;
    defaultProfile: any;
    siteLogoUrl = environment.siteLogolight;
    currentUser: User;
    username: any;
    isLogin: boolean;
    checkNav = 0;
    checkDiviceWidth = window.innerWidth;
    notifis: boolean = false;
    emailAddress;
    showUnverified = false;
    loading : boolean = true; 
    @ViewChild('mobilenavWr') elRef: ElementRef;
    userId:any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private renderer: Renderer2,
        private NotificationsServices: NotificationsServices,
        private sharedService: SharedService,
        private windownotifiserveice: NotificationsService
    ) {
        this.authenticationService.currentUser.subscribe(x => {
            this.currentUser = x;
            if (this.currentUser != null) {
                this.username = this.currentUser.name;
                this.defaultProfile = this.currentUser.profile;
                this.isLogin = true;
                this.openNotifi();
                this.userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
            } else {
                this.isLogin = false;
            }
        });

        this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
          this.logout();
         })
    }

    ngOnInit(): void {

    }
    seemoreshow: boolean = false;
    notifiData: any[] = [];
    notification: any[] = [];
    request = new Array(this.notificationdata.length);
    buttonhoenothide:boolean;
    openNotifi() {
        this.NotificationsServices.getnotification().subscribe((res: any) => {
            let countnotifi = res;
            this.countnotifinum = countnotifi.count
            // console.log(res)
            this.notificationdata = res.data.data;
            // console.log(this.notificationdata)
            this.notifiData.length = 0;
            this.request.length = 0;
            for (let index = 0; index < this.notificationdata.length; index++) {
                const subject = JSON.parse(this.notificationdata[index].data);
                const sentby = JSON.parse(this.notificationdata[index].data);
                const FriendID = JSON.parse(this.notificationdata[index].data);
                const FriendImage = JSON.parse(this.notificationdata[index].data);
                const requestType = JSON.parse(this.notificationdata[index].data);
                const SubData = JSON.parse(this.notificationdata[index].data);
                const OrderId = JSON.parse(this.notificationdata[index].data);
                let data = {
                    id: this.notificationdata[index].id,
                    img: this.notificationdata[index].profilepic,
                    read: this.notificationdata[index].read_at,
                    create: this.notificationdata[index].created_at,
                    subject: subject.data,
                    sent_by: sentby.sent_by,
                    friend_user_id: FriendID.id,
                    requestType: requestType.requestType,
                    OrderId:OrderId.other_id,
                    status:this.notificationdata[index].status
                }
                this.seemoreshow = true;
                this.notifiData.push(data);
              
                if(requestType.requestType == "Friend Request sent" || requestType.requestType == "Sent Group Invitation" ){
                    if(this.notificationdata[index].status == 0){
                      this.request[index] = true;
                      this.buttonhoenothide = true;
                    }else if(this.notificationdata[index].status == 1){
                      this.request[index] = false;
                      this.buttonhoenothide = false;
                    }
                    else if(this.notificationdata[index].status == 2){
                      this.request[index] = false;
                      this.buttonhoenothide = false;
                    }
                   }
              
                if(this.notificationdata[index].read_at == null || this.notificationdata[index].read_at == ""){
                    let datas: Array < any >= []; 
                    datas.push({  
                            'title': sentby.sent_by,  
                            'alertContent': subject.data  
                    });  
                    this.windownotifiserveice.generateNotification(datas);  
                }


            }

        })
    }
    acceptrequest(friend_id, requestType, group_id, id) {
      // console.log(id)
      if (requestType == "Friend Request sent") {
        let body = {
          friend_user_id: friend_id,
          userId: this.userId
        }
        this.NotificationsServices.requestaccept(body).subscribe((res: any) => {
          let resp:any = [];
          resp = res;
          if(resp.status == 1){
            let body = {
              noti_id:id,
              status:1
            }
           this.NotificationsServices.updatenotifi(body).subscribe(res=>{
             this.openNotifi();
             this.readnotifi(id);
           })
          }
          Swal.fire("Success", `${res.message}`, "success");
          this.loading = false;
        }, error=>{
          this.loading = false;
          Swal.fire("error",'Something wrong please try again.', "success");
        });
  
      } else if (requestType == "Sent Group Invitation") {
        let body = {
          group_id: group_id,
          to_user_id:friend_id,
        }
        this.NotificationsServices.groupaccept(body).subscribe((res: any) => {
          let resp:any = [];
          resp = res;
          if(resp.status == 1){
            let body = {
              noti_id:id,
              status:1
            }
           this.NotificationsServices.updatenotifi(body).subscribe(res=>{
            this.openNotifi();
            this.readnotifi(id);
           })
          }
          Swal.fire("Success", `${res.message}`, "success");
          this.loading = false;
        }, error=>{
          this.loading = false;
          Swal.fire("error",'Something wrong please try again.', "success");
          })
      } else {
        return;
      }
  
    }
  
    rejectRequest(friend_id, requestType, group_id, id) {
      if (requestType == "Friend Request sent") {
        const data = {
        "from_user_id": this.userId,
        "to_user_id": friend_id,
         };
        this.NotificationsServices.requestreject(data).subscribe((res: any) => {
          let resp:any = [];
          resp = res;
          if(resp.status == 2){
            let body = {
              noti_id:id,
              status:2
            }
           this.NotificationsServices.updatenotifi(body).subscribe(res=>{
            this.openNotifi();
            this.readnotifi(id);
           })
          }
          Swal.fire("Success", `${res.message}`, "success");
          this.loading = false;
        },error=>{
          this.loading = false;
          Swal.fire("error",'Something wrong please try again.', "success");
        });
  
      } else if(requestType == "Sent Group Invitation"){
        let body = {
          group_id: group_id,
          to_user_id:friend_id,
        }
        this.NotificationsServices.groupreject(body).subscribe(res => {
          let resp:any = [];
          resp = res;
          if(resp.status == 2){
            let body = {
              noti_id:id,
              status:2
            }
           this.NotificationsServices.updatenotifi(body).subscribe(res=>{
            this.openNotifi();
             this.readnotifi(id);
           })
          }
          Swal.fire("Success", `${res.message}`, "success");
          this.loading = false;
        },error=>{
          this.loading = false;
          Swal.fire("error",'Something wrong please try again.', "success");
        })
      } else {
        return;
      }
    }
    seeAllNotifi() {
        this.router.navigate(['/dashboard/notifications'], { queryParams: { see: "see_more" } })
    }

    gotoNotification(id, requestType, friend_user_id, OrderId) {
      console.log(requestType)
        if(requestType == "Comment on profile"){
          this.router.navigate(['/dashboard/profile-comments'])
        }else if(requestType == "Friend Request sent"){
          this.router.navigate([`/${friend_user_id}/swinger/profile`],{queryParams:{t:"friend"}})
        }
        else if(requestType == "Create a hot date "){
          this.router.navigate([`/${friend_user_id}/swinger/hot-dates`], {queryParams:{d:OrderId}})
        }
        else if(requestType == "Create new Group"){
          this.router.navigate([`/${friend_user_id}/swinger/groups`], {queryParams:{d:OrderId}})
        }
        else if(requestType == "Create a new album" || requestType == "insert more album images"){
          this.router.navigate([`/${friend_user_id}/swinger/albums`], {queryParams:{d:OrderId}})
        }
    
        else if(requestType == "upload new video "){
          this.router.navigate([`/${friend_user_id}/swinger/videos`], {queryParams:{d:OrderId}})
        }

        else if(requestType == "Create new event "){
          this.router.navigate([`/${friend_user_id}/swinger/events`], {queryParams:{d:OrderId}})
        }
        else if(requestType == "upload time line photos"){
          this.router.navigate([`/${friend_user_id}/swinger/timeline`], {queryParams:{d:OrderId}})
        }
        else if(requestType == "Accept friend request"){
          this.router.navigate([`/${friend_user_id}/swinger/profile`],{queryParams:{t:"friend"}})
        }
        else if(requestType == "like album images "){
         this.router.navigate(['/dashboard/notifications'],{queryParams:{d:OrderId}})
        }
        else if(requestType == "like your timeline photo"){
          this.router.navigate(['/dashboard/notifications'],{queryParams:{d:OrderId}})
        }
        else if(requestType == "like your Video"){
          this.router.navigate(['/dashboard/notifications'],{queryParams:{d:OrderId}})
        } 
      this.readnotifi(id);
       

    }
    readnotifi(id:any){
      this.NotificationsServices.updatenotificationbyID(id).subscribe((res => {
        this.openNotifi();
    }))
    }

    logout() {
        this.authenticationService.logout().subscribe(res => {
            this.router.navigate(['/login']);
            setTimeout(function () {
                this.router.onSameUrlNavigation = 'reload';
            }, 50);
        });
    }

    playToy() {
        this.router.navigate(["/toys"]).then(result => { window.location.href = 'https://www.playfulobsessions.com/'; });
    }


    mobileNav(event: any) {
        if (this.checkNav == 0) {
            this.renderer.removeClass(this.elRef.nativeElement, "nav-hide");
            this.renderer.addClass(this.elRef.nativeElement, "nav-show");
            this.checkNav = 1;
        } else {
            this.renderer.removeClass(this.elRef.nativeElement, "nav-show");
            this.renderer.addClass(this.elRef.nativeElement, "nav-hide");
            this.checkNav = 0;
        }
    }




}
