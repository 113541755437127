
           <app-friend-navigation></app-friend-navigation>

           <div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp">
            <div class="card-header"><h6>Friends</h6></div>
            <div class="card-body over_flow_wrapper_big"  *ngIf="!freeuser">
              <div class="mb-2 bg-dark" *ngFor="let friend of firends">
               <app-list-friend class="card bg-dark mb-4 col-sm-12" [friend]='friend'></app-list-friend>
              </div>
              <div class="card-text text-center" *ngIf="loading">
                <div class="spinner-border text-light" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            </div>
            <div class="row mt-4"  *ngIf="!freeuser && pagelarg">
              <div class="col-md-12 text-center">
                <nav aria-label="..." class="text-center">
                  <ul class="pagination justify-content-center">
                    <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="mt-4" *ngIf="freeuser">
              <app-subscribe-plan></app-subscribe-plan>
             </div>
          </div>
      
