<div *ngIf="!uiHide">
<div class="row g-0">
    <div class="col-4 col-md-2 pr-1">
        <div class="user_profile_ls"><img [src]="swinger?.is_pic" alt="user-profile" class="img-fluid"></div>
    </div>
    
    <div class="col-8 col-md-8 pl-1">
      <div class="row">
        <div class="col-md-8 col-12">
          <div class="card-body">
            <h5 class="card-title mb-0"><a routerLink="/{{swinger?.id}}/swinger/profile" (click)="setProfileViews(swinger?.id)">{{swinger?.name}}</a></h5>
            <span *ngIf="swinger?.gender != null" [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{swinger?.gender|genderLable:swinger?.gender}}</span>
            
            <!-- <p class="card-text text-muted">{{swinger?.short_tag_line}}</p> -->
          </div>
        </div>
        <div class="col-md-4 col-12 m-flex-v">
            <p *ngIf="successMsg" class="text-small text-success">{{swingerStatus}}</p>
          <div class="btn-group mt-3 cm-m-btn" *ngIf="swinger.friendStatus == 'not firends'">
          <button type="button" class="btn btn-warning" (click)='sendFriendRequest(swinger.id)'><div *ngIf='requestLoding' class="spinner-border spinner-border-sm text-light" role="status">
            <span class="visually-hidden"></span>
          </div> <i class="fas fa-user-plus"></i></button>
          <a  class="btn btn-info" routerLink="/{{swinger?.id}}/swinger/profile" (click)="setProfileViews(swinger?.id)"><i class="fas fa-eye"></i></a>
          </div>
          <div class="btn-group mt-2 mb-2" *ngIf="swinger.friendStatus != 'not firends'">
            <span *ngIf="swinger?.friendStatus == 'send firends request'" class="badge bg-warning text-light"> send firends request</span>
            <span *ngIf="swinger?.friendStatus == 'Block'" class="badge bg-danger text-light"> Blocked</span>
            <span *ngIf="swinger?.friendStatus == 'firends'" class="badge bg-success text-light"> Friends</span>
            </div>
        </div>
      </div>
      
    </div>

   
  </div>
</div>

