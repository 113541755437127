
            <div class="card top_form">
                <div class="card-body">
                    <h2 class="form_heading">Swinger Flings turning
                        <span>fantasies into reality!</span></h2>
                        <p class="h5 mb-4 light-text">Meet Sexy singles & couples near you!!</p>
                    <form [formGroup]="lookingForm" (ngSubmit)="lookingMembers()">
                     
                        <div class="form-group mb-1">
                           
                            <div class="form-group">
                                <label for="username">You're Looking for...</label>
                                <select formControlName="first_gender"  class="form-control">
                                    <option selected >I am</option>
                                    <option *ngFor="let gender of genders" >{{gender}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div id="looking_for" class="form-text">What are you looking for?</div>
                            <select formControlName="second_gender"  class="form-control " aria-describedby="looking_for">
                                <option selected>Looking for...</option>
                                <option *ngFor="let gender of genders" >{{gender}}</option>
                            </select>
                            
                        </div>

                        <div class="row">
                          
                            <div class="col-md-6">
                                
                                <div class="form-group">
                                    <label>Age</label>
                                    <select formControlName="distanceFrom"  class="form-control ">
                                        <option  selected>From</option>
                                        <option *ngFor="let km of distanceInKm" >{{km}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label></label>
                                    <select formControlName="distanceTo"   class="form-control ">
                                        <option  selected>To</option>
                                        <option *ngFor="let km of distanceInKm" >{{km}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="address">Address</label>
                            <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
                        </div>
                      
                        <div class="form-group mt-4">
                            <select formControlName="distanceMiles" class="form-control" >
                                <option  selected>Distance in miles</option>
                                <option *ngFor="let distance of distances" >{{distance}}</option>
                            </select>
                        </div>

                        <button [disabled]="loading" class="btn btn-danger mt-4">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Search
                        </button>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </form>
                </div>
            </div>
    
       