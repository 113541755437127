<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header"><h6>Friends</h6></div>
    <div class="card-body over_flow_wrapper_big">
      <div class="card mb-2 bg-dark" *ngFor="let swinger of swingers">
        <app-friend-list [swinger]='swinger'></app-friend-list>
      </div>
      <div *ngIf="contentNotFound">
        <p class="text-center">Friends Not Found.</p>
      </div>
      <div class="card-text text-center" *ngIf="loading">
        <div class="spinner-border text-light" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    </div>
    <div *ngIf="checkpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
