import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Swinger } from '@app/_models/swinger';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }
    handleError: any;

    getAll() {
        return this.http.get<Swinger>(`${environment.apiUrl}/users`).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    getuser() {
        return this.http.get<Swinger>(`${environment.apiUrl}/user`).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    updateUserInfo(data) {
        return this.http.post<Swinger>(`${environment.apiUrl}/update-user-about-info`, data).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    updateUserPreferences(data) {
        return this.http.post<Swinger>(`${environment.apiUrl}/update-preferences`, data).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    getInterests() {
        return this.http.get<any>(`${environment.apiUrl}/get-all-interests`).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    getUserInterests() {
        return this.http.get<any>(`${environment.apiUrl}/get-user-interests`).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

    updateuserLocation(data){
        console.log(data);
        return this.http.post<Swinger>(`${environment.apiUrl}/update-user-location`, data).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

   userChangePass(data){
        console.log(data);
        return this.http.post<any>(`${environment.apiUrl}/change-password`, data).pipe(
            retry(1),
            catchError(this.handleError)
          );
    }

}