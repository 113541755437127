import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService, PlanService, GlobalLocationsService, UserService, CheckoutService } from '@app/_services';
import { first } from 'rxjs/operators';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from "../_services/shared.service";
@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.css']
})
export class CheckOutComponent implements OnInit {

  userID = JSON.parse(sessionStorage.getItem('currentUser'))?.id;
  currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  checkoutForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  data: any;
  options: FlatpickrDefaultsInterface;
  plan: any;
  planFeatures: any;
  freePlan = false;
  closeResult = '';
  termData = false;
  countries: any;
  states: any;
  cities: any;
  checkPlanId = true;
  userData: any;
  getCityId: any;
  monthArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  yearArray = ['2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  activePlanId: any;
  UserID: any
  constructor(
    private formBuilder: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private planService: PlanService,
    private modal: NgbModal,
    private globalLocation: GlobalLocationsService,
    private userService: UserService,
    private checkoutService: CheckoutService,
    private SharedService:SharedService
  ) {
    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      maxDate: '2002-01',
      enable: [
        {
          from: '1860-04-01',
          to: '2002-04-01',
        },
      ],
    };

    this.activeRoute.queryParams.subscribe(activeId => {
      this.activePlanId = activeId.id;
      this.UserID = activeId.uid;

      if (this.activePlanId) {
        this.getPlan(this.activePlanId);
      }
    });
  }

  selectedPlanDetails: any;

  @ViewChild('selectedPlan') selectedPlan: ElementRef;
  @ViewChild('termsCondition', { static: true }) termsCondition: TemplateRef<any>;

  ngOnInit(): void {
    this.checkoutForm = this.formBuilder.group({
      user_id: this.UserID,
      name: ['', Validators.required],
      last_name: [''],
      phone: ['', Validators.required],
      address_one: ['', Validators.required],
      cardNumber: ['', Validators.required],
      expiration_year: ['', Validators.required],
      expiration_month: ['', Validators.required],
      owner: [''],
      amount: ['', Validators.required],
      cvv: ['', Validators.required],
      discount_code: [''],
      email: ['', Validators.required],
      planId: this.activePlanId,
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
    });
    this.getCountries();

    if (this.userID) {
      this.getUserDetails();
    }

    // console.log(this.currentUser?.email_status)
  }

  get f() { return this.checkoutForm.controls; }
  response: any;
  userCheckout() {
    this.submitted = true;

    if (this.checkoutForm.invalid) {
      return;
    }

    const firstName = this.checkoutForm.get('name').value;
    this.checkoutForm.get('owner').setValue(firstName);

    this.loading = true;

    this.checkoutService.storePayment(this.checkoutForm.value).pipe(first()).subscribe(res => {
      this.loading = false;
      this.response = res;
      if (this.response.status == 422) {
        Swal.fire("error", `${this.response.message}`, "error");
        this.loading = false;
      } else {
        this.checkoutForm.reset();
        Swal.fire("Success", `${this.response.message}`, "success").then(ok=>{
          if(ok){
            this.SharedService.sendClickEvent();
            sessionStorage.clear();
            this.router.navigateByUrl('/login')
          }
        });
        this.loading = false;
      }
    })
   
  }


  getPlan(planId: number) {
    this.planService.getPlanById(planId).subscribe(res => {
      this.plan = res.data;
      this.checkoutForm.get('amount').setValue(this.plan.price);
    }, (error) => {
      console.log(error);
    });
  }



  getCountries() {
    this.globalLocation.getCountries().subscribe(res => {
      this.countries = res.data;
    }, (err) => {
      console.log(err);
    });
  }

  setCountry(countryId: number) {
    const con = this.countries.find(resData => {
      if (resData.id == countryId) {
        return resData;
      }
    });
    return con?.name;
  }

  getStates(id?: number, cityId?: number) {
    const countryName = this.checkoutForm.get('country').value;
    this.globalLocation.getStates(countryName).subscribe(res => {
      this.states = res.data;
      if (id) {
        const stateName = this.setState(id);
        this.checkoutForm.get('state').setValue(stateName);
        this.getCities(cityId);
      }
    }, (err) => {
      console.log(err);
    });
  }



  setState(stateId: number) {
    const stateData = this.states.find(resData => {
      if (resData.id == stateId) {
        return resData;
      }
    });
    return stateData?.name;
  }



  getCities(id?: number) {
    const stateName = this.checkoutForm.get('state').value;
    this.globalLocation.getCities(stateName).subscribe(res => {
      this.cities = res.data;
      if (id) {
        const cityName = this.setCities(id);
        this.checkoutForm.get('city').setValue(cityName);
      }
    }, (err) => {
      console.log(err);
    });
  }

  //  working on set cities
  setCities(cityId: number) {
    const cityData = this.cities.find(resData => {
      if (resData.id == cityId) {
        return resData;
      }
    });
    return cityData?.name;
  }


  getUserDetails() {
    this.userService.getuser().subscribe(res => {
      this.userData = res;
      this.getCityId = res.city_id;
      const countryData = this.setCountry(res.country_id);
      this.checkoutForm.get('name').setValue(res.name);
      this.checkoutForm.get('last_name').setValue(res.last_name);
      this.checkoutForm.get('phone').setValue(res.phone);
      this.checkoutForm.get('address_one').setValue(res.location);
      this.checkoutForm.get('email').setValue(res.email);
      // this.checkoutForm.get('planId').setValue(res.name);
      this.checkoutForm.get('country').setValue(countryData);
      this.getStates(res.state_id, res.city_id);
    }, (err) => {
      console.log(err);
    });
  }


}
