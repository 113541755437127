<div  *ngIf="!freeuser">
<full-calendar [options]="calendarOptions" class="fullCalender"></full-calendar>
<h3>
  Hot Dates
  <button class="btn btn-primary float-right" (click)="openAddNewHotdateModal()">
    + Add new
  </button>
  <div class="clearfix"></div>
</h3>

<ng-template #eventDetails let-close="close">
  <div class="modal-header-event" style="background-image:url({{setEventBackground}});">
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="d-flex justify-content-center mb-1 z_2 p-2">
      <h4  class="text-light"><i>{{eventData.sort_description}}</i></h4>
    </div>
    <div class="d-flex justify-content-between mt-2 z_2 p-2">
      <span  class="text-light"><i>Start Date:</i> <span class="badge bg-success text-light"> {{eventData.start_date}}</span></span>
      <span class="text-light"><i>End Date:</i> <span class="badge bg-danger text-light"> {{eventData.end_date}}</span></span>
    </div>
    
  </div>
  <div class="modal-body text-light">
    <p class=" text-light mb-2"><i>Address:</i> {{eventData.address}}</p>
    <p class=" text-light">{{eventData.long_description}}</p>
  </div>
</ng-template>

  <ng-template #addHotdateModalContent  let-close="close">
    <div class="modal-header bg-light" >
      <h5 class="modal-title">+Add Hot Date</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light" >
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="addHotdateEventForm" (ngSubmit)="addNewHotDate()">
            <div class="mb-3">
              <input type="text" class="form-control" formControlName="sort_description" id="sort_discription" placeholder="Hot Date Name">
            </div>
            <app-search-place  [mapShow]="mapShow"  (mapData)="getlatitudeLongitude($event)"></app-search-place>
           

            <!-- <div class="mb-3">
              <input type="text" class="form-control" formControlName="address" id="address" placeholder="Address">
            </div> -->

            <!-- <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <input type="text" class="form-control" formControlName="zip_code" id="zipcode" placeholder="Zip code">
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <input type="text" class="form-control" formControlName="city" id="city" placeholder="City">
                </div>
              </div>
           
            </div> -->
            
            <!-- <div class="mb-3">
              <input type="text" class="form-control" formControlName="country" id="city" placeholder="Country">
            </div>
             -->
          <div class="row">
            <div class="col-md-6">
              <div class="mb-3">
                <!-- <input type="text" class="form-control" formControlName="start_date" id="start_date" placeholder="Start Date" /> 
                 [(ngModel)]="event.start"
                -->
                <input
                class="form-control"
                type="text"
                mwlFlatpickr
                formControlName="start_date"
                [enableTime]="false"
                dateFormat="Y-m-d"
                placeholder="Start Date"
                [options]="options"
                (change)="checkdates($event);"
              />
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <input
                class="form-control"
                type="text"
                formControlName="end_date"
                mwlFlatpickr
                [enableTime]="false"
                dateFormat="Y-m-d"
                placeholder="End Date"
                [options]="options"
                (change)="checkdates($event);"
              />
                <!-- <input type="text" class="form-control"  id="end_date" placeholder="End Date"> -->
              </div>
            </div>
          </div>
            
          <div class="mb-3">
            <textarea type="text" class="form-control" formControlName="long_description" id="log_discription" placeholder="Description"> </textarea>
          </div>
          
  
        <div class="mb-3">
        <p class="text-right"><button type="submit" class="btn btn-primary" [disabled]="!addHotdateEventForm.valid">
          <span *ngIf="addEventLoader" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>Add Hot Date</button></p>
          </div>
        </form>
    </div>
  </div>
    </div>
  </ng-template>

  <app-hotdate-list [hotdateList]="hotdateList" ></app-hotdate-list>
  
  <div *ngIf="checkpages" class="row mt-4">
    <div class="col-md-12 text-center">
      <nav aria-label="..." class="text-center">
        <ul class="pagination justify-content-center">
          <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
        </ul>
      </nav>
    </div>
  </div>
</div>

<div class="mt-4" *ngIf="freeuser">
  <app-subscribe-plan></app-subscribe-plan>
 </div>
  