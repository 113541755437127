<app-dashboard-header></app-dashboard-header>
<app-user-navigation></app-user-navigation>
<div *ngIf="emailStatus">
    <div class="defualt_bg">
        <div class="container">
           <div class="row">
               <div class="col-md-12">
               <app-event-navigation></app-event-navigation>  
                <router-outlet></router-outlet>
               </div>
           </div>
        </div>
    </div>
