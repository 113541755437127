<app-header></app-header>
<app-top-banner [heading]='heading' [backgroundUrl]='backgroundImage'>loading...</app-top-banner>

<section class="defualt_bg p-0">
    <div class="container">
        <div class="row">
            <div class="page col-md-12" >
                <h1 class="text-center text-light">Terms and condition </h1>
            </div>
            </div>
        </div>
</section>

