import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendGroup } from '@app/_models/friend-group';
import { GroupServiceService } from "@app/_services";

@Component({
  selector: 'app-group-memberships',
  templateUrl: './group-memberships.component.html',
  styleUrls: ['./group-memberships.component.css']
})

export class GroupMembershipsComponent implements OnInit {

  defaultProfile: string = environment.defaultProfile;
  loading = true;
  groupBackground = '../assets/images/user-group.jpg';

  constructor(private groupService: GroupServiceService) { }
  firendGroups: FriendGroup;
  userID = JSON.parse(sessionStorage.getItem("currentUser")).id;

  ngOnInit(): void {
    this.getJoinedGroups();
  }


  getJoinedGroups(){
    this.groupService.getJoinedGroup().subscribe(res => {
      console.warn(res,'not added pagination');
      this.firendGroups = res.data;
      this.loading = false;
    });
  }


}
