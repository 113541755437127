<div class="firend-navigation">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-0">
              <ul class="nav">
                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/hot-dates/hot-date-list"  routerLinkActive="active"><i class="fas fa-fire"></i> My Hot Dates</a>
                  </li>

                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/hot-dates/swinger-hot-dates"  routerLinkActive="active"><i class="fas fa-fire"></i> Swinger Hot Dates</a>
                  </li>
                </ul>
          </div>
      </div>
  </div>
  </div>
  