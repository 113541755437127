
<!-- nav -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <div class="container">
    <a class="navbar-brand" routerLink="/"><img src="{{siteLogoUrl}}" class="img-fluid" id="sitelogo"></a>

    <div class="mob-nav-notification nav-item dropdown" *ngIf="isLogin">
      <a  class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i class="fas fa-bell"></i><span class="badge bg-success">{{countnotifinum}}</span></a>
      <div class="dropdown-menu dropdown-menu-lg-right dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-5">
        <a class="dropdown-item waves-effect waves-light dispblock "  *ngFor="let notifi of notifiData | slice:0:5; let i=index" [ngClass]="{'backgcolor' : notifi.read === null}">
         <div class="inl">
         <div class="notimg">
          <img [src]="notifi.img" onerror="this.src='../../../assets/images/avtar-image.jpg';" class="imgnotif">
         </div>
        </div>
        <div class="inl" >
          <div class="testdata" (click)="gotoNotification(notifi.id, notifi.requestType, notifi.friend_user_id, notifi.OrderId)">
            <h4 [ngClass]="{'nonread' : notifi.read === null}" class="username mb-0">{{notifi?.sent_by}}</h4>
            <span [ngClass]="{'nonread' : notifi.read === null}" class="notifisub text-muted">{{notifi?.subject}}</span>
          </div>
          <div class="testdata" *ngIf="request[i]">
           <div *ngIf="buttonhoenothide">
            <button type="button" class="btn btn-primary buttons" (click)="acceptrequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
              Accept
            </button>
            <button type="button" type="button" class="btn btn-light buttons" (click)="rejectRequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
              Decline
            </button>
           </div>
         
        </div>
        <div *ngIf="!request[i]">
            <p  *ngIf="notifi.status == 1" class="frindNotFriend">friend</p>
            <p *ngIf="notifi.status == 2" class="frindNotFriend"> not intrested</p>
        </div>
        </div>
        </a>
        <div *ngIf="seemoreshow" class="see_more_wrapper border-top-darken-1 border-top-1" (click)="seeAllNotifi()">
         <a class="text-light pr-3">see all <i class="fas fa-arrow-circle-right"></i></a>
        </div>
        <!-- <button  type="button" class="btn btn-light seemoredata"  style="float: right;"><a >See More</a></button> -->
      </div>
    </div>

    <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation">
      <span class="nav-bar-1"></span>
      <span class="nav-bar-2"></span>
      <span class="nav-bar-3"></span>
    </button>

    

    <div class="collapse navbar-collapse justify-end">
      <ul class="navbar-nav ms-auto me-0 ">
<!--           
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/community">COMMUNITY</a>
        </li> -->

        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/plan">PRICING/PLANS</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/hot-dates/swinger-hot-dates">HOT DATES</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/contact">CONTACT US</a>
        </li>

        <li class="nav-item">
          <a class="nav-link" (click)='playToy();'>TOY SHOP!</a>
        </li>
      </ul>
    </div>

    <div class="collapse navbar-collapse justify-end" id="right_navbar">
      <ul class="navbar-nav ms-auto me-0 ">
        <li class="nav-item" *ngIf="!isLogin">
          <a  class="btn btn-danger rounded-0 ps-2 pe-2 cu_btn" routerLink="/login">Login</a>
        </li>

        <li class="nav-item dropdown" *ngIf="isLogin">
          <a  class="nav-link " id="navbarDropdownMenuLink-5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i class="fas fa-bell"></i><span class="badge bg-success">{{countnotifinum}}</span></a>
          <div class="dropdown-menu dropdown-menu-lg-right dropdown-secondary" aria-labelledby="navbarDropdownMenuLink-5">
            <a class="dropdown-item waves-effect waves-light dispblock "  *ngFor="let notifi of notifiData | slice:0:5; let i=index" [ngClass]="{'backgcolor' : notifi.read === null}">
             <div class="inl">
             <div class="notimg">
              <img [src]="notifi.img" onerror="this.src='../../../assets/images/avtar-image.jpg';" class="imgnotif">
             </div>
            </div>
            <div class="inl" >
              <div class="testdata" (click)="gotoNotification(notifi.id, notifi.requestType, notifi.friend_user_id, notifi.OrderId)">
                <h4 [ngClass]="{'nonread' : notifi.read === null}" class="username mb-0">{{notifi?.sent_by}}</h4>
                <span [ngClass]="{'nonread' : notifi.read === null}" class="notifisub text-muted">{{notifi?.subject}}</span>
              </div>
              <div class="testdata" *ngIf="request[i]">
               <div *ngIf="buttonhoenothide">
                <button type="button" class="btn btn-primary buttons" (click)="acceptrequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
                  Accept
                </button>
                <button type="button" type="button" class="btn btn-light buttons" (click)="rejectRequest(notifi.friend_user_id, notifi.requestType,notifi.OrderId, notifi.id)">
                  Decline
                </button>
               </div>
             
            </div>
            <div *ngIf="!request[i]">
                <p  *ngIf="notifi.status == 1" class="frindNotFriend">friend</p>
                <p *ngIf="notifi.status == 2" class="frindNotFriend"> not intrested</p>
            </div>
            </div>
            </a>
            <div *ngIf="seemoreshow" class="see_more_wrapper border-top-darken-1 border-top-1" (click)="seeAllNotifi()">
             <a class="text-light pr-3">see all <i class="fas fa-arrow-circle-right"></i></a>
            </div>
            <!-- <button  type="button" class="btn btn-light seemoredata"  style="float: right;"><a >See More</a></button> -->
          </div>
        </li>

        <!-- <li class="nav-item" *ngIf="isLogin">
          <a  class="nav-link" routerLink="/"><i class="far fa-envelope"></i><span class="badge bg-success">5</span></a>
       </li> -->
     
        <li class="nav-item dropdown" *ngIf="isLogin">
            <a class="nav-link dropdown-toggle profile_image" href="javascript:void(0);" id="user_profile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="{{defaultProfile}}" #profileImg class="img-fluid">
            </a>
            <ul class="dropdown-menu" aria-labelledby="user_profile">
              <li><a class="dropdown-item" routerLink="/dashboard/swingers">Dashboard </a></li>
              <li><a class="dropdown-item"  routerLink="/dashboard/edit-profile">My Profile</a></li>
              <li><a class="dropdown-item" routerLink="/dashboard/change-password">Change Password</a></li>
              <li><a class="dropdown-item" href="javascript:void(0);" (click)="logout()">Logout</a></li>
            </ul>
        </li>
        <li class="nav-item" *ngIf="!isLogin">
          <a class="btn btn-warning rounded-0 ps-2 pe-2 cu_btn" routerLink="/register">Register</a>
        </li>
      </ul>
    </div>
  </div>
</nav>


 <div class="justify-end navbarSmallDiveces nav-hide" #mobilenavWr>
  <button class="navbar-toggler moble-nav" type="button" (click)='mobileNav($event);' id="mobile-navigation-close">
    <i class="far fa-times-circle text-light" style="font-size:32px"></i>
  </button>
  <ul class="navbar-nav ms-auto me-0 mt-2 ">
    <li class="nav-item dropdown" *ngIf="isLogin">
      <a class="nav-link profile_image" href="javascript:void(0);" id="user_profile">
            <img src="{{defaultProfile}}" class="img-fluid">
      </a>
      <span class="active-user-name text-light">{{username}}</span>
  </li>

  

    <li class="nav-item mt-4" *ngIf="!isLogin">
      <a  class="btn btn-danger rounded-0 ps-2 pe-2 cu_btn" routerLink="/login">Login</a>
      <a class="btn btn-warning rounded-0 ps-2 pe-2 cu_btn" routerLink="/register">Register</a>
    </li>

    <li class="nav-item mt-3">
      <a class="nav-link active" routerLink="/">HOME</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a  class="nav-link" routerLink="/dashboard/swingers">Dashboard</a>
    </li>


    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/plan">PRICING/PLANS</a>
    </li>


    <li class="nav-item">
      <a class="nav-link" routerLink="/contact">CONTACT US</a>
    </li>

    <li class="nav-item">
      <a class="nav-link" (click)='playToy();'>TOY SHOP!</a>
    </li>

    <li class="nav-item" *ngIf="isLogin">
      <a  class="nav-link" href="javascript:void(0);" (click)="logout()">Logout</a>
    </li>

  </ul>
</div>

