import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { GroupServiceService } from "@app/_services";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: 'app-group-invitations',
  templateUrl: './group-invitations.component.html',
  styleUrls: ['./group-invitations.component.css']
})
export class GroupInvitationsComponent implements OnInit {
  
  defaultProfile: string = environment.defaultProfile;
  loading = true;
  groupBackground = '../assets/images/user-group.jpg';
  groupInvitations: any;

  constructor(private groupService: GroupServiceService) { }
  
  userID = JSON.parse(sessionStorage.getItem("currentUser")).id;

  ngOnInit(): void {
    this.getAllInvitations();
  }


  getAllInvitations(){
    this.groupService.getGroupInvitation().subscribe(res => {
      this.groupInvitations = res.data;
      console.log(res);
      this.loading = false;
    });
  }

  acceptGroupInvitation(id:number, index:number){
    this.groupService.acceptGroupInvitation(id).subscribe(res => {
      Swal.fire("Success", `${res.message}`, "success");
      this.loading = false;
      this.getAllInvitations();
    }, error=>{
      this.loading = false;
      Swal.fire("error",'Something wrong please try again.', "success");
    });
  }

 rejectGroupInvitation(id:number, index:number){
    this.groupService.rejectGroupInvitation(id).subscribe(res => {
      Swal.fire("Success", `${res.message}`, "success");
      this.loading = false;
      this.getAllInvitations();
    },error=>{
      this.loading = false;
      Swal.fire("error",'Something wrong please try again.', "success");
    });
  }



}
