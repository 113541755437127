<!-- <section>
    <section class="mt-5">
        <div class="container-fluid">
            <div class="row row main_banner_row">
                <div class="col-md-6">
                    <div class="imgcontgroup">
                        <img src="../../../assets/images/hottestSwingers/Hottest/8" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="Card image cap">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-left mt_2 mb_2">
                        <h2 class="text_color2">Not your</h2>
                        <h2 class="text_color1">average swinger site..</h2>
                    </div>
                    <div>
                        <p>
                            Swingerflings.com is the world's hottest sex and swingers site with millions of members worldwide looking for sexually adventurous cou just like you. 
                            Chat live with sexy swingers couples and singles using o Instant Messenger Chat (IMC), exchange photos and videos, and get un close and personal. 
                            Join now for free and see how easy it is to meet an mingle on the world's hottest couple-swapping site!
                        </p>
                    </div>
                   
                    <div class="text-center mtb_15">
                        <button class="btn btn-warning"> SUBSCRIBE TO SWINGERFLINGS NOW </button>
                    </div>

                </div>
               
            </div>
       
        </div>
    </section>
</section> -->


<!-- How it works -->
<section style="background: #ffc107;" class="mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center mt_2 mb_2">
                    <h2 class="">Keep in Touch with the community</h2>
                    
                </div>
            </div>
        </div>
        <div class="row">
  
            <div class="col-md-12 text-center">
                <div class="text-center mtb_15">
                    <button class="btn buttons"> SUBSCRIBE TO SWINGERFLINGS NOW </button>
                </div>
            </div>
          
        </div>
    </div>
</section>