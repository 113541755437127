import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserMediaService } from '@app/_services/user-media.service';
import { environment } from '@environments/environment';
import { AuthenticationService, CommentService } from '@app/_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { CommonService, GalleryService, SwingersService } from '@app/_services';

@Component({
  selector: 'app-profile-comment-list',
  templateUrl: './profile-comment-list.component.html',
  styleUrls: ['./profile-comment-list.component.css']
})

export class ProfileCommentListComponent implements OnInit {
  
currentUser: any;
isLogin: any;
userProfileUrl: string;

  constructor(private userMedia: UserMediaService,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private formBuilder: FormBuilder,
              private commentService: CommentService,
              private commonService: CommonService,
              ) {
                this.authenticationService.currentUser.subscribe(x => {
                  this.currentUser = x;
                  if ( this.currentUser != null ){
                        this.isLogin = true;
                  }else{
                       this.isLogin = false;
                  }
              });
               }

  hidenav: boolean;
  id: number;
  defaultProfile: any = environment.defaultProfile;
  coverImgURL: any = '../assets/images/user_dashboard.jpg';
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  coverImagePath: any;
  dataId: any = '';
  profileId: any = '';
  profileloading = false;
  coverLoader = false;
  defaultNavigation = true;
  getSwingerProfile = false;
  swinger: any;
  c_t_class: string;
  totalComments: any;
  commentFormReply: FormGroup;
  activeSwingerId: number;
  commentLoading = false;
  commentDataLoading = false;
  pagination:any;

  ngOnInit(): void {
    this.hidenav = false;
    this.getSwingerProfile = true;
    this.route.parent.params.subscribe( params => {
      this.activeSwingerId = params.swingerId;
      this.userProfileUrl = this.currentUser.profile;
      this.getUserCommentsById(this.currentUser.id);
    });


    this.commentFormReply = this.formBuilder.group({
      swinger_id: [''],
      comment_swinger_profile_url:[''],
      comment_content:['', Validators.required],
      comment_parent_id:[''],
      comment_id:['']
    });
  }

  data: any;
  getUserCommentsById(userId: number){
    this.commentDataLoading = true;
      this.commentService.getUserProfileCommentsBy(userId).subscribe(res => {
        this.commentDataLoading = false;
        this.totalComments = res.data.data;
        this.pagination = res.data;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        for(const comment in this.totalComments){
          this.totalComments[comment].profile = this.totalComments[comment].profile;
          for(const commentData in this.totalComments[comment].data){
            this.totalComments[comment].data[commentData].profile = this.totalComments[comment].data[commentData].profile;
            }
          }
          // console.log(res)
          // console.log(this.pagination)
      })

  }

 showReplyComment(index: number, perReply?: number){

      if(index >= 0 && perReply >= 0){
        if(this.totalComments[index].data[perReply].replyStatus == true) {
          this.totalComments[index].data[perReply].replyStatus = false;
       }else{
          this.totalComments[index].data[perReply].replyStatus = true;
          this.commentFormReply.get('comment_content').setValue(`@${this.totalComments[index].data[perReply].user_name} `);
       }
      }else{
        if(this.totalComments[index].replyStatus == true) {
          this.totalComments[index].replyStatus = false;
       }else{
        this.commentFormReply.get('comment_content').setValue(`@${this.totalComments[index].user_name} `);
        this.totalComments[index].replyStatus = true;
       }
      }
    
 }

  replyComment(index: number, parentCommentId: number, comment_id: number) {
    this.commentLoading = true;
    this.commentFormReply.get('comment_swinger_profile_url').setValue(`${environment.hostUrl}/${this.currentUser.id}/swinger/profile`);
    this.commentFormReply.get('swinger_id').setValue('');
    this.commentFormReply.get('comment_parent_id').setValue(parentCommentId);
    this.commentFormReply.get('comment_id').setValue(comment_id);
    this.commentService.storeUserProfileComment(this.commentFormReply.value).subscribe(res => {
      this.commentLoading = true;
      this.commentFormReply.reset();
      this.getUserCommentsById(this.currentUser.id);
    });
  }

  commentLike(index: number, perReply: number, commentId: number){
    const data = {
      comment_id: commentId
    };

    if(index >= 0 && perReply >= 0){
      this.commentService.commentUserProfileLike(data).subscribe(res => {
        this.totalComments[index].data[perReply].totalLikes = res.data;
      },(error)=>{
        // console.log(error);
      });

    }else{
      this.commentService.commentUserProfileLike(data).subscribe(res => {
        this.totalComments[index].totalLikes = res.data;
      },(error)=> {
        // console.log(error);
      });
    }
  }
  loading:boolean = false;
  getPageData(url:string){
    this.loading = true;
    if(url){
    this.commonService.getPagination(url).subscribe(res => {
      this.commentDataLoading = false;
      this.totalComments = res.data.data;
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for(const comment in this.totalComments){
        this.totalComments[comment].profile = this.totalComments[comment].profile;
        for(const commentData in this.totalComments[comment].data){
          this.totalComments[comment].data[commentData].profile = this.totalComments[comment].data[commentData].profile;
          }
        }
    }, error => {
      this.loading = false;
    });
         
  }
   
  }


}
