<div class="row g-0" *ngIf="!hideIfFriend">
    <div class="col-3 col-md-2 pr-1">
        <div class="user_profile_ls"><img [src]="(requestedfirend.profile == null)?defaultProfile:profilePic" alt="user-profile" class="img-fluid" /></div>
    </div>
    <div class="col-9 col-md-10 pl-1">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="card-body">
            <h5 class="card-title"><a routerLink="/dashboard/{{requestedfirend.id}}/profile">{{requestedfirend.name}}</a></h5>
            <span *ngIf="requestedfirend?.gender != null" [ngClass]="requestedfirend?.gender=='f'?'badge-success':''||requestedfirend?.gender=='m'?'badge-info':''||requestedfirend?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{requestedfirend?.gender|genderLable:requestedfirend?.gender}}</span>
            <!-- <p class="card-text text-muted">{{requestedfirend?.short_tag_line}}</p> -->
          </div>
        </div>
      <div class="col-md-4 col-12">
        <div class="btn-group mt-4">
          <button type="button" class="btn btn-success" (click)="makeFriend(requestedfirend.id)"><div *ngIf="confirm" class="spinner-border spinner-border-sm text-light" role="status">
           <span class="visually-hidden"></span>
         </div> Confirm</button>
          <button type="button" class="btn btn-danger">Deny</button>
         </div>
      </div>
    </div>
  </div>