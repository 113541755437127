<!-- 
          
<div *ngIf="defaultNavigation">
    <section id="user-banner" style="background-image: url({{groupDetails?.cover_image?groupDetails?.cover_image:coverImgURL}});">
      <div *ngIf="coverLoader" class="spinner-border text-light spinner-border-sm cover_picture" role="status">
        <span class="visually-hidden"></span>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 text-right">
            <input #groupCoverPage type="file" class="d-none" accept='image/*' (change)="changeGroupCoverPage(groupCoverPage.files, dataId)" />
            <button *ngIf="groupDetails.groupCreatedStatus==1" class="btn btn-edit-cover" (click)="groupCoverPage.click()"><i class="fa fa-pencil" aria-hidden="true"></i> Edit Group Cover Page</button>
          </div>
        </div>
      </div>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                  <div class="profile_wrapper">
                    <div class="user_profile">
                       <input #porile type="file" class="d-none" accept='image/*' (change)="changeProfile(porile.files, profileId)" />
                       <div *ngIf="profileloading" class="spinner-border text-light spinner-border-sm pro_loader" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                        <img (click)="porile.click()" *ngIf="groupDetails.groupCreatedStatus==1 else swingerProfile" src="{{groupDetails?.profile_pic?groupDetails?.profile_pic:defaultProfile}}" class="img-fluid profile-image">

                        <ng-template #swingerProfile>
                          <img  src="{{groupDetails?.profile_pic?groupDetails?.profile_pic:defaultProfile}}" class="img-fluid profile-image">
                        </ng-template>
                    </div>
                    <div class="user_info_data">
                      <h5>{{groupDetails?.name}}</h5>
                      <p class="m-0">{{groupDetails?.sort_discription}}</p>
                      <p>Members <span class="badge bg-warning text-dark">{{groupDetails?.totalMember}}</span></p>
                      <button type="button" *ngIf="groupDetails?.groupCreatedStatus==1" class="btn btn-success edit-user-profile" routerLink='/group-panel/{{groupDetails.id}}/edit-group-info' routerLinkActive="active"><i class="fa fa-pencil" aria-hidden="true"></i> Edit group Profile</button>
                      <button *ngIf="groupDetails?.groupCreatedStatus!=1" type="button" class="btn btn-warning" (click)="joinGroup(group.id, i);" ><div 
                        *ngIf="group.requestStatus" class="spinner-border text-light spinner-border-sm" role="status">
                         <span class="visually-hidden"></span>
                       </div> Join Group</button>
                    </div>
                 </div>
              </div>
        </div>
        </div>
    </section>
   </div> -->
    

     <div class="defualt_bg pt-4">
         <div *ngIf="defaultNavigation" class="container">
            <div class="row">
                <div class="col-md-12">
                 <router-outlet></router-outlet>
                </div>
                
                <!-- <div  class="col-md-4">
                <app-group-right-panel [groupId]='groupId'></app-group-right-panel>
                </div> -->
            </div>
         </div>
     
         
     </div>
     