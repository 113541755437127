<div class="firend-navigation">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-0">
              <ul class="nav">
                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/group/create-group"  routerLinkActive="active"><i class="fa fa-plus" aria-hidden="true"></i> Create Group</a>
                  </li>

                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/group/group-membership"  routerLinkActive="active"><i class="fa fa-users" aria-hidden="true"></i> Joined Groups</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/group/group-invitation" routerLinkActive="active"><i class="fa fa-id-badge" aria-hidden="true"></i> Invite</a>
                  </li>
                 
                  <!-- <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/group/group-events" routerLinkActive="active"><i class="fa fa-id-badge" aria-hidden="true"></i> Events</a>
                  </li> -->
                </ul>
          </div>
      </div>
  </div>
  </div>
  