import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { environment } from "@environments/environment";
import { GroupServiceService,FriendsService, CommonService } from "@app/_services";

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})

export class CreateGroupComponent implements OnInit {

  pagination:any;
  constructor(
    private fb: FormBuilder,
    private groupService: GroupServiceService,
    private renderer: Renderer2,
    private friendService: FriendsService,
    private commonService: CommonService
  ) {}

  submitted = false;
  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  defaultProfile = environment.defaultProfile;
  loading = false;
  error = "";
  groupForm: FormGroup;
  openModel = false;
  groups: any;
  groupLoading = false;
  logdingImages = false;
  loadingPrivacyStatus = false;
  groupBackground = '../assets/images/user-group.jpg';
  friendList;
  checkpages = false;
  options = {multiple: true };

  ngOnInit(): void {
    this.groupForm = this.fb.group({
      userID: [this.userId],
      group_name: [" ", Validators.required],
      group_id: [" "],
      friends: [" "],
      privacy: ["", Validators.required],
    });
    this.getUserGroups(this.userId);
  }

  get f() {
    return this.groupForm.controls;
  }

 onSubmit() {
   this.submitted = true;
   const data = {
     'userId':this.groupForm.controls.userID.value,
     'group_id':this.groupForm.controls.group_id.value,
     'name':this.groupForm.controls.group_name.value,
     'privacy':this.groupForm.controls.privacy.value,
     'friends':this.groupForm.controls.friends.value,
   };
console.log(data)
    this.groupService.storeGroup(data).subscribe(res => {
      console.log(res);
      if(res){
         Swal.fire("Success", `${res.message}`, "success");
        this.openModel = false;
        this.getUserGroups(this.userId);
      }
    });
 
}


getAllFriends(){
  this.friendService.getFriends().subscribe(res=>{
    console.log(res.data.data);
    let data = res.data.data;
      for(let friend in data){
        data[friend].text = data[friend].name;
      }
    this.friendList = res.data.data;
  })
}

  getUserGroups(id: any) {
    this.groupLoading = true;
    this.groupService.getUserGroups().subscribe((res) => {
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.groups = res.data.data;
      this.groupLoading = false;
    }, error => {
      this.groupLoading = false;
    });
  }



  activePage(url:string) {
    this.groupLoading = true;
    if(url){
      this.commonService.getPagination(url).subscribe((res) => {
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.groups = res.data.data;
      this.groupLoading = false;
    }, error => {
      this.groupLoading = false;
    });
  }
  }



  openCreateAlbumModal() {
    this.openModel = true;
    this.getAllFriends();
  }

  closeCreateAlbumModal() {
    this.openModel = false;
  }



  deleteGroup(id: any, index: any) {
    Swal.fire({
      title: "Are you sure want to remove this group?",
      text: "You will not be able to recover this group!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.groupService.deleteGroup(id).subscribe(
          (res) => {
            this.getUserGroups(this.userId);
            Swal.fire(
              "Deleted!",
              "Your group file has been deleted.",
              "success"
            );
          },
          (error) => {
            Swal.fire(
              "Server Error",
              "Something is wrong please try again.",
              "error"
            );
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your group file is safe :)", "error");
      }
    });
  }

  privacyEditMode(data: any) {
    this.groups[data].privacy_mode = !this.groups[data].privacy_mode;
  }

  changeGroupPrivacy(id: any, privacy: any, index: number) {
    this.groups[index].privacy_mode = false;
    this.groups[index].loadingPrivacyStatus = true;

    const data = {
      id: id,
      privacy: privacy,
    };
    this.groupService.changeGroupPrivacy(data).subscribe((res) => {
      console.log(res);
      this.groups[index].privacy = privacy;
      this.groups[index].loadingPrivacyStatus = false;
    });
  }


}
