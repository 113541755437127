<app-user-gallery-navigation></app-user-gallery-navigation>
<div class="card bg-dark text-light mb-3 mt-2">
  <div class="card-header">
    <h6><span class="hoverPointer" (click)="openAlbumWrapper();">Albums</span> <span *ngIf="albumName"
        class="text-capitalize">| {{albumName}}</span> </h6>
  </div>
  <div class="card-body" *ngIf="!freeuser">
    <div class="row" id="wrapper">
      <div class="col-md-3 desktop-view">
        <div class="create-album-w  text-center" (click)="openCreateAlbumModal()">
          <i class="fas fa-plus"></i>
        </div>
      </div>

      <div class="col-md-3 m-view text-center mb-4">
        <button class="btn btn-sm btn-success"  (click)="openCreateAlbumModal()">
          <i class="fas fa-plus"></i> Create Album
        </button>
      </div>

      <div *ngIf="albumLoading">
        <div class="spinner-border text-light mt-4" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
      <ng-template [ngIf]="!openAlbumImages">
        <div class="col-md-3 col-6" *ngFor="let album of albums; let i = index;">
          <div class="top_wrapper">
            <div class="edit_mode_wrapper">
              <div [ngSwitch]="album.privacy" class='edit_mode_item_r'>

                <i *ngSwitchCase="2" (click)="privacyEditMode(i);" class="fas fa-user-friends"></i>
                <i *ngSwitchCase="3" (click)="privacyEditMode(i);" class="fas fa-lock"></i>
                <i *ngSwitchCase="1" (click)="privacyEditMode(i);" class="fas fa-globe"></i>
              </div>
              <span (click)="deleteAlbum(album.id, i)"><i class="fas fa-trash-alt"></i></span>
            </div>
          </div>
          <div *ngIf="album.privacy_mode" class="privacy_mode_wrapper">
            <ul>
              <li (click)="changeAlbumPrivacy(album.id, 1, i);"><i class="fas fa-globe"></i> Public</li>
              <li (click)="changeAlbumPrivacy(album.id, 2, i);"><i class="fas fa-user-friends"></i> Friends</li>
              <li (click)="changeAlbumPrivacy(album.id, 3, i);"><i class="fas fa-lock"></i> Only me</li>
            </ul>
          </div>
          <div *ngIf="album.deleteLoadingStatus" class="spinner-grow spinner-grow-sm staus_grow_delete" role="status">
            <span class="visually-hidden"></span>
          </div>
          <div *ngIf="album.loadingPrivacyStatus" class="spinner-grow spinner-grow-sm staus_grow" role="status">
            <span class="visually-hidden"></span>
          </div>

          <div class="create-album-wrapper text-center " (click)="openAlbum(album.id,i)">
            <img class="img-fluid" src="{{album.image}}" alt="{{album.name}}">
            <div class="bottom-text-wrapper">
              <p class="text-capitalize mb-0">{{album.name}}</p>
              <small>{{album.Totalalbum}} Photo's</small>
            </div>
          </div>
        </div>
      </ng-template>


      <div *ngIf="albumStatus" class="text-center">
        <div class="spinner-border text-light mt-4" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>


      <div class="col-md-3 col-6 p-1" *ngFor="let image of albumImages; let i=index">
        <div class="top_wrapper">
          <div class='video_views_wrapper'>
            <span (click)="albumImageLike(image.id,i)" class="like"><i class="fas fa-thumbs-up"></i>
              {{image.likes}}</span>
            <span><i class="fas fa-eye"></i> {{image.views}}</span>
          </div>
          <span (click)="deleteAlbumImage(image.id, image.album_id)" class="delete_wr"><i
              class="fas fa-trash-alt"></i></span>
        </div>
        <div class="image-wrapper text-center">
          <img src="{{image.s3Image}}" (click)="open(i)" class="img-fluid">
        </div>
      </div>
    </div>
    <!-- <div *ngIf="alImgcheckpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of alImgPagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="imageActivePage(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div> -->

    <div class="row">
      <div class="col-md-12">
        <div class="modal animate__animated animate__zoomIn" *ngIf="openModel" [ngClass]="openModel==true?'show':'hide'"
          id="createAlbum" role="dialog">
          <div class="modal-dialog ">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{albumModalHeading}}</h5>

                <button type="button" class="btn-close" (click)="closeCreateAlbumModal();" data-bs-dismiss="modal"
                  aria-label="Close"><i class="fas fa-times"></i></button>
              </div>
              <div class="modal-body">
                <div class="progress form-group" *ngIf="progress > 0">
                  <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                    [style.width.%]="progress">
                  </div>
                  <div class='count-progressbar bg-dark text-center text-light'>{{progress}} %</div>
                </div>

                <form [formGroup]="album" (ngSubmit)="storeGallery()">
                  <input type="text" formControlName="userID" class="form-control d-none" readonly />
                  <div class="form-group" *ngIf="!albumUpdateStatus">
                    <label for="sortTagline">Album Name</label>
                    <input type="text" class="form-control" formControlName="album_name">
                  </div>

                  <div class="form-group" *ngIf="!albumUpdateStatus">
                    <label for="location"></label>
                    <div class="row">
                      <div class="col-md-6">
                        <label>Select privacy</label>
                        <select class="form-control" formControlName="privacy">
                          <option disabled value="0" [selected]="0">Select Privacy</option>
                          <option value="1">Public</option>
                          <option value="2">Show only Friends</option>
                          <option value="3">Only Me</option>
                        </select>
                      </div>
                    </div>
                    <small *ngIf="!albumUpdateStatus">What type Swinger you want to show Album</small>

                    <div *ngIf="submitted && f.privacy.errors" class="invalid-feedback">
                      <div *ngIf="f.privacy.errors.required">Type is required.</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="file" #albumImages (change)="loadImages(albumImages.files)" accept='image/*'
                      class="form-control d-none" multiple="" formControlName="photos" />
                    <div *ngIf="submitted && f.photos.errors" class="invalid-feedback">
                      <div *ngIf="f.photos.errors.required">Photos is required</div>
                    </div>
                  </div>

                  <div class="row mb-3 over_flow">
                    <div class="col-md-3">
                      <div class="create_album_bx text-center mb-3" (click)="albumImages.click();">
                        <i class="fas fa-plus"></i>
                      </div>
                    </div>
                    <div class="col-md-3" *ngFor="let image of gallerImages">
                      <img src="{{image}}" class="img-fluid gallery_image_rs">
                    </div>
                  </div>


                  <div *ngIf="message" class="alert alert-success" role="alert"><i
                      class="fas fa-check-circle"></i>{{message}}</div>
                  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                  <button [disabled]="!album.valid" class="btn btn-warning">
                    <div *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                      <span class="visually-hidden"></span>
                    </div> Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!freeuser">
    <div *ngIf="checkpages" class="row mt-4">
      <div class="col-md-12 text-center">
        <nav aria-label="..." class="text-center">
          <ul class="pagination justify-content-center">
            <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
              [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)"
                [innerText]="link.label"></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
  </div>

</div>