import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.css']
})
export class TermsAndConditionComponent implements OnInit {

  constructor() { }
  heading: string = 'Terms & Conditions';
  backgroundImage: any = '/assets/images/login_background.jpg';

  ngOnInit(): void {
  }

}
