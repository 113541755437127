import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-event-wrapper',
  templateUrl: './event-wrapper.component.html',
  styleUrls: ['./event-wrapper.component.css']
})
export class EventWrapperComponent implements OnInit {

  emailStatus = true;
  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(check => {
      if(check.email_status!=1){
      this.emailStatus = false;
      }
    })
   }

  ngOnInit(): void {
  }

}
