import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, EventEmitter, Output } from '@angular/core';
import { MapsAPILoader} from '@agm/core';



@Component({
  selector: 'app-search-place',
  templateUrl: './search-place.component.html',
  styleUrls: ['./search-place.component.css']
})
export class SearchPlaceComponent implements OnInit {
  
  title: string = 'Swingers';
  latitude= 28.704060;
  longitude= 77.102493;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  @Input('mapShow') public mapShow:any;
  @Output() mapData: EventEmitter<any> = new EventEmitter();

  constructor(
     private mapsAPILoader: MapsAPILoader,
     private ngZone: NgZone) { }


  ngOnInit(): void {
    // google map code start here
    console.log(this.mapShow);
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
  
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddress(this.latitude, this.longitude);
          this.zoom = 12;
        });
      });
    });
  }


  private setCurrentLocation(lat?:any,long?:any) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if(lat && long){
          this.latitude = lat;
          this.longitude = long;
          this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
        }else{
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 8;
         this.getAddress(this.latitude, this.longitude);
        }
        
      });
    }
  }

  getAddress(latitude, longitude) {
   
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          const postal_code =  results[0].address_components.find(x =>{
            if(x.types[0] == "postal_code"){
              return x;
            }
            });
          this.mapData.emit({ lat: latitude, lng: longitude, postalCode:postal_code?.long_name,address: results[0]?.formatted_address});
          this.address = results[0]?.formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
  
    });
  }


}
