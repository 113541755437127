import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterUserComponent } from './register-user/register-user.component';
import { PlansComponent } from './plans/plans.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { CheckOutComponent } from './check-out/check-out.component';
import { ContactComponent } from './contact/contact.component';
import { EmailVerifyComponent } from './email-verify/email-verify.component';
import { SearchComponent } from './form/search/search.component'
import { ForgotPasswordComponent } from './forgot-password';
import { ConfirmPasswordComponent } from './confirm-password';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full'},
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterUserComponent },
    { path: 'search', component: SearchComponent },
    { path: 'plan', component: PlansComponent },
    { path: 'privacy-policy', component: PrivacypolicyComponent },
    { path: 'check-out', component: CheckOutComponent},
    { path: 'contact', component: ContactComponent},
    { path: 'term-conditoins', component: TermsAndConditionComponent},
    

    { path: 'forgot-password', component: ForgotPasswordComponent},
    { path: 'confirm-password', component: ConfirmPasswordComponent},

    { path: 'email-verify', component: EmailVerifyComponent},
    { path: '**', redirectTo: '' }
];

export const appRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });