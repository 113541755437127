<div class="card bg-dark text-light mb-3 mt-2" >
  <div class="card-body pb-2 d-flex justify-content-center align-content-center" style="height: 70vh;">
    <h4 class="text-center">Group Chatting Start Here...</h4>
  </div>
  </div>

<!-- <div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header"><h6><span class="hoverPointer" >+ Add Your Post</span></h6></div>
    <div class="card-body">
        <div class="row" id="wrapper">
            <div class="col-md-12">
              <textarea class="form-control costom_textarea" type="text" placeholder="Write something here..." (click)="openPostModal()" ></textarea>
            </div>
            </div>
        </div>
        </div>

        <div class="row">
            <div class="col-md-12">
             <div class="modal animate__animated animate__zoomIn" *ngIf="openModel" [ngClass]="openModel==true?'show':'hide'" id="createAlbum" role="dialog">
                <div class="modal-dialog ">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Create Post</h5>
            
                      <button type="button" class="btn-close" (click)="closePostModal();" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                    </div>
                        <div class="modal-body">
                            <form [formGroup]="postForm" (ngSubmit)="postSubmit();">
                                <input type="text" formControlName="userID" class="form-control d-none" readonly />
                            <div class="form-group" *ngIf="!albumUpdateStatus">
                                <textarea type="text" class="form-control"  style="height:80px" formControlName="group_name" placeholder="Write something here"></textarea>
                            </div>
            
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                      <label>Select privacy</label>
                                        <select class="form-control" formControlName="privacy">
                                            <option disabled value="0" [selected]="0">Select Privacy</option>
                                            <option value="1">Any one</option>
                                            <option value="2">Friend of Friend</option>
                                            <option value="3">Only My Friend</option>
                                        </select>
                                    </div>
                                </div>
                                <small class="text-secondary">What type Swinger you want to show post</small>
                            
                                <div *ngIf="submitted && f.privacy.errors" class="invalid-feedback">
                                    <div *ngIf="f.privacy.errors.required">Type is required.</div>
                                </div> 
                            </div>

                            <div class="form-group">
                              <input type="file" #file  multiple>
                            </div>
                           
                        <button  [disabled]="loading" class="btn btn-warning"><div 
                        *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
                        <span class="visually-hidden"></span>
                        </div> Post</button> 
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>

         <app-group-post-view></app-group-post-view>
             -->
