import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupServiceService {

  constructor(private http: HttpClient ) { }

  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

  getUserGroups() {
    return this.http.get<any>(`${environment.apiUrl}/get-user-group`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getJoinedGroup(){
    return this.http.get<any>(`${environment.apiUrl}/get-user-join-groups`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getAllGroups() {
    return this.http.get<any>(`${environment.apiUrl}/get-all-group`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  storeGroup(data:any) {
    return this.http.post<any>(`${environment.apiUrl}/create-group`,data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  deleteGroup(id: any){
    return this.http.post<any>(`${environment.apiUrl}/delete-group/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  changeGroupPrivacy(data: any){
    return this.http.post<any>(`${environment.apiUrl}/change-group-privacy`, data , this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  joinGroup(data: any){
    return this.http.post<any>(`${environment.apiUrl}/group-sent-request`,data, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGroupDetailById(id: any){
    return this.http.get<any>(`${environment.apiUrl}/get-group-detail/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGroupMembersById(id: any){
    return this.http.get<any>(`${environment.apiUrl}/get-all-active-member/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );

  }

  getGroupInvitation(){
    return this.http.get<any>(`${environment.apiUrl}/get-all-group-invitaion`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );

  }

  acceptGroupInvitation(id: any){
    return this.http.post<any>(`${environment.apiUrl}/accept-request/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );

  }

  rejectGroupInvitation(id: any){
    return this.http.post<any>(`${environment.apiUrl}/reject-request/${id}`, this.httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );

  }



}
