import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UserRoutingModule } from './user-routing.module';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FirendsComponent } from './friends/firends.component';
import { FriendNavigationComponent } from './friends/friend-navigation/friend-navigation.component';
import { FriendRequestSentComponent } from './friends/friend-request-sent/friend-request-sent.component';
import { FriendRequestPanddingComponent } from './friends/friend-request-pandding/friend-request-pandding.component';
import { RightNavigationPanelComponent } from './right-navigation-panel/right-navigation-panel.component';
import { LightboxModule } from 'ngx-lightbox';
import { BlockFriendsComponent } from './friends/block-friends/block-friends.component';
import { SwingersComponent } from './swingers/swingers.component';
import { SwingerNavigationComponent } from '../swingers/swinger-navigation/swinger-navigation.component';
import { WhoViewedComponent } from './who-viewed/who-viewed.component';
import { MatchesComponent } from './matches/matches.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileEditComponent } from './profile/profile-edit/profile-edit.component';
import { PanddingFriendComponent } from './friends/pandding-friend/pandding-friend.component';
import { ListItemComponent } from './friends/friend-request-sent/list-item/list-item.component';
import { FriendListComponent } from './friends/block-friends/friend-list/friend-list.component';
import { ListFriendComponent } from './friends/list-friend/list-friend.component';
import { FavouriteFriendsComponent } from './friends/favourite-friends/favourite-friends.component';
import { FavouriteComponent } from './friends/favourite-friends/favourite/favourite.component';
import { ProfileNavigationComponent } from './profile/profile-navigation/profile-navigation.component';
import { AboutYouComponent } from './profile/about-you/about-you.component';
import { LocationComponent } from './profile/location/location.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { PreferencesComponent } from './profile/preferences/preferences.component';
import { SwingerComponent } from './swinger/swinger.component';
import { UserGalleryComponent } from './user-gallery/user-gallery.component';
import { UserGalleryNavigationComponent } from './user-gallery/user-gallery-navigation/user-gallery-navigation.component';
import { CreateAlbumComponent } from './user-gallery/create-album/create-album.component';
import { UploadPhotosComponent } from './user-gallery/upload-photos/upload-photos.component';
import { UserVideosComponent } from './user-gallery/user-videos/user-videos.component';
import { VgCoreModule, } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharingModuleModule } from '../Sharing/sharing-module.module';
import { NotificationComponent } from './notification/notification.component';

import { ProfileCommentListComponent } from './profile-comment-list/profile-comment-list.component';
import { AgmCoreModule } from '@agm/core';
import { GenderLablePipe } from '../_pipes/gender-lable.pipe';
import { InvoceViewComponent } from './invoce-view/invoce-view.component';
import {NgxPrintModule} from 'ngx-print';
import { UserOnlineComponent } from "./user-online/user-online.component";

@NgModule({
  declarations: [
    DashboardComponent,
    FirendsComponent,
    FriendNavigationComponent,
    FriendRequestSentComponent,
    FriendRequestPanddingComponent,
    RightNavigationPanelComponent,
    BlockFriendsComponent,
    SwingersComponent,
    SwingerNavigationComponent,
    WhoViewedComponent,
    MatchesComponent,
    ProfileComponent,
    ProfileEditComponent,
    PanddingFriendComponent,
    ListItemComponent,
    FriendListComponent,
    ListFriendComponent,
    FavouriteFriendsComponent,
    FavouriteComponent,
    ProfileNavigationComponent,
    AboutYouComponent,
    LocationComponent,
    ChangePasswordComponent,
    PreferencesComponent,
    SwingerComponent,
    UserGalleryComponent,
    UserGalleryNavigationComponent,
    CreateAlbumComponent,
    UploadPhotosComponent,
    UserVideosComponent,
    NotificationComponent,
    ProfileCommentListComponent,
    GenderLablePipe,
    InvoceViewComponent,
    UserOnlineComponent,
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LightboxModule,
    UserRoutingModule,
    SharingModuleModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxPrintModule,
    NgxPaginationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCJO17hr0x0XPD8fhY4WxgxGb1muNBEhfE',
      libraries: ['places']
    }),
  ]
})

export class UserModule { }
