<div class="col-md-12 p-0">
    <div *ngIf="female" class="card bg-dark text-light mb-3 mt-2">
      <div class="card-header"><h6><i class="fas fa-female"></i> Female Info</h6></div>
        <div class="card-body pt-0">
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><span>Age</span> <span>{{swinger?.f_age}} Yrs</span></li>
            <li *ngIf="swinger?.f_height_feet" class="list-group-item"><span>Height</span> <span>{{swinger?.f_height_feet}} ft {{swinger?.f_height_in}} in</span></li>
            <li class="list-group-item"><span>Weight</span> <span>{{swinger?.f_weight}}</span></li>
            <li class="list-group-item"><span>Drink</span> <span>{{swinger?.f_drink}}</span></li>
            <li class="list-group-item"><span>Smoke</span> <span>{{swinger?.f_smoke}}</span></li>
            <li class="list-group-item"><span>Orientation</span> <span>{{swinger?.f_orientation}}</span></li>
          </ul>
        </div>
      </div>
      <div *ngIf="male" class="card bg-dark text-light mb-3 mt-2">
        <div class="card-header"><h6><i class="fas fa-male"></i> Male Info</h6></div>
          <div class="card-body pt-0">
            <ul class="list-group list-group-flush">
            <li *ngIf="swinger?.m_age" class="list-group-item"><span>Age</span> <span>{{swinger?.m_age}} Yrs</span></li>
            <li *ngIf="swinger?.f_height_feet" class="list-group-item"><span>Height</span> <span>{{swinger?.m_height_feet}} ft {{swinger?.m_height_in}} in</span></li>
            <li *ngIf="swinger?.m_weight" class="list-group-item"><span>Weight</span> <span>{{swinger?.m_weight}} Kg</span></li>
            <li class="list-group-item"><span>Drink</span> <span>{{swinger?.m_drink}}</span></li>
            <li class="list-group-item"><span>Smoke</span> <span>{{swinger?.m_smoke}}</span></li>
            <li class="list-group-item"><span>Orientation</span> <span>{{swinger?.m_orientation}}</span></li>
            </ul>
          </div>
        </div>
  </div>