import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: HttpClient ) { }

  handleError: any;
  httpOptions = {
    headers: new HttpHeaders({
        'Accept':'application/json',
        'Content-Type': 'application/json'
    })
};

storePayment(data: any) {
  return this.http.post<any>(`${environment.apiUrl}/handleonlinepay`, data, this.httpOptions).pipe(
    retry(1),
    catchError(this.handleError)
  );
}


}
