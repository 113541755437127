import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthenticationService } from '@app/_services';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    EmailVerifyForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    sitelogo = environment.siteLogolight;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }
    ngOnInit() {
        this.EmailVerifyForm = this.formBuilder.group({
            email: ['', Validators.required],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    }

    // convenience getter for easy access to form fields
    get f() { return this.EmailVerifyForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        // stop here if form is invalid
        if (this.EmailVerifyForm.invalid) {
            return;
        }

        let body = {
            email: this.f.email.value
        }
        this.authenticationService.forgotpassword(body).subscribe(res => {
            if(res){
               this.EmailVerifyForm.reset();
               this.router.navigate(['/login'])
            }
            Swal.fire("Success", `Password reset link send to your registered email. Please check your email.`, "success");
            this.loading = false;
          }, error=>{
            this.loading = false;
            Swal.fire("error",'Something wrong please try again.', "error");
        });
    }

}
