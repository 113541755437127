import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {
     emailStatus = true;
     constructor(private authenticationService: AuthenticationService) { 

      this.authenticationService.currentUser.subscribe(x => {
        
        if(x.email_status!=1){
          this.emailStatus = false;
        }
      })

     }

  ngOnInit(): void {
  }

}
