import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, SwingersService } from '@app/_services';
import { Swinger } from '@app/_models/swinger';

@Component({
  selector: 'app-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.css']
})
export class MatchesComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  loading = true;
  pagination: any;
  currentUser: any;
  freeuser = false;
  constructor(private swingersService: SwingersService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    ) {
      this.authenticationService.currentUser.subscribe(x => {
         this.currentUser = x;
        if(this.currentUser.plan_id == 1){
          this.freeuser = true;
        }
    });
	
     }
  swingers: Swinger;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;


  ngOnInit(): void {
    this.getMatcheSwingers();
  }

  getMatcheSwingers(){
    this.swingersService.getWhoMatches().subscribe(data => {
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.swingers = data.data.data;
      // console.log(this.swingers)
      for(let swinger in this.swingers){

        this.swingers[swinger].profile = this.swingers[swinger].profile ? this.swingers[swinger].profile: this.defaultProfile;

      }
      this.loading = false;
    });
  }


  activePage(url:string){
    if(url){
    this.commonService.getPagination(url).subscribe(data => {
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.swingers = data.data.data;
      for(let swinger in this.swingers){
        this.swingers[swinger].profile = (this.swingers[swinger].profile) ? `${environment.imgUrl}/${this.swingers[swinger].id}/images/user/profile/${this.swingers[swinger].profile}`: this.defaultProfile;
      }
      this.loading = false;
    });
  }
}


}
