import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { environment } from "@environments/environment";
import { CommonService, GalleryService, SwingersService } from "@app/_services";
import { Lightbox, LightboxEvent, LIGHTBOX_EVENT } from "ngx-lightbox";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsServices } from '../../_services/notifications.service'
@Component({
  selector: 'app-swinger-albums',
  templateUrl: './swinger-albums.component.html',
  styleUrls: ['./swinger-albums.component.css']
})
export class SwingerAlbumsComponent implements OnInit {
  IDs:any;
  constructor(
    private fb: FormBuilder,
    private galleryService: GalleryService,
    private renderer: Renderer2,
    private _lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private route: ActivatedRoute,
    private swingerService: SwingersService,
    private ActivatedRoute:ActivatedRoute,
    private NotificationsServices:NotificationsServices,
    private commonService: CommonService
  ) {
    this.ActivatedRoute.queryParams.subscribe(params=>{
      this.IDs = params['d'];
      if(this.IDs != null && this.IDs != undefined && this.IDs !=""){
       this.getalbumByID();
      }else{
        this.route.parent.params.subscribe( params => {
          this.swingerId = params.swingerId;
          this.getSwingerAlbums(params.swingerId);
        });
      }
     })
  }

  submitted = false;
  swingerId:number;
  defaultProfile = environment.defaultProfile;
  loading = false;
  ifSuccess = false;
  message: string;
  error = "";
  album: FormGroup;
  openModel = false;
  gallerImages = [];
  galleryFiles = [];
  albums: any;
  albumStatus = false;
  albumImages: any;
  albumName: string;
  bgSuccess: any;
  albumLoading = false;
  logdingImages = false;
  editMode = false;
  _subscription: Subscription;
  privacy_mode = true;
  loadingPrivacyStatus = false;
  openAlbumImages = false;
  albumId: number;
  albumIndex: number;
  albumUpdateStatus = false;
  albumModalHeading: string = "Create Album";
  pagination:any;
	checkpages = false;

  alImgPagination:any;
	alImgcheckpages  = false;

  ngOnInit(): void {
    this.album = this.fb.group({
      swingerId: [this.swingerId],
      album_name: ["", Validators.required],
      album_id: [""],
      photos: ["", Validators.required],
      privacy: ["", Validators.required],
    });


    
  }

  get f() {
    return this.album.controls;
  }

  loadImages(files: File) {
    if (files) {
      for (let file in files) {
        let reader = new FileReader();
        this.galleryFiles.push(files[file]);
        reader.readAsDataURL(files[file]);
        reader.onload = (_event) => {
          this.gallerImages.push(reader.result);
        };
      }
    }
  }
dataNotFound = false;
resposnse:any = [];
newID:any;
  getalbumByID(){
    this.NotificationsServices.getAlbumID(this.IDs).subscribe((res:any)=>{
      // console.warn(res)
      this.resposnse = res.data;
      this.newID = this.resposnse.id;
      if(this.resposnse.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
      if(this.resposnse.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
      this. getAlbumsImages(this.newID)
    })
  }

 

  getSwingerAlbums(id: any) {
    this.albumLoading = true;
    this.swingerService.getAllAlbumsBySwingerId(id).pipe(map(res=>{
      console.log(res)
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(image =>{
        image.src = image.image ? image.image: this.defaultProfile;
        image.privacy_mode = false;
      })
       return res.data.data;
    })).subscribe((res) => {
      this.albumLoading = false;
      this.albums = res;
      if(this.albums.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
    });
  }


  activePage(url:string) {
    this.albumLoading = true;
    if(url){
      this.commonService.getPagination(url).pipe(map(res=>{
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(image =>{
        image.src = image.image ? image.image: this.defaultProfile;
        image.privacy_mode = false;
      })
       return res.data.data;
    })).subscribe((res) => {
      this.albumLoading = false;
      this.albums = res;
      if(this.albums.length == 0 ){
        this.loading = false;
        this.dataNotFound = true;
      }
    });
  }
}



  getAlbumsImages(id: any) {
    this.albumStatus = true;
    this.galleryService.getAlbumsImages(id).subscribe((res) => {
      // console.log(res ,"jgksjag;fkj")
      this.albumStatus = false;
      this.albumImages = [];
      this.alImgPagination = res.data;
      this.alImgcheckpages  = this.alImgPagination.links.length == 4?true: false;
      this.alImgPagination.links[0].label = 'Previous';
      this.alImgPagination.links[this.alImgPagination.links.length - 1].label = 'Next';
      this.albumImages = res.data.data;
      this.albumName = res.data.data[0].name;
      for (let image in this.albumImages) {
        this.albumImages[image].src = this.albumImages[image].s3Image
          ? this.albumImages[image].s3Image
          : this.defaultProfile;
      }
      this.openAlbumImages = true;
    });
  }

  albumImageActivePage(url: string){
    this.albumStatus = true;
    if(url){
    this.commonService.getPagination(url).subscribe((res) => {
      this.albumStatus = false;
      this.albumImages = [];
      this.alImgPagination = res.data;
      this.alImgcheckpages  = this.alImgPagination.links.length == 4?true: false;
      this.alImgPagination.links[0].label = 'Previous';
      this.alImgPagination.links[this.alImgPagination.links.length - 1].label = 'Next';
      this.albumImages = res.data.data;
      this.albumName = res.data.data[0].name;
      for (let image in this.albumImages) {
        this.albumImages[image].src = this.albumImages[image].s3Image
          ? this.albumImages[image].s3Image
          : this.defaultProfile;
      }
      this.openAlbumImages = true;
    });
  }
}

  openAlbumWrapper() {
    this.openAlbumImages = false;
    this.albumImages = [];
    this.albumUpdateStatus = false;
    this.album.reset();
    this.albumModalHeading = "Create Album";
    window.location.reload();
    if (this.albumImages.length == 0){
     this.albumName = '';
    }
  }

  openAlbum(id: any, index: number) {
    // console.log(id)
    this.openAlbumImages = true;
    this.albumIndex = index;
    this.getAlbumsImages(id);
  }

  open(index: number): void {
    // console.log(index)
    this.albumImageView(index);
    this._lightbox.open(this.albumImages, index);
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event) =>
      this._onReceivedEvent(event)
    );
  }

  private _onReceivedEvent(event: any): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }

    if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
      this.albumImageView(event.data);
    }
  }

  close(): void {
    this._lightbox.close();
  }

 

  closeCreateAlbumModal() {
    this.openModel = false;
  }


  albumImageLike(id: number, index: number) {
    const data = {
      image_Id: id,
    };

    this.galleryService.getAlbumImageLike(data).subscribe((res) => {
      // console.log(res);
      this.albumImages[index].likes = res.totalLikes;
    });
  }

  albumImageView(index: number) {
    let id = this.albumImages[index].id;
    const data = {
      image_Id: id,
    };
    this.galleryService.getAlbumImageView(data).subscribe((res) => {
      // console.log(res);
    });
  }


}
