import { Component, OnInit, Input } from '@angular/core';
import { environment } from '@environments/environment';
import { FriendsService} from '@app/_services';
import { Friends } from '@app/_models/friends';

@Component({
  selector: 'app-pandding-friend',
  templateUrl: './pandding-friend.component.html',
  styleUrls: ['./pandding-friend.component.css']
})
export class PanddingFriendComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  constructor(private friendService: FriendsService) { }
  requestedFirend: Friends;
  confirm = false;
  hideIfFriend = false;
  @Input() requestedfirend: any;
  profilePic: any;

  ngOnInit(): void {
    this.profilePic = this.requestedfirend.profile;
  }

 makeFriend(friendId: any){
   this.confirm = true;
   const data = {"user_id": this.userId,
                "friend_user_id": friendId
                };
   this.friendService.makeFriend(data).subscribe( res => {
    this.confirm = false;
    this.hideIfFriend = true;
  });
 }



}
