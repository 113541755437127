import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Output, EventEmitter 
} from '@angular/core';

import { CalendarOptions } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';


import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { AuthenticationService, CommonService, HotDateService } from '@app/_services';


@Component({
  selector: 'app-hotdates',
  templateUrl: './hotdates.component.html',
  styleUrls: ['./hotdates.component.css'],
})
export class HotdatesComponent implements OnInit {
  userID = JSON.parse(sessionStorage.getItem('currentUser')).id;
  addHotdateEventForm: FormGroup;
  eventBackground = ['../../../assets/images/date_bg_1.jpg','../../../assets/images/date_bg_2.jpg',
'../../../assets/images/date_bg_3.jpg','../../../assets/images/date_bg_4.jpg','../../../assets/images/date_bg_5.jpg'
];
  setEventBackground: any;
  hotdateList: any;
  mapShow = false;
  eventData: any;
  
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    events: [
    ],
    plugins: [ interactionPlugin ],
    eventClick: this.dateEventClick.bind(this)
  };
    pagination:any;
		checkpages = false;
    freeuser = false;
    currentUser:any;
		
  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private hotdateServive: HotDateService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if(this.currentUser.plan_id == 1){
        this.freeuser = true;
      }
  });
  }

  options: FlatpickrDefaultsInterface;
  

  ngOnInit(): void {
    this.addHotdateEventForm = this.fb.group({
      address: [''],
      zip_code: [''],
      city: [''],
      start_date: [''],
      end_date: [''],
      latitude:[''],
      longitude:[''],
      sort_description: [''],
      long_description: [''],
      privacy: [''],
      country: [''],
      status: [''],
    });

    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      enable: [
        {
          from: new Date(),
          to: '2025-05-01',
        },
      ],
    };
    this.getUserHotdates();
    this.checkdates();
  }

  checkdates(){
    this.addHotdateEventForm.valueChanges.subscribe(val => {
      if(val.end_date){
      if(val.start_date>val.end_date){
        Swal.fire(
          'Error',
          'Please check start date and select valid end date.','error'
        );
        this.addHotdateEventForm.get('end_date').setValue('');
      }
    }
      
  });
}

 


  getlatitudeLongitude(mapData: any) {
      this.addHotdateEventForm.get('latitude').setValue(mapData.lat);
      this.addHotdateEventForm.get('longitude').setValue(mapData.lng);
      this.addHotdateEventForm.get('address').setValue(mapData.address);
      this.addHotdateEventForm.get('zip_code').setValue(mapData.postalCode);
  }

  @ViewChild('addHotdateModalContent', { static: false }) addHotdateModalContent: TemplateRef<any>;
  @ViewChild('eventDetails', { static: false }) eventDetails: TemplateRef<any>;


  openAddNewHotdateModal(){
    console.log('working')
    this.modal.open(this.addHotdateModalContent, { size: 'lg' });
  }

  closeHotdateModal(){
    this.modal.dismissAll(this.addHotdateModalContent);
  }
  addEventLoader = false;
  addNewHotDate(){
    const data = this.addHotdateEventForm.value;
    this.addEventLoader = true;
    this.hotdateServive.addHotDates(data).subscribe(res => {
      this.addEventLoader = false;
      Swal.fire(
        'Success',
        `${res.message}`,'success'
      );
      this.addHotdateEventForm.reset();
      this.getUserHotdates();
      this.closeHotdateModal();
    }, error => {
      Swal.fire(
        'Error',
        'Something wrong please Try again','error'
      );
      this.closeHotdateModal();
    });
  }



  getUserHotdates(){
    this.hotdateServive.getUserHotDates().pipe(map(res => {
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(hot => {
        hot.start = hot.start_date;
        hot.id = hot.id;
        hot.end = hot.end_date;
        hot.title = hot.sort_description;
        hot.source = hot;
        hot.classNames = `cl_date_${hot.id}`;
       })
       return res.data.data;
    })).subscribe(hotdates => {
      console.log(hotdates)
     this.hotdateList = hotdates.reverse();
     this.calendarOptions.events = this.hotdateList;
    });
  }


  activePage(url:string){
    if(url){
      this.commonService.getPagination(url).pipe(map(res => {
        this.pagination = res.data;
        this.checkpages = this.pagination.links.length >= 4?true: false;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        res.data.data.map(hot => {
          hot.start = hot.start_date;
          hot.id = hot.id;
          hot.end = hot.end_date;
          hot.title = hot.sort_description;
          hot.source = hot;
          hot.classNames = `cl_date_${hot.id}`;
         })
         return res.data.data;
      })).subscribe(hotdates => {
       this.hotdateList = hotdates.reverse();
       this.calendarOptions.events = this.hotdateList;
      });
    }
  }




  handleDateClick(arg) {
     console.log(arg);
   }

   dateEventClick(info) {
    const eventid =  info.event.id;
    this.setEventBackground = this.eventBackground[Math.floor(Math.random() * this.eventBackground.length)];
    this.hotdateServive.getHotDateById(eventid).subscribe(res => {
      this.eventData = res.data;
      this.modal.open(this.eventDetails, { size: 'lg' });
    });
    info.el.style.borderColor = '#696666';
    info.el.style.backgroundColor = '#6b796e';
  }



}
