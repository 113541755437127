import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import {ActivatedRoute } from '@angular/router';
import { CommonService, FriendsService, SwingersService} from '@app/_services';

@Component({
  selector: 'app-swinger-friends',
  templateUrl: './swinger-friends.component.html',
  styleUrls: ['./swinger-friends.component.css']
})
export class SwingerFriendsComponent implements OnInit {
  defaultProfile = environment.defaultProfile;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  loading = true;
  requestLoding = false;
  data: any;
  contentNotFound = false;
  pagination: any;
	checkpages = false;

  constructor(
      private friendsService: FriendsService,
      private swingerService: SwingersService,
      private route: ActivatedRoute,
      private commonService: CommonService
      ) { }
  swingers: any;

  ngOnInit(): void {
    this.route.parent.params.subscribe( params => {
      this.getSwingerFriends(params.swingerId);
    });

  }

  getSwingerFriends(id: any){
    this.swingerService.getAllFriendsBySwingerId(id).subscribe(res => {
      this.pagination = res.data;
      // console.log(res)
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.loading = false;
      this.swingers = res.data.data;
      console.warn(this.swingers );
    }, error => {
      this.loading = false;
    });
  }


  activePage(url:string){
    if(url){
      this.commonService.getPagination(url).subscribe(res => {
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.loading = false;
      this.swingers = res.data.data;
    }, error => {
      this.loading = false;
    });
  }
  }

  

}
