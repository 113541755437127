import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';
import { HotdatesComponent } from '@app/hot-dates/hotdates/hotdates.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-online',
  templateUrl: './user-online.component.html',
  styleUrls: ['./user-online.component.css']
})
export class UserOnlineComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  loading = true;
  currentUser: any;
  constructor(private friends: FriendsService, private router:Router,  private authenticationService: AuthenticationService,) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser.plan_id == 1) {
        this.freeuser = true;
      }
    });
   }
  friendsData:any;
  pagination:any;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  @ViewChild(HotdatesComponent) hotdates: HotdatesComponent;

  ngOnInit(): void {
    this.getOnlineFriends();
  }
  freeuser = false;
  pagelarg = true;
  dataNotFound = false;
  getOnlineFriends(){
    this.friends.getOnlineFriendslist().subscribe(data =>{
      this.loading = false;
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.friendsData = data.data.data;
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
     if(this.friendsData.length == 0 ){
      this.loading = false;
      this.dataNotFound = true;
    }
    }, error => {
      this.loading = false;
    });
  }

}


