
           <app-swinger-navigation></app-swinger-navigation>
           <div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp">
            <div class="card-header"><h6>Profile Matches</h6></div>
            <div class="card-body over_flow_wrapper_big"  *ngIf="!freeuser">
              <div class="card mb-2 bg-dark" *ngFor="let swinger of swingers">
                <div class="row g-0">
                  <div class="col-4 col-md-2 pr-1">
                      <div class="user_profile_ls"><img src="{{swinger.profile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid"></div>
                  </div>
                  <div class="col-8 col-md-6 pl-1">
                    <div class="row">
                       <div class="col-md-8 col-8">
                        <div class="card-body">
                          <h5 class="card-title mb-21" > <a routerLink="/{{swinger.id}}/swinger/profile">{{swinger.name}}</a></h5>
                          <span *ngIf="swinger?.gender != null" [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{swinger?.gender|genderLable:swinger?.gender}}</span>
                          <!-- <p class="card-text text-muted">{{swinger?.short_tag_line}}</p> -->
                        </div>
                       </div>
                       <div class="col-md-4 col-4">
                        <div class="text-md-center" *ngIf="swinger.swingerMatched > 0; else notMatched;">
                          <div class="matched-status matched">
                            {{swinger.swingerMatched}}%
                          </div>
                        </div>
                       </div>
                    </div>
                    
                  </div>

                  <ng-template #notMatched >
                    <div class="col-12 col-md-4 pl-1" >
                      <div class="matched-status not_matched">
                        {{swinger.swingerMatched}}%
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="card-text text-center" *ngIf="loading">
                <div class="spinner-border text-light" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            </div>
            <div class="row mt-4" *ngIf="!freeuser">
              <div class="col-md-12 text-center">
                <nav aria-label="..." class="text-center">
                  <ul class="pagination justify-content-center">
                    <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="mt-4" *ngIf="freeuser">
              <app-subscribe-plan></app-subscribe-plan>
             </div>
          </div>
      
