import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AuthenticationService, CommonService, GalleryService } from '@app/_services';
import { environment } from '@environments/environment';
import { Lightbox, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-upload-photos',
  templateUrl: './upload-photos.component.html',
  styleUrls: ['./upload-photos.component.css']
})

export class UploadPhotosComponent implements OnInit {

  freeuser = false;
  currentUser: any;

  constructor(private fb: FormBuilder,
    private galleryService: GalleryService,
    private _lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser.plan_id == 1) {
        this.freeuser = true;
      }
    });
  }





  _subscription: Subscription;
  submitted = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  defaultProfile = environment.defaultProfile;
  loading = false;
  message: string;
  error = '';
  timeline: FormGroup;
  openModel = false;
  gallerImages = [];
  galleryFiles = [];
  timeLineImages: any;
  timeLineStoreMessage: string;
  timeLineLoading = false;
  editMode = false;
  checkpages = false;
  pagination: any;
  progress: number = 0;


  ngOnInit(): void {
    this.timeline = this.fb.group({
      userID: [this.userId],
      photos: ['', Validators.required],
    });
    this.getTimelinePhotos(this.userId);
  }

  get f() { return this.timeline.controls; }

  checkImageSize(file) {
    if (file.size > 5097152) {
      Swal.fire(
        `File size error "${file.name}" file greater then 5 MB`,
        `make sure less then 5 MB then upload file.`,
        "error"
      );
      return false;
    } else {
      return true;
    }
  }


  loadImages(files: File) {
    if (files) {
      for (let file in files) {
        let reader = new FileReader();
        const checkfile = this.checkImageSize(files[file]);
        if (checkfile) {
          this.galleryFiles.push(files[file]);
          reader.readAsDataURL(files[file]);
          reader.onload = (_event) => {

            this.gallerImages.push(reader.result);
          }
        }
      }
    }
  }

  storeTimeline() {
    this.loading = true;
    this.submitted = true;
    if (this.timeline.invalid) {
      return;
    }

    const formData = new FormData();
    const bucketData = {
      FOLDER: 'photos/timeline'
    };
    let limitimage;
    for (let file in this.galleryFiles) {
      formData.append('images[]', this.galleryFiles[file]);
      limitimage = this.galleryFiles[file].length
    }

    formData.append('user_id', this.userId);
    if (this.galleryFiles.length <= 11) {
      // console.log(this.galleryFiles.length)
      this.galleryService.storeTimeLine(formData).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            // console.log('uploading '+this.progress + '%');
            break;
          case HttpEventType.Response:
            Swal.fire(
              'Success',
              'Images successfully uploaded.',
              'success'
            );
            this.closeCreateAlbumModal();
            setTimeout(() => {
              this.progress = 0;
            }, 1500);

            this.loading = false;
            this.timeLineStoreMessage = 'Successfully Uploaded';
            this.gallerImages = [];
            this.galleryFiles.length = 0;
            this.getTimelinePhotos(this.userId);

        }


      }, error => {
        Swal.fire(
          'Server Error',
          'Something is wrong please try again.',
          'error'
        );
        this.timeline.reset();
        this.gallerImages = [];
        this.loading = false;
        this.submitted = false;
      });
    } else {
      // console.log(this.galleryFiles.length)
      Swal.fire("Error", "10 photo's upload one time.", "error");
      this.gallerImages = [];
      this.galleryFiles.length = 0;
      this.timeline.reset();
      this.loading = false;
    }
  }




  getTimelinePhotos(id: any) {
    this.timeLineLoading = true;
    this.galleryService.getTimeLine(id).subscribe(res => {
      this.timeLineLoading = false;
      this.timeLineImages = res.data;
      // console.log(this.timeLineImages, 'data Check');
      // this.pagination = res.data;
      // this.checkpages = this.pagination.links.length >= 4?true: false;
      // this.pagination.links[0].label = 'Previous';
      // this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      for (let image in this.timeLineImages) {
        this.timeLineImages[image].src = this.timeLineImages[image].s3Image;
      }
    });
  }

  activePage(url: string) {
    // if(url){
    //   this.timeLineLoading = true;
    //   this.commonService.getPagination(url).subscribe(res => {
    //     this.timeLineLoading = false;
    //     this.timeLineImages = null;
    //     this.checkpages = this.pagination.links.length >= 4?true: false;
    //     this.timeLineImages = res.data.data;
    //     this.pagination = res.data;
    //     this.pagination.links[0].label = 'Previous';
    //     this.pagination.links[this.pagination.links.length - 1].label = 'Next';
    //     for(let image in this.timeLineImages ){
    //       this.timeLineImages[image].src = this.timeLineImages[image].s3Image;
    //      }
    //   });
    // }
  }


  open(index: number): void {
    this._lightbox.open(this.timeLineImages, index);
    this.storetimeLineView(index);
    this._subscription = this._lightboxEvent.lightboxEvent$
      .subscribe(event => this._onReceivedEvent(event));
  }

  close(): void {
    this._lightbox.close();
  }


  openCreateAlbumModal() {
    this.gallerImages = [];
    this.openModel = true;
    this.message = '';
  }

  closeCreateAlbumModal() {
    this.gallerImages = [];
    this.openModel = false;
  }

  private _onReceivedEvent(event: any): void {

    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }

    if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
      this.storetimeLineView(event.data);
    }

  }

  activeEditMode() {
    this.editMode = !this.editMode;
  }

  imageLike(id: any, index: number) {
    // console.log(id, index);
    this.galleryService.imageLike(id).subscribe(res => {
      this.timeLineImages[index].Likes = res.totalLikes;
    });
  }

  storetimeLineView(index: number) {
    const id = this.timeLineImages[index].id;
    this.galleryService.storeTimeLineView(id).subscribe(res => {
      this.timeLineImages[index].views = res.views;
    });
  }

  deleteImage(id: any) {
    Swal.fire({
      title: 'Are you sure want to remove this image?',
      text: 'You will not be able to recover this image!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.galleryService.deleteTimeLineImage(id).subscribe(res => {
          Swal.fire(
            'Deleted!',
            'Your image file has been deleted.',
            'success'
          );

          this.getTimelinePhotos(this.userId);
        }, error => {
          Swal.fire(
            'Server Error',
            'Something is wrong please try again.',
            'error'
          )
        });

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your image file is safe :)',
          'error'
        )
      }
    })
  }


}

