import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { environment } from "@environments/environment";
import { GroupServiceService,FriendsService, SwingersService } from "@app/_services";
import { ActivatedRoute } from "@angular/router";
import { identifierName } from "@angular/compiler";
import { Router } from "@angular/router";
import { NotificationsServices } from '../../_services/notifications.service'
@Component({
  selector: 'app-swinger-groups',
  templateUrl: './swinger-groups.component.html',
  styleUrls: ['./swinger-groups.component.css']
})

export class SwingerGroupsComponent implements OnInit {
  IDs:any;
  constructor(
    private fb: FormBuilder,
    private groupService: GroupServiceService,
    private renderer: Renderer2,
    private friendService: FriendsService,
    private swingerService: SwingersService,
    private route: ActivatedRoute,
    private NotificationsServices:NotificationsServices
  ) {
    this.route.queryParams.subscribe(params=>{
      this.IDs = params['d'];
      if(this.IDs != null && this.IDs != undefined && this.IDs !=""){
      //  this.getalbumByID();
      }
     })}

  submitted = false;
  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  defaultProfile = environment.defaultProfile;
  loading = false;
  error = "";
  groupForm: FormGroup;
  openModel = false;
  groups: any;
  groupLoading = false;
  logdingImages = false;
  loadingPrivacyStatus = false;
  groupBackground = '../assets/images/user-group.jpg';
  friendList;
  options = {multiple: true };

  ngOnInit(): void {
    this.route.parent.params.subscribe( params => {
      this.getSwingerGroups(params.swingerId);
    });
    
  }


getAllFriends(){
  this.friendService.getFriends().subscribe(res=>{
    console.log(res.data.data);
    let data = res.data.data;
      for(let friend in data){
        data[friend].text = data[friend].name;
      }
    this.friendList = res.data.data;
  })
}

  getSwingerGroups(id: any) {
    this.groupLoading = true;
    this.swingerService.getAllGroupsBySwingerId(id).subscribe((res) => {
      console.log(res.data);
      this.groups = res.data.data;
      console.log(this.groups);
      this.groupLoading = false;
    }, error => {
      console.log(error);
      this.groupLoading = false;
    });
  }



  openCreateAlbumModal() {
    this.openModel = true;
    this.getAllFriends();
  }

  closeCreateAlbumModal() {
    this.openModel = false;
  }





}
