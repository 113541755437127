<div class="email-verification-popup" *ngIf="showUnverified">
  <div class="pdin">
    <div *ngIf="emailVerify">
      <h4 class="text-center text-light mb-0">Please verify your email.</h4>
      <!-- <div class="text-center mt-2">
        <h4 class="text-center " style="color: rgb(206, 10, 10);font-size: 17px;">{{emailAddress}}</h4>
      </div> -->
  
      <!-- <a class="btn btn-danger new_close_btn" (click)="closeVerifyPopup();"><i class="fa fa-times"
          aria-hidden="true"></i></a> -->
    </div><br>
  
    <div *ngIf="showpaymentPopup" style="padding: 10px;">
      <h4 *ngIf="planids" class="text-center text-light mb-0">Please make your pending pyment for this Plan <span style="color: green;">
        {{PlaneName}}
      </span></h4>
      <h4 *ngIf="!planids" class="text-center text-light mb-0">Your subscription had been cancelled. 
        If you wish to continue with paid membership. Then, you need to upgrade your plan.      
      </h4>
      <div class="text-center mt-2" *ngIf="planids">
        <button class="btn btn-warning" (click)="gotopayment()">Make Payment</button>
      </div>

      <div class="text-center mt-2" *ngIf="!planids">
        <button class="btn btn-warning" (click)="gotopayment()">Upgrade Plan</button>
      </div>
      <!-- <a class="btn btn-danger new_close_btn" (click)="closepayPopup();"><i class="fa fa-times"
          aria-hidden="true"></i></a> -->
    </div>
  </div>
</div>


<section *ngIf="defaultNavigation && emailStatus && planstatus" id="user-banner" style="background-image: url({{coverImgURL}});">
  <div *ngIf="coverLoader" class="spinner-border text-light spinner-border-sm cover_picture" role="status">
    <span class="visually-hidden"></span>
  </div>
 
    <div class="container mt-4">
        <div class="row">
            <div class="col-md-6">
              <button class="btn btn-edit-cover m-view-icon" (click)="coverPage.click()"><i class="fa fa-camera" aria-hidden="true"></i> Cover Page</button>
              <div class="profile_wrapper">
                <div class="user_profile">
                   <input #porile type="file" class="d-none" accept='image/*' (change)="changeProfile(porile.files, profileId)" />
                   <div *ngIf="profileloading" class="spinner-border text-light spinner-border-sm pro_loader" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                    <img (click)="porile.click()" src="{{defaultProfile}}"  class="img-fluid profile-image">
                </div>
                <div class="m-view-icon">
                  <i class="fas fa-camera"></i>
                </div>
                <div class="user_info_data">
                  <h5>{{username}}</h5>
                  <h6><a routerLink="/dashboard/plan-invoce"><span class="badge bg-info custom_badge">{{subscribtion?.plan_name}}</span></a>   <a routerLink="/plan"><span *ngIf="subscribtion?.id==1" class="badge ml-2 bg-light custom_badge"> <i class="fas fa-star"></i> Upgrade Plan</span></a></h6>
                  <button type="button" class="btn btn-success edit-user-profile" routerLink='/dashboard/edit-profile' routerLinkActive="active"><i class="fa fa-pencil" aria-hidden="true"></i> Edit Profile</button> <button type="button" class="btn btn-warning edit-user-profile ml-2" routerLink='/dashboard/profile-comments' routerLinkActive="active"><i class="fas fa-eye"></i> Profile Comments</button>
                </div>
             </div>
          </div>
          <div class="col-md-6 thumb_navigation">
            <div class="row">
              <div class="col-md-4 col-sm-6 col-4">
                <div class="card text-dark bg-warning mb-3">
                  
                  <div class="card-body">
                    <h1 class="text-center mtmb"><i class="fa fa-users" aria-hidden="true"></i></h1>
                  </div>
                  <div class="card-header text-center">Online Users</div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-4">
              <div class="card text-white bg-primary mb-3">
                
                <div class="card-body">
                  <h1 class="text-center mtmb"><i class="fa fa-picture-o" aria-hidden="true"></i></h1>
                </div>
                <div class="card-header text-center">Gallery</div>
              </div>
          </div>
          <div class="col-md-4 col-sm-6 col-4">
            <div class="card text-white bg-success mb-3" >
              
              <div class="card-body">
                <h1 class="text-center mtmb"><i class="fa fa-comments" aria-hidden="true"></i></h1>
              </div>
              <div class="card-header text-center">Chat</div>
            </div>
        </div>
        <div class="col-md-12">
          <div class="card text-white bg-light mb-4">
            <div class="card-header text-center text-dark">200 Profile match with your interst. <a routerLink="/dashboard/matches"  routerLinkActive="active" >Visit Profiles</a></div>
          </div>
      </div>
      <div class="col-md-12 text-right">
        <input #coverPage type="file" class="d-none" accept='image/*' (change)="changeCoverPage(coverPage.files, dataId)" />
        <button class="btn btn-edit-cover" (click)="coverPage.click()"><i class="fa fa-camera" aria-hidden="true"></i> Cover Page</button>
      </div>
        </div>
        </div>
    </div>
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col-md-12 text-right">
          <input #coverPage type="file" class="d-none" accept='image/*' (change)="changeCoverPage(coverPage.files, dataId)" />
          <button class="btn btn-edit-cover" (click)="coverPage.click()"><i class="fa fa-camera" aria-hidden="true"></i> Cover Page</button>
        </div>
      </div>
    </div> -->
</section>

<app-user-menu *ngIf="defaultNavigation"></app-user-menu>

