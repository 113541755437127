import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genderLable'
})
export class GenderLablePipe implements PipeTransform {

  transform(value: string, gender: string): string {
    if(gender.toLowerCase() == 'm'){
      return 'Male';
    }else if(gender.toLowerCase() == 'f'){
      return 'Female';
    }else{
      return 'Couple';
    }
  }

}
