import { AfterViewInit, Component, OnInit, DoCheck} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  constructor(private router: Router) { }
  defaultNavigation = true;

  ngOnInit(): void {
    const currentUrl: string = this.router.url;
    if(currentUrl === '/group') {
     this.router.navigate(['/dashboard/swingers']);
    }
  }

}
