
           <app-swinger-navigation></app-swinger-navigation>
           <div class="card bg-dark text-light mb-3 mt-2 mb-bg-tp">
            <div class="card-header"><h6>Who Viewed Me</h6></div>
            <div *ngIf="!freeuser" class="card-body over_flow_wrapper_big">
              <div class="card mb-2 bg-dark col-lg-12 col-md-12 col-sm-12" *ngFor="let swinger of swingers">
                <div class="row g-0">
                  <div class="col-4 col-md-2 pr-1">
                      <div class="user_profile_ls"><img src="{{swinger?.profile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid"></div>
                  </div>
                  <div class="col-8 col-md-10 pl-1">
                    <div class="row">
                      <div class="col-md-8 col-12">
                        <div class="card-body">
                          <h5 class="card-title mb-21"><a routerLink="/{{swinger?.id}}/swinger/profile">{{swinger.name}}</a></h5>
                          <span *ngIf="swinger?.gender != null" [ngClass]="swinger?.gender=='f'?'badge-success':''||swinger?.gender=='m'?'badge-info':''||swinger?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{swinger?.gender|genderLable:swinger?.gender}}</span>
                          <!-- <p class="card-text text-muted">{{swinger?.short_tag_line}}</p> -->
                        </div>
                      </div>
                      <div class="col-12 col-md-4  pl-4 m-flex-v">
                        <div class="btn-group mt-3">
                          <span class="badge bg-light text-dark"><i class="fas fa-eye"></i> {{swinger.viewCount}}</span>
                        </div>
                    </div>

                    </div>
                  </div>
              </div>
              <div class="card-text text-center" *ngIf="loading">
                <div class="spinner-border text-light" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            </div>
            <div class="mt-4" *ngIf="freeuser">
              <app-subscribe-plan></app-subscribe-plan>
            </div>
          </div>
      
