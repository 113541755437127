﻿import { Component, OnInit, EventEmitter, Output, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@app/_services';
import { NotificationsService } from "./notifications.service";
import { SwPush } from '@angular/service-worker';
declare var device;
declare var google: any;
@Component({
  // moduleId: module.id,
  selector: 'app',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit {
  private socket: any;
  public data: any;
  defaultProfile: string = environment.defaultProfile;
  currentUser: any;
  isLogin = false;




  constructor(
    swPush: SwPush,
    private _notificationService: NotificationsService,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2,
    private elemref: ElementRef) {
    // this.socket = io(environment.hostUrl);
   // this._notificationService.requestPermission();
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser != null) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });
/*
    document.addEventListener('deviceready', function () {
      alert(device.platform);
    }, false);
*/
    let profile = sessionStorage.getItem('user-profile');
    if (profile) {
      this.defaultProfile = profile;
    }



  }


  ngOnInit() { }

}  
