import { Component, OnInit, Input, ElementRef, Renderer2 } from "@angular/core";
import { environment } from "@environments/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { FriendsService, ProfileViewService } from "@app/_services";
import { Friends } from "@app/_models/friends";

@Component({
  selector: "app-list-friend",
  templateUrl: "./list-friend.component.html",
})
export class ListFriendComponent implements OnInit {
  defaultProfile = environment.defaultProfile;
  blockloading = false;
  favouriteloading = false;
  uiHide = false;
  isFavourite = 0;
  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  constructor(
    private friendService: FriendsService,
    private renderer: Renderer2,
    private elm: ElementRef,
    private viewService: ProfileViewService
  ) {}
  firends: Friends;
  @Input() friend: any;
  profilePic: any;

  ngOnInit(): void {
    this.profilePic = this.friend.profile;
  }

  blockFriend(id: any) {
   

    Swal.fire({
      title: "Are you sure want to block this friend?",
      text: "Block friend show in to friend block section.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Block it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.blockloading = true;
        const data = {
          userId: this.userId,
          friendId: id,
        };
        this.friendService.blockFriend(data).subscribe(
          (res) => {
            this.uiHide = true;
            this.renderer.addClass(
              this.elm.nativeElement.parentElement,
              "d-none"
            );
            this.blockloading = false;
            Swal.fire(
              "Blocked!",
              "Your friend successfully blocked.",
              "success"
            );
          },
          (error) => {
            Swal.fire(
              "Server Error",
              "Something is wrong please try again.",
              "error"
            );
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your friend not blocked. :)", "error");
      }
    });
  }

  addToFavourite(id: any) {
    this.favouriteloading = true;
    const data = {
      userId: this.userId,
      friendId: id,
    };
    this.friendService.storeFavoriteFriend(data).subscribe((res) => {
      this.favouriteloading = false;
    });
  }

  setProfileViews(id: any) {
    console.log(id);
    const data = {
      swinger_id: id,
    };

    this.viewService.setProfileViews(data).subscribe((res) => {
      console.log(res);
    });
  }
}
