<div class="card" >
    <div class="card-body" #invoicedownload>
        <h3 class="text-center font-weight-bold mb-1"><img style="max-width: 272px;" [src]="siteLogoUrl" class="img-fluid"></h3>
        <!-- <p class="text-center font-weight-bold mb-0">GSTIN No.: 09AANFB4888NIZH</p> -->
        <p class="text-center font-weight-bold"><small class="font-weight-bold">Phone No.: {{invoceData.data?.phone}}</small></p>
        <div class="row pb-2 p-2">
            <div class="col-md-6">
             <p class="mb-0"><strong>Invoice Number</strong>: SW{{invoceData.data?.id}}</p>
             <p><strong>Name</strong>: {{invoceData.data?.first_name}}</p>						 
            </div>

            <div class="col-md-6 text-right">
             <p class="mb-0"><strong>Invoice Date</strong>: {{invoceData.data?.active_date}}</p>
             <p><strong>Phone</strong>: {{invoceData.data?.phone}}</p>
            </div>
        </div>
        <div class="table-responsive">
        <table class="table table-bordered mb-0">
            <thead>
                <tr>
                    <th class="text-uppercase small font-weight-bold">SR No.</th>
                    <th class="text-uppercase small font-weight-bold">Plan Name</th>
                    <th class="text-uppercase small font-weight-bold">Price</th>
                    <th class="text-uppercase small font-weight-bold">Desc</th>
                    <th class="text-uppercase small font-weight-bold">Tax %</th>
                    <th class="text-uppercase small font-weight-bold">Tax Amt.</th>
                    <th class="text-uppercase small font-weight-bold">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>{{invoceData.data?.plan_name}}</td>
                    <td>{{invoceData.data?.payment_amount | currency: 'USD'}}</td>
                    <td>{{invoceData.data?.discount_amount}}</td>
                    <td>{{0 | currency: 'USD'}}</td>
                    <td>{{0 | currency: 'USD'}}</td>
                    <td>{{invoceData.data?.payment_amount | currency: 'USD'}}</td>
                </tr>
            </tbody>
            <tfoot class="font-weight-bold small">
             <tr>
                <td colspan="5">Total</td>
                <td>{{0 | currency: 'USD'}}</td>
                <td>{{invoceData.data?.payment_amount | currency: 'USD'}}</td>
             </tr>
             
             
            </tfoot>
          </table>
        </div><!--table responsive end-->
        
           <p class="mt-2 mb-1">Thank you for choosing our service.We look forward to meet you again</p>
           <p class="mb-1">Money once paid will not we refunded. However, it can be abjected towards any services</p>
           <p class="font-weight-bold small mt-0">Other T &C Apply</p>
        
   </div>
   </div>
   <div class="text-right mt-1">
    <button class="btn btn-sm btn-primary" (click)="dwnloadinvoce()"><i class="fas fa-download"></i> Download</button>
   
   </div>