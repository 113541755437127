
<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header"><h6><span class="hoverPointer" >Groups</span></h6></div>
    <div class="card-body">
        <div class="text-center" *ngIf="groupLoading">
            <div class="spinner-border text-light mt-4" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>

        <div class="row" id="wrapper">
            <div class="col-md-3"  *ngFor="let group of groups; let i = index;">

              <div class="create-album-wrapper text-center" style="background-image:url({{groupBackground}})">
               <img class="img-fluid" src="{{group.src?group.src:groupBackground}}" alt="{{group.name}}">
                <div class="bottom-text-wrapper">
                  <p class="text-capitalize mb-0"><a routerLink="/group-panel/{{group.id}}/post">{{group.name}}</a></p>
                  <small>{{group.totalMember}} mermbers</small>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>


