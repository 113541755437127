export const environment = {
    production: false,
    apiUrl: 'https://demoapi.swingerflings.com/api',
    imgUrl: 'https://demoapi.swingerflings.com/public/storage/uploads',
    hostUrl: 'https://demoapi.swingerflings.com',
    siteLogolight: '../assets/images/Swingerflings-png-logo.png',
    siteLogoDark: '../assets/images/Swingerflings-logo.jpg',
    defaultProfile: '../assets/images/avtar-image.jpg',
};

// export const environment = {
//     production: false,
//     apiUrl: 'https://stagingapi.swingerflings.com/api',
//     imgUrl: 'https://stagingapi.swingerflings.com/public/storage/uploads',
//     hostUrl: 'https://stagingapi.swingerflings.com',
//     siteLogolight: '../assets/images/Swingerflings-png-logo.png',
//     siteLogoDark: '../assets/images/Swingerflings-logo.jpg',
//     defaultProfile: '../assets/images/avtar-image.jpg',
// };
