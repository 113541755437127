import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { GroupServiceService } from "@app/_services";
import { FriendGroup } from '@app/_models/friend-group';

@Component({
  selector: 'app-group-events',
  templateUrl: './group-events.component.html',
  styleUrls: ['./group-events.component.css']
})
export class GroupEventsComponent implements OnInit {

  defaultProfile: string = environment.defaultProfile;
  loading = true;
  constructor(private fgs: GroupServiceService) { }
  firendGroups: FriendGroup;

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups(){
    // this.fgs.getFirendsGroups().subscribe(data => {
    //   this.firendGroups = data;
    //   this.loading = false;
    // });
  }
}
