import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hot-date-navigation',
  templateUrl: './hot-date-navigation.component.html',
  styleUrls: ['./hot-date-navigation.component.css']
})
export class HotDateNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
