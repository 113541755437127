import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MailSubscriptionService } from '@app/_services';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private mailSubscription: MailSubscriptionService ) { }
  @ViewChild('mailInput', { static: true }) mailInput: ElementRef;

  ngOnInit(): void {
  }

  subscribeMail(emailData: string){
    const data = {
      'email': emailData
    };

    this.mailSubscription.mailSubscribe(data).subscribe(res =>{
      console.log(res);
      if(res.data == 1){
        Swal.fire(
          'Success',
          'Thank you for Subscription.','success'
        );
      }else{
        Swal.fire(
          'Info',
          `You have Already Subscribe.`,'info'
        );
      }
    }, (error) => {
      Swal.fire(
        'Error',
        `${error.message[0]}`,'error'
      );
    });
  }


}
