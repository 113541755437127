<div class="firend-navigation">
    <div class="container">
      <div class="row">
          <div class="col-md-12 p-0">
              <ul class="nav">
                <li class="nav-item">
                    <a class="btn btn-light " routerLink="/dashboard/edit-profile"  routerLinkActive="active"> About You</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light " routerLink="/dashboard/preferences"  routerLinkActive="active"> Preferences</a>
                  </li>
                  <li class="nav-item">
                    <a class="btn btn-light me-4" routerLink="/dashboard/location" routerLinkActive="active"> Location</a>
                  </li>
                </ul>
          </div>
      </div>
  </div>
  </div>
  