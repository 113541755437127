import { Component, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { environment } from "@environments/environment";
import { AuthenticationService, CommonService, GalleryService } from "@app/_services";
import { Lightbox, LightboxEvent, LIGHTBOX_EVENT } from "ngx-lightbox";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationsServices } from '../../../_services/notifications.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';

@Component({
  selector: "app-create-album",
  templateUrl: "./create-album.component.html",
  styleUrls: ["./create-album.component.css"],
})
export class CreateAlbumComponent implements OnInit {

  IDs:any;
  freeuser = false;
  currentUser:any;
  progress: number = 0;
  disabed = true;

  constructor(
    private fb: FormBuilder,
    private galleryService: GalleryService,
    private renderer: Renderer2,
    private _lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private commonService: CommonService,
    private ActivatedRoute:ActivatedRoute,
    private NotificationsServices:NotificationsServices,
    private authenticationService: AuthenticationService
    ) { 
      // this.ActivatedRoute.queryParams.subscribe(params=>{
      //  this.IDs = params['d'];
      //  if(this.IDs != null || this.IDs != undefined || this.IDs !=""){
      //   this.getalbumByID();
      //    console.log('working')
      //  }
      // })

      this.authenticationService.currentUser.subscribe(x => {
        this.currentUser = x;
        if(this.currentUser.plan_id == 1){
          this.freeuser = true;
        }
    });

    }

  submitted = false;
  userId = JSON.parse(sessionStorage.getItem("currentUser")).id;
  defaultProfile = environment.defaultProfile;
  loading = false;
  ifSuccess = false;
  message: string;
  error = "";
  album: FormGroup;
  openModel = false;
  gallerImages = [];
  galleryFiles = [];
  albums: any;
  albumStatus = false;
  albumImages: any;
  albumName: string;
  bgSuccess: any;
  albumLoading = false;
  logdingImages = false;
  editMode = false;
  _subscription: Subscription;
  privacy_mode = true;
  loadingPrivacyStatus = false;
  openAlbumImages = false;
  albumId: number;
  albumIndex: number;
  albumUpdateStatus = false;
  albumModalHeading: string = "Create Album";
  pagination:any;
	checkpages = false;

  alImgPagination:any;
	alImgcheckpages = false;

  ngOnInit(): void {
    this.album = this.fb.group({
      userID: [this.userId],
      album_name: ["", Validators.required],
      album_id: [""],
      photos: ["", Validators.required],
      privacy: ["", Validators.required],
    });
    this.getAlbums(this.userId);
  }


  


  checkImageSize(file){
    if(file.size > 5097152){
      Swal.fire(
        `File size error "${file.name}" file greater then 5 MB`,
        `make sure less then 5 MB then upload file.`,
        "error"
      );
     return false;
    }else{
      return true;
    }
  }

  get f() {
    return this.album.controls;
  }

  loadImages(files: File) {
    if (files) {
      for (let file in files) {
        let reader = new FileReader();
       const checkSize = this.checkImageSize(files[file]);
       if(checkSize){
        this.galleryFiles.push(files[file]);
        reader.readAsDataURL(files[file]);
        reader.onload = (_event) => {
          this.gallerImages.push(reader.result);
        };
      }
      }
    }
  }
disabledbtn = true;
  storeGallery() {
    this.loading = true;
    this.submitted = true;
    if (this.album.invalid) {
      return;
    }
    let limitimage;
    let formData = new FormData();
    for (let file in this.galleryFiles) {
        formData.append("photos[]", this.galleryFiles[file]);
        limitimage = this.galleryFiles[file].length
    }
    formData.append("user_id", this.userId);
    formData.append("album_id", this.album.controls["album_id"].value);
    formData.append("album_name", this.album.controls["album_name"].value);
    formData.append("privacy", this.album.controls["privacy"].value);
     if(this.galleryFiles.length <= 11){
      this.galleryService.storeAlbum(formData).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
          Swal.fire("Success", "Request Complited.", "success");
          this.closeCreateAlbumModal();
            setTimeout(() => {
              this.progress = 0;
            }, 1500);
  
            this.loading = false;
          this.message = 'Successfully uploaded.';
          this.gallerImages = [];
          
        
          this.getAlbums(this.userId);
          this.openAlbumImages = false;
          this.galleryFiles= [];
          if(this.album.controls["album_id"].value!=''){
            this.getAlbumsImages(this.album.controls["album_id"].value);
          }
          this.album.reset();
        }
  
        },
        (error) => {
          Swal.fire(
            "Server Error",
            "Something is wrong please try again.",
            "error"
          );
          this.openAlbumImages = false;
          this.galleryFiles = [];
        }
      );
     }else{
       console.log(this.galleryFiles.length)
      Swal.fire("Error", "10 photo's upload one time.", "error");
      this.openAlbumImages = false;
      this.galleryFiles = [];
      this.gallerImages.length = 0;
       this.album.reset();
      this.loading = false;
    }
  }

  // getalbumByID(){
  //   this.NotificationsServices.getAlbumID(this.IDs).subscribe((res)=>{
  //     console.log(res)
  //   })
  // }

  getAlbums(id: any) {
    this.albumLoading = true;
    this.galleryService.getAlbums(id).pipe(map(res=>{
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length == 4? true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(item=>{
        item.src = item.image ? item.image: this.defaultProfile;
        item.privacy_mode = false;
      })
      return res.data.data;
    })).subscribe((res) => {
      this.albumLoading = false;
      this.albums = res;
    });
  }


  activePage(url:string) {
    this.albumLoading = true;
    if(url){
      this.commonService.getPagination(url).pipe(map(res=>{
      this.pagination = res.data;
      this.checkpages = this.pagination.links.length >= 4?true: false;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      res.data.data.map(item=>{
        item.src = item.image ? item.image : this.defaultProfile;
        item.privacy_mode = false;
      })
      return res.data.data;
    })).subscribe((res) => {
      this.albumLoading = false;
      this.albums = res;
      // console.log(this.albums);
    });
  }
  }




  getAlbumsImages(id: any) {
    this.albumStatus = true;
    this.galleryService.getAlbumsImages(id).subscribe((res) => {
      // console.warn(res);
      this.albumStatus = false;
      this.albumImages = [];
      this.albumImages = res.data;
      this.albumName = res.data[0].name;
      for (let image in this.albumImages) {
        this.albumImages[image].src = this.albumImages[image].s3Image?this.albumImages[image].s3Image:this.defaultProfile;
      }
      this.openAlbumImages = true;
    });
  }


//   imageActivePage(url: string){
//     this.albumStatus = true;
//     if(url){
//       this.commonService.getPagination(url).subscribe((res) => {
//       this.albumStatus = false;
//       this.albumImages = [];
//       this.alImgPagination = res.data;
//       this.alImgcheckpages  = this.alImgPagination.links.length == 4 ?true: false;
//       this.alImgPagination.links[0].label = 'Previous';
//       this.alImgPagination.links[this.alImgPagination.links.length - 1].label = 'Next';
//       this.albumImages = res.data.data;
//       this.albumName = res.data.data[0].name;
//       for (let image in this.albumImages) {
//         this.albumImages[image].src = this.albumImages[image].s3Image?this.albumImages[image].s3Image:this.defaultProfile;
//       }
//       this.openAlbumImages = true;
//     });
//   }
// }



  openAlbumWrapper() {
    this.openAlbumImages = false;
    this.albumImages = [];
    this.albumUpdateStatus = false;
    this.album.reset();
    this.albumModalHeading = "Create Album";
    window.location.reload();
    if (this.albumImages.length == 0){
     this.albumName = '';
    }
  }

  openAlbum(id: any, index: number) {
    this.openAlbumImages = true;
    this.albumIndex = index;
    this.getAlbumsImages(id);
  }

  open(index: number): void {
    this.albumImageView(index);
    this._lightbox.open(this.albumImages, index);
    this._subscription = this._lightboxEvent.lightboxEvent$.subscribe((event) =>
      this._onReceivedEvent(event)
    );
  }

  private _onReceivedEvent(event: any): void {
    if (event.id === LIGHTBOX_EVENT.CLOSE) {
      this._subscription.unsubscribe();
    }

    if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
      this.albumImageView(event.data);
    }
  }

  close(): void {
    this._lightbox.close();
  }

  openCreateAlbumModal() {
    this.openModel = true;
    this.message = "";
    if (this.openAlbumImages) {
      const activeAlbumData: any = this.albums[this.albumIndex];
      this.albumUpdateStatus = true;
      this.albumModalHeading = `Add more images in to ${activeAlbumData.name}`;
      this.album.controls.userID.setValue(activeAlbumData.userId);
      this.album.controls.album_name.setValue(activeAlbumData.name);
      this.album.controls.album_id.setValue(activeAlbumData.id);
      this.album.controls.privacy.setValue(activeAlbumData.privacy);
      this.openAlbumImages= true;
    }
  }

  closeCreateAlbumModal() {
    this.openModel = false;
  }

  deleteAlbum(id: any, index: any) {

    Swal.fire({
      title: "Are you sure want to remove this group?",
      text: "You will not be able to recover this group!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.albums[index].deleteLoadingStatus = true;
        this.galleryService.deleteAlbum(id).subscribe(
          (res) => {
            this.albums[index].deleteLoadingStatus = false;
            this.getAlbums(this.userId);

            Swal.fire(
              "Deleted!",
              "Your group file has been deleted.",
              "success"
            );
          },
          (error) => {
            Swal.fire(
              "Server Error",
              "Something is wrong please try again.",
              "error"
            );
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your group file is safe :)", "error");
      }
    });
  }

  privacyEditMode(data: any) {
    this.albums[data].privacy_mode = !this.albums[data].privacy_mode;
  }

  changeAlbumPrivacy(id: any, privacy: any, index: number) {
    this.albums[index].privacy_mode = false;
    this.albums[index].loadingPrivacyStatus = true;

    const data = {
      id: id,
      privacy: privacy,
    };
    this.galleryService.changeAlbumPrivacy(data).subscribe((res) => {
      this.albums[index].privacy = privacy;
      this.albums[index].loadingPrivacyStatus = false;
    });
  }

  albumImageLike(id: number, index: number) {
    const data = {
      image_Id: id,
    };

    this.galleryService.getAlbumImageLike(data).subscribe((res) => {
      // console.log(res);
      this.albumImages[index].likes = res.totalLikes;
    });
  }

  albumImageView(index: number) {
    let id = this.albumImages[index].id;
    const data = {
      image_Id: id,
    };
    this.galleryService.getAlbumImageView(data).subscribe((res) => {
      // console.log(res);
    });
  }

  deleteAlbumImage(id: number, albumId2: number) {
    Swal.fire({
      title: "Are you sure want to remove this image?",
      text: "You will not be able to recover this image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        const data = {
          imageId: id,
          albumId: albumId2,
        };

        this.galleryService.deleteAlbumImage(data).subscribe(
          (res) => {
            Swal.fire(
              "Deleted!",
              "Your image file has been deleted.",
              "success"
            );
            this.getAlbumsImages(albumId2);
          },
          (error) => {
            Swal.fire(
              "Server Error",
              "Something is wrong please try again.",
              "error"
            );
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your image file is safe :)", "error");
      }
    });
  }
}
