

  <div class="row">
    <div class="col-md-12">
      <div class="card bg-dark text-light mb-3 mt-2">
        <div class="card-header"><h6>About </h6></div>
          <div class="card-body ">
          <p>{{swinger?.describe_your_self}}</p>
          <p>{{swinger?.additional_info}}</p>
          <h6 class="bg_ddark"><i class="fas fa-eye"></i> Looking for</h6>
          <p>{{swinger?.lookingfor}}</p>

          <h6 class="bg_ddark"><i class="fas fa-heart"></i> Interested
          </h6>
          <ul class="pl-2">
            <li class="bullet-none" *ngFor="let ints of swinger?.intrests">
              <i class="fas fa-check-circle text-success"></i> {{ints}}
            </li>
          </ul>
          <h6 class="bg_ddark f-align-2"><span><i class="fas fa-wine-glass-alt"></i> Like to meet people that Drink</span> <span [class]="c_t_class">{{swinger?.like_drink}}</span></h6>
          <h6 class="bg_ddark f-align-2"><span><i class="fas fa-smoking"></i> Like to meet people that Smoke</span><span [class]="c_t_class">{{swinger?.like_smoke}}</span></h6>
           <h6 class="bg_ddark"><i class="fas fa-map-marker-alt"></i> Location</h6>
            <p *ngIf="swinger?.location || swinger?.zipcode">{{swinger?.location}}, {{swinger?.zipcode}}</p>
          </div>
        </div>
   
  
  <div class="main-comment-wrapper">
  <div class="comment_wrapper">
    <div class="row">
        <div class="col-md-12">
          <h4 class="text-light">Comments</h4>
        <div class="card bg-dark text-light mb-2">
            <div class="card-body">
              <form [formGroup]="commentForm" (ngSubmit)="addComment()">
                <img [src]="userProfileUrl?userProfileUrl:defaultProfile" onerror="this.src='../../../assets/images/avtar-image.jpg';" class="img-fluid rounded-circle comment-user-profile">
                <h6><a href="javascript:void(0);"  class="text-light">{{swinger?.name}}</a></h6> 
                <textarea
                class="_comment_wrapper form-control comment-textbox"
                type="text"
                formControlName="comment_content"
                placeholder="Write something to {{swinger?.name}}"
                ></textarea>
                <p class="text-right mt-2">
                <button class="btn btn-primary btn-sm" [disabled]="!commentForm.valid">
                    <i *ngIf="!commentLoading" class="fas fa-paper-plane"></i> <div *ngIf="commentLoading" class="spinner-grow spinner-grow-sm text-light" role="status">
                      <span class="visually-hidden"></span>
                    </div> Submit
                    </button>
                </p>
              </form>
            </div> 
        </div>
    </div>
    </div>
  </div>
  <div class="row" *ngFor="let comment of totalComments; let l= index">
    <div class="col-sm-12">
        <div class="swinger-comment" [ngClass]="comment?.comment_parent_id!=0?'child-comment':'parent-comment'">
        <img src="{{comment?.profile}}" class="img-fluid rounded-circle comment-user-profile">
        <h6><a href="javascript:void(0);"  class="text-light">{{comment?.user_name}}</a></h6>
        <p class="text-secondery comment-text">{{comment?.comment_content}}</p>
        <div class="d-flex justify-content-around">
            <a href="javascript:void(0);" (click)="commentLike(l,'-1',comment.id)" class="text-light">Like <span class="badge bg-light text-dark">{{comment?.totalLikes}}</span></a>
            <a href="javascript:void(0);" (click)="showReplyComment(l)" class="text-light">Reply</a>
        </div>
        <form *ngIf="comment?.replyStatus" [formGroup]="commentFormReply" (ngSubmit)="replyComment(l,comment.user_id, comment.id)">
          <textarea
          class="_comment_wrapper comment-textbox mt-2"
          type="text"
          formControlName="comment_content"
          placeholder="Write a comment..."
          style="height:50px"></textarea>
          <p class="text-right mt-2">
          <button class="btn btn-primary btn-sm" [disabled]="!commentFormReply.valid">
              <i class="fas fa-paper-plane"></i> Submit
          </button>
          </p>
        </form>
    </div>
  </div>
    <div class="row w-100" *ngFor="let rComment of comment.data; let i= index">
      <div class="col-sm-12">
        <div class="swinger-comment" [ngClass]="rComment?.comment_parent_id!=0?'child-comment':'parent-comment'">
        <img src="{{rComment.profile}}" class="img-fluid rounded-circle comment-user-profile">
        <h6><a href="javascript:void(0);"  class="text-light">{{rComment?.user_name}}</a></h6>
        <p class="text-secondery comment-text">{{rComment?.comment_content}}</p>
        <div class="d-flex justify-content-around">
            <a href="javascript:void(0);" (click)="commentLike(l, i, comment.id);" class="text-light">Like <span class="badge bg-light text-dark">{{rComment?.totalLikes}}</span></a>
            <a href="javascript:void(0);" (click)="showReplyComment(l,i)" class="text-light">Reply</a>
        </div>
        <form *ngIf="rComment?.replyStatus" [formGroup]="commentFormReply" (ngSubmit)="replyComment(l,i,comment.user_id, comment.id)">
          <textarea
          class="_comment_wrapper comment-textbox mt-2"
          type="text"
          formControlName="comment_content"
          placeholder="Write a comment..."
          style="height:50px"></textarea>
          <p class="text-right mt-2">
          <button class="btn btn-primary btn-sm" [disabled]="!commentFormReply.valid">
              <i class="fas fa-paper-plane"></i> Submit
              </button>
          </p>
        </form>
    </div>
  </div>
    </div>
 </div>
 </div>
</div>
</div>
