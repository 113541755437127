import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { SharingModuleModule } from '../Sharing/sharing-module.module';

import { EventsRoutingModule } from './events-routing.module';
import { EventWrapperComponent } from './event-wrapper/event-wrapper.component';
import { EventsComponent } from './events/events.component';
import { EventNavigationComponent } from './event-navigation/event-navigation.component';
import { SwingerEventsComponent } from './swinger-events/swinger-events.component';
import { EventListComponent } from './event-list/event-list.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [EventWrapperComponent, EventsComponent, EventNavigationComponent, SwingerEventsComponent, EventListComponent],

  imports: [
    CommonModule,
    SharingModuleModule,
    EventsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    FullCalendarModule,
    FlatpickrModule.forRoot()
  ],
})

export class EventsModule { }
