<app-user-gallery-navigation></app-user-gallery-navigation>
<div class="card bg-dark text-light mb-3 mt-2">
<div class="card-header"><h6>Create Gallery</h6></div>
<div class="card-body over_flow_wrapper_big">
    <div class="card mb-2 bg-dark m-50-wrapper" >
    </div>
    <div class="card-text text-center">
    <div class="spinner-border text-light" role="status">
    <span class="visually-hidden"></span>
    </div>
</div>
</div>
</div>
