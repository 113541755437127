
<app-dashboard-header></app-dashboard-header>
<app-user-navigation></app-user-navigation>
  <div class="defualt_bg">
      <div *ngIf="defaultNavigation; else fullView" class="container">
         <div class="row">
             <div class="col-md-8">
              <router-outlet></router-outlet>
             </div>
             
  
             <div *ngIf="defaultNavigation" class="col-md-4">
              <app-right-navigation-panel></app-right-navigation-panel>
             </div>
         </div>
      </div>
  
      <ng-template #fullView>
          <div class="container-fluid">
              <div class="row">
             <div class="col-md-12">
             <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </ng-template>
  </div>
  
      
 
 