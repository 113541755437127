import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { CalendarOptions } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';

import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { FlatpickrDefaultsInterface } from 'angularx-flatpickr/flatpickr-defaults.service';
import { EventService, HotDateService, SwingersService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { NotificationsServices } from '../../_services/notifications.service'

@Component({
  selector: 'app-swinger-hot-date-view',
  templateUrl: './swinger-hot-date-view.component.html',
  styleUrls: ['./swinger-hot-date-view.component.css']
})
export class SwingerHotDateViewComponent implements OnInit {
  butnhides:boolean = true;
  IDs:any;
  userID = JSON.parse(sessionStorage.getItem('currentUser')).id;
  addEventdateForm: FormGroup;
  eventBackground = ['../../../assets/images/date_bg_1.jpg','../../../assets/images/date_bg_2.jpg',
'../../../assets/images/date_bg_3.jpg','../../../assets/images/date_bg_4.jpg','../../../assets/images/date_bg_5.jpg'
];
  setEventBackground: any;
  eventDateList: any;
  actionEventData: any;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this),
    events: [
    ],
    plugins: [ interactionPlugin ],
    eventClick: this.dateEventClick.bind(this)
  };
  constructor(
    private modal: NgbModal,
    private fb: FormBuilder,
    private hotdateServive: HotDateService,
    private swingerService: SwingersService, private route: ActivatedRoute,
    private NotificationsServices:NotificationsServices
  ) {
    this.route.queryParams.subscribe(params=>{
      this.IDs = params['d'];
      if(this.IDs != null && this.IDs != undefined && this.IDs !=""){
       this.getalbumByID();
       this.butnhides = false;
      }else{
        this.route.parent.params.subscribe( params => {
          this.getSwingerHotDates(params.swingerId);
          this.butnhides = true;
        });
      }
     })

  }

  options: FlatpickrDefaultsInterface;
  addEventLoader = false;

  ngOnInit(): void {
   

    this.options = {
      altFormat: 'd/m/Y',
      altInput: true,
      enable: [
        {
          from: new Date(),
          to: '2025-05-01',
        },
      ],
    };

 

  }

  resposnse:any = [];
    getalbumByID(){
      this.NotificationsServices.getHotDateID(this.IDs).subscribe((res:any)=>{
        this.resposnse = res.data;
        // console.log(this.resposnse)
        let newData:any [] = [];
        newData.push(this.resposnse);
        this.eventDateList = newData;
      })
    }

  @ViewChild('eventDetails', { static: true }) eventDetails: TemplateRef<any>;

  getSwingerHotDates(id:number){
    this.swingerService.getAllHotDatesBySwingerId(id)
     .pipe(map(res =>{
      res.data.data.map(hot => {
      hot.start = hot.start_date;
      hot.id = hot.id;
      hot.end = hot.end_date;
      hot.title = hot.sort_description;
      hot.source = hot;
      hot.classNames = `cl_date_${hot.id}`;
       
    })
    return res.data.data;}
    ))
     .subscribe(eventDates => {
      //  console.log(eventDates)
     this.eventDateList = eventDates.reverse();
     this.calendarOptions.events = this.eventDateList;
    });
  }


  handleDateClick(arg) {
    //  console.log(arg);
   }

   dateEventClick(info,ev?:number) {
    
    let eventid;
     if(info==''|| ev== undefined){
      eventid = info.event.id;
      info.el.style.borderColor = '#696666';
      info.el.style.backgroundColor = '#6b796e';
     }else{
      eventid = ev;
     }
     this.setEventBackground = this.eventBackground[Math.floor(Math.random() * this.eventBackground.length)];
     this.hotdateServive.getHotDateById(eventid).subscribe(res => {
      this.actionEventData = res.data;
      this.modal.open(this.eventDetails, { size: 'lg' });
    });
    
  }

}
