import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, SwingersService } from '@app/_services';
import { Swinger } from '@app/_models/swinger';

@Component({
  selector: 'app-who-viewed',
  templateUrl: './who-viewed.component.html',
  styleUrls: ['./who-viewed.component.css']
})


export class WhoViewedComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  loading = true;
  currentUser:any;
  freeuser = false;

  constructor(private swingersService: SwingersService,
    private authenticationService: AuthenticationService
    ){
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if(this.currentUser.plan_id == 1){
        this.freeuser = true;
      }
  });

   }
  swingers: Swinger;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;

  ngOnInit(): void {
    this.getAllViewsSwinger();
  }

  getAllViewsSwinger(){
    this.swingersService.getWhoViews().subscribe(data => {
      // console.log(data)
      this.swingers = data;
      for(let swinger in data){
        data[swinger].profile = (data[swinger].profile) ? data[swinger].profile: this.defaultProfile;
        // data[swinger].profile = (data[swinger].profile) ? `${environment.imgUrl}/${data[swinger].id}/images/user/profile/${data[swinger].profile}`: this.defaultProfile;
      }
      this.loading = false;
    });
  }



}
