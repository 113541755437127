import { AfterViewInit, Component, OnInit, DoCheck} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services';
import { Location } from '@angular/common';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, DoCheck{
  public href: string = "";
    url: string = "asdf";
  constructor(private router: Router,
    private authenticationService: AuthenticationService) { }
  defaultNavigation = true;
  emailStatus= true;
  planStatus=true;
  planInvoce:boolean = true;
  ngOnInit(): void {
    const currentUrl: string = this.router.url;
    if(currentUrl === '/dashboard'){
     this.router.navigate(['/dashboard/swingers']);
    }

    this.authenticationService.currentUser.subscribe(check => {
      if(check.email_status!=1){
      this.emailStatus = false;
      }
      if(check.plan_status!=1){
        this.planStatus = false;
        }
    });
  
  }
cols = 'col-lg-8 col-md-12 col-sm-12';
  ngDoCheck(){
    const currentUrl: string = this.router.url;
    let userId = currentUrl.split('/')[3];
    if(userId === `profile`) {
      this.defaultNavigation = false;
      }else{
        this.defaultNavigation = true;
      }

      this.href = this.router.url;
      if( this.router.url.substring(11) === 'plan-invoce' ||this.router.url.substring(11) == "user-online" ){
        this.cols = 'col-lg-12 col-md-12 col-sm-12'
        this.planInvoce = false
      }else{
        this.planInvoce = true;
        this.cols = 'col-lg-8 col-md-12 col-sm-12'
      }
  }


}
