<div *ngIf='!uiHide'>
<div class="row g-0">
    <div class="col-4 col-md-2 pr-1">
        <div class="user_profile_ls"><img [src]="friend.is_pic" alt="user-profile" class="img-fluid user-profile"></div>
    </div>
    <div class="col-8 col-md-10 pl-1">
      <div class="row">
         <div class="col-md-8 col-12">
          <div class="card-body">
            <h5 class="card-title" style="margin-bottom: 2px;"><a routerLink="/{{friend.id}}/swinger/profile" (click)="setProfileViews(friend?.id)">{{friend.name}}</a></h5>
            <span *ngIf="friend?.gender != null" [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
            <!-- <p class="card-text text-muted">{{friend?.short_tag_line}}</p> -->
          </div>
         </div>
         <div class="col-md-4 col-12 m-flex-v">
            <div class="btn-group mt-2 mb-2">
            <button *ngIf="friend.is_faverate==0" type="button" class="btn btn-info" (click)="addToFavourite(friend.id)"><div 
              *ngIf="favouriteloading" class="spinner-border text-light spinner-border-sm" role="status">
               <span class="visually-hidden"></span>
             </div> <i *ngIf="!favouriteloading" class="fa fa-heart" aria-hidden="true"></i></button>
            <button type="button" class="btn btn-warning"><i class="fas fa-comments"></i></button>
            <button type="button" class="btn btn-danger" (click)="blockFriend(friend.id)"><div 
              *ngIf="blockloading" class="spinner-border text-light spinner-border-sm" role="status">
               <span class="visually-hidden"></span>
             </div> <i *ngIf="!blockloading" class="fas fa-ban"></i></button>
            </div>
          
        </div>
      </div>
      
    </div>
</div>
  </div>
