  
  
  
          
          <app-group-navigation></app-group-navigation> 
          <div class="card bg-dark text-light mb-3 mt-2">
            <div class="card-header"><h6>Groups</h6></div>
            <div class="card-body over_flow_wrapper_big">
              <div class="card mb-2 bg-dark m-50-wrapper" *ngFor="let group of firendGroups; let i=index">
                <div class="row g-0" >
                  <div class="col-12 col-md-2 pr-1">
                      <div class="user_profile_ls"><img src="{{group.thumbnailUrl}}" alt="user-profile" class="img-fluid"></div>
                  </div>
                  <div class="col-12 col-md-6 pl-1">
                    <div class="card-body">
                    <h5><a class="card-title" routerLink="/group-panel/{{group.id}}/post">{{group.name | slice:0:12}}</a></h5>
                      <p class="card-text text-muted">{{group.sort_discription | slice:0:35}}</p>
                    </div>
                  </div>
 
                  <div class="col-12 col-md-4 pl-1">
                    <div class="btn-group mt-4">
                      <button *ngIf="group.memberStatus == 0" type="button" class="btn btn-warning" (click)="joinGroup(group.id, i);" ><div 
                        *ngIf="group.requestStatus" class="spinner-border text-light spinner-border-sm" role="status">
                         <span class="visually-hidden"></span>
                       </div> Join Group</button>
                       <button *ngIf="group.memberStatus==2" type="button" class="btn btn-dark"  disabled> Requested</button>

                       <button *ngIf="group.memberStatus == 1" type="button" class="btn btn-dark"  disabled> You are Member</button>
                    </div>
                  </div>
 
                </div>
              </div>
              <div class="card-text text-center" *ngIf="loading">
                <div class="spinner-border text-light" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            </div>
          </div>