import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';

@Component({
  selector: 'app-block-friends',
  templateUrl: './block-friends.component.html',
  styleUrls: ['./block-friends.component.css']
})
export class BlockFriendsComponent implements OnInit {
  


  defaultProfile: string = environment.defaultProfile;
  loading = true;
  freeuser = false;
  currentUser:any;
  
  constructor(
    private blockFriendsService: FriendsService,
    private authenticationService: AuthenticationService,
    private commonService:CommonService
    ) {
   this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      // console.log(this.currentUser.plan_id)
       if(this.currentUser.plan_id === 1){
        this.freeuser = true;
        
      }else{
       return this.freeuser = false;
      }
  });
   }
  blockFriends: Friends;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  btnloading = false;
  removeUiBlock = false;
  dataNotFound = false;

  ngOnInit(): void {
    this.getPaddingFriends();
  }
  pagination:any;
  pagelarg = true;
  getPaddingFriends(){
    this.blockFriendsService.getBlockFriends().subscribe(data => {
      if(data == null ){
        this.loading = false;
        this.dataNotFound = true;
      }
      this.loading = false;
      this.pagination = data.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.blockFriends = data.data.data;
      if (this.pagination.links.length == 3) {
        this.pagelarg = false;  
     }else{
       this.pagelarg = true;  
     }
    }, error => {
      this.loading = false;
      this.dataNotFound = true;
    });
  }
  activePage(url:string){
    this.loading = true;
    if(url){
    this.commonService.getPagination(url).subscribe(data => {
      if(data != null){
        this.blockFriends = null;
        this.blockFriends = data.data.data;
        this.pagination = data.data;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
     
        
        this.loading = false;
      }else{
        this.loading = false;
        this.dataNotFound = true;
      }
    }, error => {
      this.loading = false;
    });
  }
  }

  ngDoCheck(){
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      // console.log(x)
       if(this.currentUser.plan_id == 1){
        this.freeuser = true
      }else{
        // console.log("working")
       return this.freeuser = false;
       
      }
  });
  }
}
