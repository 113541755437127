<app-user-gallery-navigation></app-user-gallery-navigation>
<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header"><h6 class="around_space">Timeline </h6></div>
    <div class="card-body timeline_wrapper" *ngIf="!freeuser">
        <div class="row" id="lightgallery">
            <div class="col-md-3 mb-2 pl-1 pr-1 desktop-view">
              <div class="create-album-wrapper text-center" (click)="openCreateAlbumModal()">
                <i class="fas fa-plus"></i>
              </div>
            </div>
            <div class="col-md-3 m-view text-center mb-4">
              <button class="btn btn-sm btn-success"  (click)="openCreateAlbumModal()">
                <i class="fas fa-plus"></i> Add Photos
              </button>
            </div>
            <div *ngIf="timeLineLoading">
              <div class="spinner-border text-light mt-4" role="status">
                <span class="visually-hidden"></span>
              </div>
            </div>
            <div class="col-md-3 col-6 mb-2 pl-1 pr-1" *ngFor="let photos of timeLineImages; let i=index">
              <div class="top_wrapper">
                <div class='video_views_wrapper'>
                  <span (click)="imageLike(photos.id, i)"><i class="fas fa-thumbs-up like"></i> {{photos.Likes}}</span>
                  <span><i class="fas fa-eye"></i> {{photos.views}}</span>
                </div>
                <span (click)="deleteImage(photos.id)" class="delete_wr"><i class="fas fa-trash-alt"></i></span>
              </div>
              <div class="image-wrapper text-center">
                <img src="{{photos?.src}}" (click)="open(i)" alt="" class="timeline-item">
                </div>
            </div>
        </div>

        <!-- <div *ngIf="checkpages" class="row mt-4">
          <div class="col-md-12 text-center">
            <nav aria-label="..." class="text-center">
              <ul class="pagination justify-content-center">
                <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
              </ul>
            </nav>
          </div>
        </div> -->
        
     <div class="row">
       <div class="col-md-12">
         
<!-- Modal -->
<div class="modal animate__animated animate__zoomIn" *ngIf="openModel" [ngClass]="openModel==true?'show':'hide'" id="createAlbum" role="dialog">
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upload images</h5>
          <button type="button" class="btn-close" (click)="closeCreateAlbumModal();" data-bs-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
        </div>
            <div class="modal-body">
              <div class="progress form-group" *ngIf="progress > 0">
                <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
                </div>
                <div class='count-progressbar bg-dark text-center text-light'>{{progress}} %</div>
            </div>
                <form [formGroup]="timeline" (ngSubmit)="storeTimeline()">
                    <input type="text" formControlName="userID" class="form-control d-none" readonly />
                
                    <div class="form-group">
                    <input type="file" #albumImages (change)="loadImages(albumImages.files)" accept='image/*' class="form-control d-none" multiple=""  formControlName="photos"/>
                    <div *ngIf="submitted && f.photos.errors" class="invalid-feedback">
                        <div *ngIf="f.photos.errors.required">Photos is required</div>
                    </div> 
                </div>

                <div class="row mb-3">
                    <div class="col-md-3">
                       <div class="create-album-wrapper text-center mb-3" (click)="albumImages.click();">
                        <i class="fas fa-plus"></i>   
                    </div>
                    </div>
                    <div class="col-md-3" *ngFor="let image of gallerImages">
                     <img src="{{image}}" class="img-fluid gallery_image_rs">
                    </div>
                </div>
        
            <button  [disabled]="loading" class="btn btn-warning"><div 
            *ngIf="loading" class="spinner-border text-light spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
            </div> Submit</button> 
             </form>
        </div>
      </div>
    </div>
       </div>
     </div>
    </div>
  </div>
  <div class="mt-4" *ngIf="freeuser">
    <app-subscribe-plan></app-subscribe-plan>
   </div>
</div>



