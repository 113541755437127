import { Component, OnInit } from '@angular/core';
import { NotificationsServices } from "../../_services/notifications.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from '@environments/environment.prod';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { SharedService } from "../../_services/shared.service";
import { Subscription, BehaviorSubject } from 'rxjs';
import { GroupServiceService } from "../../_services/group-service.service";
import { Lightbox, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { CommonService, GalleryService, SwingersService } from '@app/_services';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})

export class NotificationComponent implements OnInit {
  clickEventsubscription: Subscription;
  IDs: any;
  seemor: any;
  notifiData: any = [];
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  allnotification: boolean = false;
  seemoredata: boolean = true;
  defaultProfile = environment.defaultProfile;
  _subscription: Subscription;
  timelineviews: boolean = false;
  albumImages: any[] = [];
  albumlikesviews: boolean = false;
  loading: boolean = true;
  notificationdata: any = [];
  AllNotifiiData: any[] = [];
  request = new Array(this.AllNotifiiData.length);
  buttonhoenothide: boolean;
  currentIndex: number;
  api;
  currentItem;
  videoStatus = false;
  pagination: any;
  constructor(
    private notification: NotificationsServices,
    private sharedService: SharedService,
    private Activate: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private galleryService: GalleryService,
    private _lightbox: Lightbox,
    private _lightboxEvent: LightboxEvent,
    private groupService: GroupServiceService) {
    this.Activate.queryParams.subscribe(params => {
      this.IDs = params['d'];
      this.seemor = params['see'];
      if (this.seemor != null || this.seemor != undefined) {
        this.seemoredata = true;
        this.openNotifi()
      } else if (this.IDs != null || this.IDs != undefined) {
        this.seemoredata = false
        this.gettimeline(this.IDs)
        this.getvideoslikes(this.IDs)
        this.getalbumlikes(this.IDs);
        
      }
    });
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      if (this.IDs != null || this.IDs != undefined) {
        this.gettimeline(this.IDs)
        this.getalbumlikes(this.IDs)
        this.getvideoslikes(this.IDs)
      }
    });
  }

  ngOnInit(): void {
  }
  paginanat = true;
  openNotifi() {
    this.notification.getnotification().subscribe((res: any) => {
      this.loading = false;
      let countnotifi = res;
      // console.log(countnotifi);
      this.notificationdata = res.data.data;
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.AllNotifiiData.length = 0;
      for (let index = 0; index < this.notificationdata.length; index++) {
        const subject = JSON.parse(this.notificationdata[index].data);
        const sentby = JSON.parse(this.notificationdata[index].data);
        const requestType = JSON.parse(this.notificationdata[index].data);
        const FriendID = JSON.parse(this.notificationdata[index].data);
        const FriendImage = JSON.parse(this.notificationdata[index].data);
        const OrderId = JSON.parse(this.notificationdata[index].data);
        let readColor: any;
        if (this.notificationdata[index].read_at == "" || this.notificationdata[index].read_at == null) {
          readColor = "black"
        }
        let data = {
          id: this.notificationdata[index].id,
          img: this.notificationdata[index].profilepic,
          read: this.notificationdata[index].read_at,
          create: this.notificationdata[index].created_at,
          subject: subject.data,
          sent_by: sentby.sent_by,
          friend_user_id: FriendID.id,
          requestType: requestType.requestType,
          OrderId: OrderId.other_id,
          status: this.notificationdata[index].status
        }
        if (requestType.requestType == "Friend Request sent" || requestType.requestType == "Sent Group Invitation") {
          if (this.notificationdata[index].status == 0) {
            this.request[index] = true;
            this.buttonhoenothide = true;
          } else if (this.notificationdata[index].status == 1) {
            this.request[index] = false;
            this.buttonhoenothide = false;
          }
          else if (this.notificationdata[index].status == 2) {
            this.request[index] = false;
            this.buttonhoenothide = false;
          }
        }
        this.AllNotifiiData.push(data);
      }
       if(this.pagination.links.length == 3){
          this.paginanat = false
       }else{
        this.paginanat = true;
       }
    })
  }


  getPageData(url:string){
    this.loading = true;
    // console.log(url)
    if(url){
    this.commonService.getPagination(url).subscribe(res => {
      this.AllNotifiiData.length = 0;
      this.loading = false;
      this.notificationdata = res.data.data;
      this.pagination = res.data;
      this.pagination.links[0].label = 'Previous';
      this.pagination.links[this.pagination.links.length - 1].label = 'Next';
      this.pagination.links[0].label = 'Previous';
      for (let index = 0; index < this.notificationdata.length; index++) {
        const subject = JSON.parse(this.notificationdata[index].data);
        const sentby = JSON.parse(this.notificationdata[index].data);
        const requestType = JSON.parse(this.notificationdata[index].data);
        const FriendID = JSON.parse(this.notificationdata[index].data);
        const FriendImage = JSON.parse(this.notificationdata[index].data);
        const OrderId = JSON.parse(this.notificationdata[index].data);
        let readColor: any;
        if (this.notificationdata[index].read_at == "" || this.notificationdata[index].read_at == null) {
          readColor = "black"
        }
        let data = {
          id: this.notificationdata[index].id,
          img: this.notificationdata[index].profilepic,
          read: this.notificationdata[index].read_at,
          create: this.notificationdata[index].created_at,
          subject: subject.data,
          sent_by: sentby.sent_by,
          friend_user_id: FriendID.id,
          requestType: requestType.requestType,
          OrderId: OrderId.other_id,
          status: this.notificationdata[index].status
        }
        if (requestType.requestType == "Friend Request sent" || requestType.requestType == "Sent Group Invitation") {
          if (this.notificationdata[index].status == 0) {
            this.request[index] = true;
            this.buttonhoenothide = true;
          } else if (this.notificationdata[index].status == 1) {
            this.request[index] = false;
            this.buttonhoenothide = false;
          }
          else if (this.notificationdata[index].status == 2) {
            this.request[index] = false;
            this.buttonhoenothide = false;
          }
        }
        this.AllNotifiiData.push(data);
      }
      if(this.pagination.links.length == 3){
        this.paginanat = false
     }else{
      this.paginanat = true;
     }
    }, error => {
      this.loading = false;
    });
         
  }
   
  }


  opensinglenoti(id, requestType, friend_user_id, OrderId) {
    console.log(requestType, friend_user_id)
    if (requestType == "Comment on profile") {
      this.router.navigate(['/dashboard/profile-comments'])
    } else if (requestType == "Friend Request sent") {
      this.router.navigate([`/${friend_user_id}/swinger/profile`], { queryParams: { t: "friend" } })
    }
    else if (requestType == "Create a hot date ") {
      this.router.navigate([`/${friend_user_id}/swinger/hot-dates`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Create new Group") {
      this.router.navigate([`/${friend_user_id}/swinger/groups`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Create a new album" || requestType == "insert more album images")  {
      this.router.navigate([`/${friend_user_id}/swinger/albums`], { queryParams: { d: OrderId } })
    }

    else if (requestType == "upload new video ") {
      this.router.navigate([`/${friend_user_id}/swinger/videos`], { queryParams: { d: OrderId } })
    }

    else if (requestType == "Create new event ") {
      this.router.navigate([`/${friend_user_id}/swinger/events`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "upload time line photos") {
      this.router.navigate([`/${friend_user_id}/swinger/timeline`], { queryParams: { d: OrderId } })
    }
    else if (requestType == "Accept friend request") {
      this.router.navigate([`/${friend_user_id}/swinger/profile`], { queryParams: { t: "friend" } })
    }
    else if (requestType == "like your timeline photo") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
      
    }
    else if (requestType == "like album images ") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } })
      
    }

    else if (requestType == "like your Video") {
      this.router.navigate(['/dashboard/notifications'], { queryParams: { d: OrderId } });
      
    }
    this.IDs = id;
    this.readnotifi(id);

  }

  readnotifi(id: any) {
    this.notification.updatenotificationbyID(id).subscribe((res => {
      this.openNotifi();
    }))
  }


  /// get timeline pic singele

  gettimeline(id: any) {
    console.log(id)
    this.notification.gettimelineID(id).subscribe((res: any) => {
      this.loading = false;
      this.newDataNotifi.length = 0;
      this.newDataNotifi.push(res.data)
      for (let image in this.newDataNotifi) {
        this.newDataNotifi[image].src = (this.newDataNotifi[image].image) ? `${environment.imgUrl}/${this.userId}/images/user/timeline/${this.newDataNotifi[image].image}` : this.defaultProfile;
      }
      if (this.newDataNotifi.length != 0) {
        this.timelineviews = true;
        this.albumlikesviews = false;
        this.likevideosviews = false;
      }

      // console.log(this.newDataNotifi)
    })
  }


  // get Album image likes pics
  getalbumlikes(id: any) {
    this.notification.getSingleImageID(id).subscribe(res => {
      this.loading = false;
      this.albumImages.length = 0;
      this.albumImages.push(res.data)
      for (let image in this.newDataNotifi) {
        this.albumImages[image].src = (this.albumImages[image].images) ? this.albumImages[image].images : this.defaultProfile;
      }
      if (this.albumImages.length != 0) {
        this.timelineviews = false;
        this.albumlikesviews = true;
        this.likevideosviews = false;
      }

      // console.log(this.albumImages)
    })
  }

  likevideosviews: boolean = false;
  videosGallery: any[] = [];
  getvideoslikes(id) {
    this.notification.getVideoID(id).subscribe(res => {
      this.loading = false;
      let array: any[] = [];
      array.push(res.data)
      this.videosGallery.length = 0;
      this.videosGallery = array;
      if (this.videosGallery.length != 0) {
        this.timelineviews = false;
        this.albumlikesviews = false;
        this.likevideosviews = true;
      }
    })
  }

  onPlayerReady(api) {
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }
  nextVideo() {
    this.currentIndex++;
    if (this.currentIndex === this.videosGallery.length) {
      this.currentIndex = 0;
    }

    this.currentItem = this.videosGallery[this.currentIndex];
  }


  playVideo() {
    this.api.play();
  }

  onClickPlaylistItem(itemId, index: number) {
    this.currentIndex = index;
    this.currentItem = itemId;
    this.storeVideoView(itemId, index);
  }
  storeVideoView(id: number, index: number) {
    if (!this.videoStatus) {
      const data = {
        'video_id': id
      };
      this.galleryService.storeVideoViews(data).subscribe(res => {
        this.videosGallery[index].totalviews = res.views;
      });
      this.videoStatus = true;
    } else {
      this.videoStatus = false;
    }

  }

  userRequest: boolean = true;
  actionbtns: boolean = false;
  newDataNotifi: any[] = [];
  RequestTypes: any;
  // getnotifibyID() {
  //   // console.log(this.IDs)
  //   this.newDataNotifi.length = 0;
  //   this.notification.getnotificationbyID(this.IDs).subscribe((res) => {
  //     this.notifiData = res.data;
  //     // console.log(res)
  //     const subject = JSON.parse(this.notifiData.data);
  //     const sub = JSON.parse(this.notifiData.data);
  //     const sentby = JSON.parse(this.notifiData.data);
  //     const FriendID = JSON.parse(this.notifiData.data);
  //     const requestType = JSON.parse(this.notifiData.data);
  //     const FriendImage = JSON.parse(this.notifiData.data);

  //     this.RequestTypes = requestType.requestType
  //     if (requestType.requestType == "Friend Request sent") {
  //       this.actionbtns = true;
  //     }
  //     if (subject.subject == "Emailvarification" || requestType.requestType == "Create new event " || subject.subject == "Accept Your Friend Request") {
  //       this.userRequest = true;
  //     }
  //     if (requestType.requestType == "Create a new album" || sub.subject == "upload time line photos" || requestType.requestType == "upload new video ") {
  //       this.userRequest = false;
  //     }

  //     let data = {
  //       id: this.notifiData.id,
  //       img: `${environment.imgUrl}/${FriendID.id}/images/user/profile/${FriendImage.sendar_image}`,
  //       read: this.notifiData.read_at,
  //       create: this.notifiData.created_at,
  //       subject: subject.data,
  //       sent_by: sentby.sent_by,
  //       friend_user_id: FriendID.id,
  //       totallike: this.notifiData.totallike,
  //       likename: this.notifiData.name,
  //       picLikeurl: `${environment.imgUrl}/${FriendID.id}/images/user/profile/${this.notifiData.is_profile_pic}`,
  //       requestType: requestType.requestType
  //     }

  //     this.newDataNotifi.push(data);
  //     // console.log(data)
  //   })
  // }

  acceptrequest(friend_id, requestType, group_id, id) {
    // console.log(id)
    if (requestType == "Friend Request sent") {
      let body = {
        friend_user_id: friend_id,
        userId: this.userId
      }
      this.notification.requestaccept(body).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 1) {
          let body = {
            noti_id: id,
            status: 1
          }
          console.log(body)
          this.notification.updatenotifi(body).subscribe(res => {
            console.log(res)
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", 'Something wrong please try again.', "success");
      });

    } 
    // else if (requestType == "Sent Group Invitation") {
    //   let body = {
    //     group_id: group_id,
    //     to_user_id: friend_id,
    //   }
    //   this.notification.groupaccept(body).subscribe((res: any) => {
    //     let resp: any = [];
    //     resp = res;
    //     if (resp.status == 1) {
    //       let body = {
    //         noti_id: id,
    //         status: 1
    //       }
    //       this.notification.updatenotifi(body).subscribe(res => {
    //         this.openNotifi();
    //         this.readnotifi(id);
    //       })
    //     }
    //     Swal.fire("Success", `${res.message}`, "success");
    //     this.loading = false;
    //   }, error => {
    //     this.loading = false;
    //     Swal.fire("error", 'Something wrong please try again.', "success");
    //   })
    // } else {
    //   return;
    // }

  }

  rejectRequest(friend_id, requestType, group_id, id) {
    if (requestType == "Friend Request sent") {
      const data = {
        "from_user_id": this.userId,
        "to_user_id": friend_id,
      };
      this.notification.requestreject(data).subscribe((res: any) => {
        let resp: any = [];
        resp = res;
        if (resp.status == 2) {
          let body = {
            noti_id: id,
            status: 2
          }
          console.log(body)
          this.notification.updatenotifi(body).subscribe(res => {
            console.log(res)
            this.openNotifi();
            this.readnotifi(id);
          })
        }
        Swal.fire("Success", `${res.message}`, "success");
        this.loading = false;
      }, error => {
        this.loading = false;
        Swal.fire("error", 'Something wrong please try again.', "success");
      });

    }
    //  else if (requestType == "Sent Group Invitation") {
    //   let body = {
    //     group_id: group_id,
    //     to_user_id: friend_id,
    //   }
    //   this.notification.groupreject(body).subscribe(res => {
    //     let resp: any = [];
    //     resp = res;
    //     if (resp.status == 2) {
    //       let body = {
    //         noti_id: id,
    //         status: 2
    //       }
    //       this.notification.updatenotifi(body).subscribe(res => {
    //         this.openNotifi();
    //         this.readnotifi(id);
    //       })
    //     }
    //     Swal.fire("Success", `${res.message}`, "success");
    //     this.loading = false;
    //   }, error => {
    //     this.loading = false;
    //     Swal.fire("error", 'Something wrong please try again.', "success");
    //   })
    // } else {
    //   return;
    // }
  }
  likeImages: boolean = false;
  postLikes(id) {
    console.log(id)
    let body = {
      image_Id: '88'
    }
    this.notification.likeImageNotifi(body).subscribe((res: any) => {
      this.likeImages = true;
      console.log(res)
    })
  }
}
