<div class="row">
  <div class="col-md-12">
    <div class="card bg-dark text-light mb-3 mt-2">
      <div class="card-header text-left">
        <h4 class="text-light">Comments</h4>
        <div class="text-center" *ngIf="commentDataLoading">
          <div class="spinner-border text-light" role="status">
          </div>
        </div>
        <div class="row" *ngFor="let comment of totalComments; let l= index">
          <div class="col-sm-12">
            <div class="swinger-comment" [ngClass]="comment?.comment_parent_id!=0?'child-comment':'parent-comment'">
              <img src="{{comment?.profile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                class="img-fluid rounded-circle comment-user-profile">
              <h6><a href="javascript:void(0);" class="text-light">{{comment?.user_name}}</a></h6>
              <p class="text-light" style="font-size: 10px;margin-bottom:1px">{{rComment?.comment_date}}</p>
              <p class="text-secondery comment-text">{{comment?.comment_content}}</p>
              <div class="d-flex justify-content-around">
                <a href="javascript:void(0);" (click)="commentLike(l,'-1',comment.id)" class="text-light">Like <span
                    class="badge bg-light text-dark">{{comment?.totalLikes}}</span></a>
                <a href="javascript:void(0);" (click)="showReplyComment(l)" class="text-light">Reply</a>
              </div>
              <form *ngIf="comment?.replyStatus" [formGroup]="commentFormReply"
                (ngSubmit)="replyComment(l,comment.user_id, comment.id)">
                <textarea class="_comment_wrapper comment-textbox mt-2" type="text" formControlName="comment_content"
                  placeholder="Write a comment..." style="height:50px"></textarea>
                <p class="text-right mt-2">
                  <button class="btn btn-primary btn-sm" [disabled]="!commentFormReply.valid">
                    <i class="fas fa-paper-plane"></i> Submit
                  </button>
                </p>
              </form>
            </div>
          </div>
          <div class="row w-100" *ngFor="let rComment of comment.data; let i= index">
            <div class="col-sm-12">
              <div class="swinger-comment" [ngClass]="rComment?.comment_parent_id!=0?'child-comment':'parent-comment'">
                <img src="{{rComment?.profile}}" onerror="this.src='../../../assets/images/avtar-image.jpg';"
                  class="img-fluid rounded-circle comment-user-profile">
                <h6><a href="javascript:void(0);" class="text-light">{{rComment?.user_name}}</a></h6>
                <p class="text-light" style="font-size: 10px;margin-bottom:1px">{{rComment?.comment_date}}</p>
                <p class="text-secondery comment-text">{{rComment?.comment_content}}</p>
                <div class="d-flex justify-content-around">
                  <a href="javascript:void(0);" (click)="commentLike(l, i, comment.id);" class="text-light">Like <span
                      class="badge bg-light text-dark">{{rComment?.totalLikes}}</span></a>
                  <a href="javascript:void(0);" (click)="showReplyComment(l,i)" class="text-light">Reply</a>
                </div>




                <form *ngIf="rComment?.replyStatus" [formGroup]="commentFormReply"
                  (ngSubmit)="replyComment(i,comment.user_id, comment.id)">
                  <textarea class="_comment_wrapper comment-textbox mt-2" type="text" formControlName="comment_content"
                    placeholder="Write a comment..." style="height:50px"></textarea>
                  <p class="text-right mt-2">
                    <button class="btn btn-primary btn-sm" [disabled]="!commentFormReply.valid">
                      <i class="fas fa-paper-plane"></i> Submit
                    </button>
                  </p>
                </form>
              </div>
            </div>
          </div>

        </div>

        <div class="row mt-4">
          <div class="col-md-12 text-center">
            <nav aria-label="..." class="text-center">
              <ul class="pagination justify-content-center">
                <li *ngFor="let link of pagination?.links; let i = index;" class="page-item"
                  [ngClass]="link.active?'active':''"><a class="page-link" (click)="getPageData(link.url)"
                    [innerText]="link.label"></a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>