import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';

@Component({
  selector: 'app-firends',
  templateUrl: './firends.component.html',
  styleUrls: ['./firends.component.css']
})

export class FirendsComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  loading = true;
  currentUser: any;
  freeuser = false;
  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  constructor(private reqSent: FriendsService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser.plan_id == 1) {
        this.freeuser = true;
      }
    });
  }
  firends: Friends;
  contentNotFound = false;
  pagination: any;


  ngOnInit(): void {
    this.getFirends();
  }
  pagelarg = true;
  getFirends() {
    this.reqSent.getFriends().subscribe(data => {
      if (data != null) {
        this.firends = data.data.data;
        console.log(data)
        this.pagination = data.data;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';

        for (let friend in this.firends) {
          this.firends[friend].is_pic = (this.firends[friend].is_pic) ? this.firends[friend].is_pic : this.defaultProfile;
        }
        this.loading = false;
        console.log(this.pagination.links.length)
        if (this.pagination.links.length == 3) {
          this.pagelarg = false;  
       }else{
         this.pagelarg = true;  
       }
      } else {
        this.loading = false;
        this.contentNotFound = true;
      }
    }, error => {
      this.loading = false;
    });
  }


  activePage(url: string) {
    this.loading = true;
    if (url) {
      this.commonService.getPagination(url).subscribe(data => {
        if (data != null) {
          this.firends = null;
          this.firends = data.data.data;
          this.pagination = data.data;
          this.pagination.links[0].label = 'Previous';
          this.pagination.links[this.pagination.links.length - 1].label = 'Next';
          this.pagination.links[0].label = 'Previous';
          this.pagination.links[this.pagination.links.length - 1].label = 'Next';
          for (let friend in this.firends) {
            this.firends[friend].is_pic = (this.firends[friend].is_pic) ? `${environment.imgUrl}/${this.firends[friend].id}/images/user/profile/${this.firends[friend].is_pic}` : this.defaultProfile;
          }

          this.loading = false;
        } else {
          this.loading = false;
          this.contentNotFound = true;
        }
      }, error => {
        this.loading = false;
      });
    }
  }
}


