<div class="card bg-dark text-light mb-3 mt-2">
    <div class="card-header"><h6 class="around_space">Timeline </h6></div>
    <div class="card-body timeline_wrapper">
        <div class="text-center" *ngIf="timeLineLoading">
            <div class="spinner-border text-light mt-4" role="status">
              <span class="visually-hidden"></span>
            </div>
          </div>

        <div class="row" id="lightgallery">
            <div class="col-md-3 mb-2 pl-1 pr-1 col-6" *ngFor="let photos of timeLineImages; let i=index">
              <div class="top_wrapper">
                <div class='video_views_wrapper'>
                  <span (click)="imageLike(photos.id, i)"><i class="fas fa-thumbs-up like"></i> {{photos.Likes}}</span>
                  <span><i class="fas fa-eye"></i> {{photos.views}}</span>
                </div>
              </div>
              <div class="image-wrapper text-center">
                <img src="{{photos?.s3Image}}" (click)="open(i)" alt="" class="timeline-item">
                </div>
            </div>
            <p *ngIf="dataNotFound" class="text-center">There is no photo's available </p>
        </div>
        <div *ngIf="checkpages"  class="row mt-4">
          <div class="col-md-12 text-center">
            <nav aria-label="..." class="text-center">
              <ul class="pagination justify-content-center">
                <li *ngFor="let link of pagination?.links; let i = index;" class="page-item" [ngClass]="link.active?'active':''"><a class="page-link" (click)="activePage(link.url)" [innerText]="link.label"></a></li>
              </ul>
            </nav>
          </div>
        </div>
  </div>
  
</div>
