
   <div class="right_navigation_panel"> 
      <div class="card bg-dark text-light mb-3">
        <div class="card-header"><h4 class="fw-bold">Who’s Online</h4></div>
        <div class="card-body over_flow_wrapper">
          <div class="card mb-2 bg-dark" *ngFor="let friend of friendsData | slice:0:5; let i=index">
            <div class="row g-0">
              <div class="col-3 pr-1">
               
                  <div class="user_profile_ls active">
                    <img [src]="friend?.profile" onerror="this.src='../../../assets/images/avtar-image.jpg';" alt="user-profile" class="img-fluid">
                  </div>
                  <i class="fas fa-circle cutom"></i>
              </div>
              <div class="col-9 pl-1">
                <div class="card-body">
                  <h5 class="card-title"><a routerLink="/{{friend?.id}}/swinger/profile">{{friend.name}}</a></h5>
                  <!-- <p class="card-text text-muted" style="margin-bottom: 2px;">{{friend.short_tag_line}}</p> -->
                  <span [ngClass]="friend?.gender=='f'?'badge-success':''||friend?.gender=='m'?'badge-info':''||friend?.gender=='c'?'badge-warning':''" class="badge badge-pill  mb-2">{{friend?.gender|genderLable:friend?.gender}}</span>
                </div>
              </div>
            </div>
          </div>
          <p *ngIf="dataNotFound  " class="text-center">There is no swinger/friend online.</p>
          <div class="see_more_wrapper" (click)="gotoOnline()">
            <a >see all <i class="fas fa-arrow-circle-right"></i></a>

           </div>
          <div class="card-text text-center" *ngIf="loading">
            <div class="spinner-border text-light" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>

        
        </div>
      </div>

   

    </div>


