import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/_services';
import { environment } from '@environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  userID = JSON.parse(sessionStorage.getItem('currentUser'))?.id;
  contactForm: FormGroup;
  bannerImage = './assets/images/young-passionate.jpg';
  loading = false;
  submitted = false;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
     ) { }

  selectedPlanDetails: any;

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      email: ['', Validators.required],
  });
  }

  get f() { return this.contactForm.controls; }

  contactUs() {
    this.submitted = true;
    console.log(this.contactForm.value);
    return false;

    if (this.contactForm.invalid) {
        return;
    }
    this.loading = true;
}


}
