import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { DataService } from '../../_services/dataShare';
import { AuthenticationService, PlanService, GlobalLocationsService } from '@app/_services';
import { SearchService } from "./search";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-looking-form',
  templateUrl: './looking-form.component.html',
  styleUrls: ['./looking-form.component.css']
})
export class LookingFormComponent implements OnInit {
  mapShow = false;

  sitelogo = environment.siteLogoDark;
  lookingForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  data: any;

  genders: any = ['male','female','couple','couple (2 female)', 'couple (2 men)'];
  distances: any = ['5','10','25','50', '100','200','500', '800', '1000'];

  distanceInKm: any = new Array("0", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65", "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82", "83", "84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99", "100", "101", "102", "103", "104", "105", "106", "107", "108", "109", "110");

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private SearchService: SearchService,
    private datashare :DataService,
    private globalLocation: GlobalLocationsService
   ) {

    if (this.authenticationService.currentUserValue) { 
        // this.router.navigate(['/']);
    }
}



ngOnInit(): void {
  this.lookingForm = this.formBuilder.group({
    first_gender: ['', Validators.required],
    second_gender:['', Validators.required],
    distanceFrom: ['', Validators.required],
    distanceTo: ['', Validators.required],
    address:['', Validators.required],
    distanceMiles:['', Validators.required],
    latitude:[''],
    longitude:[''],
});
  // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
}

getlatitudeLongitude(mapData: any) {
  this.lookingForm.get('latitude').setValue(mapData.lat);
  this.lookingForm.get('longitude').setValue(mapData.lng);
  this.lookingForm.get('address').setValue(mapData.address);
  this.lookingForm.get('zipcode').setValue(mapData.postalCode);
}

get f() { return this.lookingForm.controls; }

lookingMembers() {
  this.submitted = true;
  this.data = {
    gender: this.f.first_gender.value.charAt(0),
    lookingfor: this.f.second_gender.value.charAt(0),
    from_age: this.f.distanceFrom.value,
    to_age: this.f.distanceTo.value,
    distance: this.f.distanceMiles.value,
    longitude:this.f.longitude.value,
    lattitude:this.f.latitude.value,

  };
  // stop here if form is invalid
  if (this.lookingForm.invalid) {
      return;
  }

  // console.log(this.data);

  this.SearchService.searchswing(this.data).subscribe(res => {
    this.datashare.setOption('data', res.data);
    this.router.navigate(['search']);
  });

}

}
