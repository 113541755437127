<footer class="py-5">
    <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <h5>SWINGERFLINGS COMMUNITY</h5>
        <ul class="list-unstyled text-small">
          <li><a class="link-secondary" routerLink="/" routerLinkActive="">Only Women Swap</a></li>
          <li><a class="link-secondary" routerLink="/" routerLinkActive="">Only Men Swap</a></li>
          
        </ul>
      </div>
      <div class="col-md-6 col-lg-3">
        <h5>MORE SWINGERFLINGS</h5>
        <ul class="list-unstyled text-small">
          <li><a class="link-secondary" routerLink="/plan" routerLinkActive="">Pricing & Plans</a></li>
          <li><a class="link-secondary" routerLink="/term-conditoins" routerLinkActive="">Terms & Conditions</a></li>
          <li><a class="link-secondary" routerLink="/privacy-policy" routerLinkActive="">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-2">
        <h5>FOLLOW US</h5>
        <ul class="list-unstyled text-small">
          <li><a class="link-secondary" href="#"><i class="fab fa-instagram"></i> Instagram</a></li>
          <li><a class="link-secondary" href="#"><i class="fab fa-facebook-f"></i> Facebook</a></li>
          <li><a class="link-secondary" href="#"><i class="fab fa-twitter"></i> Twitter</a></li>
          <li><a class="link-secondary" href="#"><i class="fab fa-youtube"></i> Youtube</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-4">
        <h5>SUBSCRIBE TO SWINGERFLINGS</h5>
        <p>SwingerFlings offers you the opportunity to simply accelerate this process by finding out which is right for you.</p>
        <input type="text" class="form-control subscribe_ft" #mailInput>
        <p class="text-right"><button  class="btn btn-warning cu_btn mr-0" (click)="subscribeMail(mailInput.value)">Subscribe</button></p>
      </div>
    </div>
    
    </div>

  </footer>
  <div class="design-by">
    <p><strong>©Swingerflings </strong>2021. All Rights Reserved.</p>
  </div>