import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { AuthenticationService, CommonService, FriendsService } from '@app/_services';
import { Friends } from '@app/_models/friends';
import { da } from 'date-fns/locale';

@Component({
  selector: 'app-friend-request-pandding',
  templateUrl: './friend-request-pandding.component.html',
  styleUrls: ['./friend-request-pandding.component.css']
})

export class FriendRequestPanddingComponent implements OnInit {

  defaultProfile = environment.defaultProfile;
  loading = true;
  dataNotFound = false;
  freeuser = false;
  currentUser:any;


  userId = JSON.parse(sessionStorage.getItem('currentUser')).id;
  constructor(private friendSarvice: FriendsService,
    private commonService:CommonService,
    private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if(this.currentUser.plan_id == 1){
        this.freeuser = true;
      }
  });
   }

  panddingFirend: Friends;


  ngOnInit(): void {
    this.getPanddingFirendsFriends();
  }
  pagelarg = true;
  getPanddingFirendsFriends(){
    this.friendSarvice.getPendingFriends().subscribe(data => {
      console.log(data)
      if( data == null){
        this.loading = false;
        this.dataNotFound = true;
      }else{
        this.pagination = data.data;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        this.panddingFirend = data.data.data;
        this.loading = false;
        if (this.pagination.links.length == 3) {
          this.pagelarg = false;  
       }else{
         this.pagelarg = true;  
       }
      }
 
    });
  }
  pagination:any
  activePage(url:string){
    this.loading = true;
    if(url){
    this.commonService.getPagination(url).subscribe(data => {
      if(data != null){
        this.panddingFirend = null;
        this.panddingFirend = data.data.data;
        this.pagination = data.data;
        this.pagination.links[0].label = 'Previous';
        this.pagination.links[this.pagination.links.length - 1].label = 'Next';
        this.pagination.links[0].label = 'Previous';
       this.pagination.links[this.pagination.links.length - 1].label = 'Next';
     
        
        this.loading = false;
      }else{
        this.loading = false;
       
      }
    }, error => {
      this.loading = false;
      this.dataNotFound = true;
    });
  }
  }

}
